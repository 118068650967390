import { LastTransactionPagarMeResponse } from "./last-transaction-pagar-me-response";

export class ChargePagarMeResponse {
	id: string;	
	code: string;
	amount: number;
	status: string;
	last_transaction: LastTransactionPagarMeResponse;

	constructor() {
	
	}
}