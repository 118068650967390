import { Component, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ChassiValidation } from 'src/app/models/chassi-validation/chassi-validation';
import { ChassiValidationService } from 'src/app/services/chassi-validation/chassi-validation.service';
import { TranslateService } from '@ngx-translate/core';
import { StatusChassi } from 'src/app/models/enum/status-chassi';
import { Router } from '@angular/router';
import { MessageExceptionService } from 'src/app/services/message-exception/message-exception.service';

@Component({
	selector: 'app-direct-sale',
	templateUrl: './direct-sale.component.html'
})
export class DirectSaleComponent implements OnInit {

	chassis: String = "";
	chassiValidationList: Array<ChassiValidation> = [];
	
	constructor(private chassiValidationService: ChassiValidationService,
		private translateService: TranslateService,
		private messageService: MessageService,
		private router: Router,
		private messageExceptionService: MessageExceptionService) {
	}

	ngOnInit() {
		this.chassiValidationList = this.chassiValidationService.getAllChassiValidationList();
	}

	buttonAddChassis() {
		this.searchChassiValidation();
	}

	buttonRemoveChassiList(chassiValidation: ChassiValidation) {
		this.chassiValidationList.forEach((element, index)=>{
			if(element.vin === chassiValidation.vin) {
				this.chassiValidationList.splice(index, 1);
			}			
		});
	}

	buttonNextWindow() {
		if(this.chassiValidationList.length > 0) {
			
			let isvalid: boolean = true;

			this.chassiValidationList.forEach(chassiValidation => {
				if(chassiValidation.validated == StatusChassi.invalid) {
					isvalid = false; 				
				}
			});

			if(isvalid) {
				this.chassiValidationService.setAllChassiValidationList(this.chassiValidationList);
				this.router.navigate(['plan-selection']);
			} else {
				this.messageService.add({severity:'error', summary: 'Error', detail: 'Remover os chassi com status inválido.'});
			}

		} else {
			this.messageService.add({severity:'error', summary: 'Error', detail: 'Adicionar chassi.'});
		}
	}

	buttonClean() {
		this.chassis = "";
		this.chassiValidationList = [];
		this.chassiValidationService.getAllChassiValidationListClean();
	}

	breakTextByChassi(): Array<String> {
		let chassiList: Array<String> = this.chassis.trim().split('\n');		
		chassiList = chassiList.filter((element, i) => i === chassiList.indexOf(element)) //remove duplicidade
		return chassiList;
	}

	searchChassiValidation() {
		this.breakTextByChassi().forEach(chassi => {
			const isDuplicated: boolean = this.isDuplicate(chassi);

			if(isDuplicated == false && chassi !== '') {
				this.searchChassiSaveAndVector(chassi);
			}
		});		
	}

	searchChassiSaveAndVector(chassi: String) {
		let country: String = 'BRA';

		this.chassiValidationService.getChassiValidation(country, chassi, "pt-BR")
			.subscribe((response: ChassiValidation) => {				
				const chassiValidation: ChassiValidation = this.chassiValidation(response);
				this.chassiValidationList.push(chassiValidation);
				
			}, (err) => {
				this.messageExceptionService.getMessageException(err.status, 'Sem acesso, por gentileza, fecha a página e faça o login novamente!');
			});
	}

	isDuplicate(chassi: String): boolean {
		let isValide: boolean = false;
		
		this.chassiValidationList.forEach(chassiValidation => {		
			if(chassi.trim() === chassiValidation.vin.trim()) {
				isValide = true;
			}
		});

		return isValide;
	}

	chassiValidation(chassiValidation: ChassiValidation): ChassiValidation {
		
		if (chassiValidation.errorMessage != null && chassiValidation.errorMessage.trim().toUpperCase() === 'VIN.ERROR.SAVE_VECTOR') {
			chassiValidation.validated = StatusChassi.invalid;
		} else {
			chassiValidation.validated = StatusChassi.valid;
		}
		
		return chassiValidation;
	}
}
