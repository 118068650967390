import {Component, OnInit} from '@angular/core';
import { ApsPermissionService } from 'src/app/services/aps-permission/aps-permission.service';
import { ApsPermitedScreen } from 'src/app/models/aps-permission/aps-permited-screen';
import { Router } from '@angular/router';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
	
	allPermissions: String[] = [];
	isScreenSales: boolean = false;
	isScreenSalesTelemarketing: boolean = false;
	isScreenContractSearch: boolean = false;
	isScreenContractSearchDealer: boolean = false;
	isAccessManagement: boolean = false;

	constructor(
		private apsPermissionService: ApsPermissionService,
		private router: Router, 
	) {}

	ngOnInit() {

		this.apsPermissionService.getSearchApsPermission();
		this.getPermissions();
	}

	async checkPermission(permissionName:string): Promise<boolean> {		
		
		let checkPermission = false;
		this.allPermissions.forEach(pm => {
			if (pm === permissionName) {
				checkPermission = true;
			}
		});

		return checkPermission;
	}

	async loadScreenPermissions() {

		await this.checkPermission(ApsPermitedScreen.screenSales).then((success: boolean) => {			
			if (success) {				
				this.isScreenSales = true;
			}
		});
		await this.checkPermission(ApsPermitedScreen.screenSalesTelemarketing).then((success: boolean) => {			
			if (success) {				
				this.isScreenSalesTelemarketing = true;
			}
		});
		await this.checkPermission(ApsPermitedScreen.screenContractSearch).then((success: boolean) => {			
			if (success) {				
				this.isScreenContractSearch = true;
			}
		});		
		await this.checkPermission(ApsPermitedScreen.screenContractSearchDealer).then((success: boolean) => {			
			if (success) {				
				this.isScreenContractSearchDealer = true;
			}
		});
		await this.checkPermission(ApsPermitedScreen.accessManagement).then((success: boolean) => {			
			if (success) {				
				this.isAccessManagement = true;
			}
		});						
	}	

	async getListAllPermissions(): Promise<String[]> {
		let allPermissions : String[] = [];
		await this.apsPermissionService.getListPermissions().then((list: String[]) => {
			allPermissions = list;
		});
		return allPermissions;
	}
	
	async getPermissions() {	
		/*
		lê todas as permissões do usuário logado 
		e armazena na variável allPermissions
		*/		
		await this.getListAllPermissions().then((success : String[]) => {
			this.allPermissions = success;
		});	
	
		await this.loadScreenPermissions();

	}

	async salesPermissions() {

		await this.getPermissions();

		if (this.isScreenSales) {
			
			this.router.navigate(['/sales-channel'],  {skipLocationChange: true, replaceUrl: false});
		} 
		else if (this.isScreenSalesTelemarketing) {
			
			this.router.navigate(['/sales-channel-external'],  {skipLocationChange: true, replaceUrl: false});
		} 	 
			
	}
	
	async dealerPermissions() {

		await this.getPermissions();
		
		if (this.isScreenContractSearchDealer) {
			
			this.router.navigate(['/contract-search-dealer'],  {skipLocationChange: true, replaceUrl: false});
		} 
		else if (this.isScreenContractSearch) {
			
			this.router.navigate(['/contract-search'],  {skipLocationChange: true, replaceUrl: false});
		} 		

	}

}