import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng";

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class MessageExceptionService {

  constructor(
    private messageService: MessageService,
    private translateService: TranslateService
  ) {
  }

  getMessageException(status: any, message: string) {
    if (status >= 400) {
      if (status == 401) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: message });
      } else {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('TRANSLATE.ERROR'),
          detail: this.translateService.instant('TRANSLATE.SESSION.CLOSED.DUE.INACTIVITY.REFRESH.PAGE')
        });
      }
    }
  }


}