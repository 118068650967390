import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class RemoveAuthInterceptor implements HttpInterceptor {
    authenticationRequest: HttpRequest<any>;

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // se for uma chamada sem autenticação, retirar o Authorization do header
        if (sessionStorage.getItem('encodedAccessToken') == null) {
            this.authenticationRequest = req.clone({
                headers: req.headers.delete('Authorization')
            });
        } else {
            this.authenticationRequest = req.clone({
                headers: req.headers.append('Authorization', 'Bearer ' + sessionStorage.getItem('encodedAccessToken'))
            });
        }
        return next.handle(this.authenticationRequest);
    }


}