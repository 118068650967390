import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Price } from 'src/app/models/product/price';
import { PriceDto } from 'src/app/models/product/price-dto';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class PriceService {

  private apiUrl = environment.apiUrl;
  private country: string = 'BRA';
  private operation: string = 'CAR';

  constructor(
    private httpClient: HttpClient, 
    private messageService: MessageService
  ) {
  }
  
  createNewPrice(price: Price): Observable<Price> {
    return this.httpClient.post(`${this.apiUrl}/api/v1/price`, price, httpOptions)
      .pipe(
        map((response: Price) => {
          return response;
        })
      );
  }
  
  getAllPrices(): Observable<Array<PriceDto>> {
    return this.httpClient.get(`${this.apiUrl}/api/v1/price`)
      .pipe(
        map((response: Array<PriceDto>) => {
          return response;
        })
      );
  }
 
  getByCustomersPrices(priceName: string): Observable<Array<PriceDto>> {
    return this.httpClient.get(`${this.apiUrl}/api/v1/price/price-name/${priceName}`)
      .pipe(
        map((response: Array<PriceDto>) => {
          return response;
        })
      );
  }
  
}
