export class FileFull {
    
    type: number;
    name: string;
    description: string;
    fileReference: string;
    file: string;

	constructor() {
	
	}
}