export class DealerSeller {
    
    id: number;
    sellerCode: string;
    name: string;
    phone: string;
    email: string;
    document: string;
    birthday: Date;

	constructor() {
	
	}
}