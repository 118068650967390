import { ProductList } from "../product/product-contract-manual";
import { ProductFull } from "../product/product-full";

export class ContractManual {
    
    contractId: number = null;
    country: string;
    operation: string = 'CAR';
    status: number;
    user: string;
    saleDate: Date = new Date();
    startDate: Date;
    endDate: Date;
    saleType: number;
    tmaCode:string;
    vehicleLineCode:string;
    modelYear:number;

    vin: string;
    cvvgDate: Date;
    product: ProductFull;
    oldContractId: string;
    isGlobalContract: boolean;
    message: string;
    hasError: boolean = false;
    
    constructor() {
	}
}