import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { Dealer } from "src/app/models/dealer/dealer";
import { DealerApsFordDetailAttributes } from "src/app/models/dealer/dealer-aps-ford-detail-attributes";
import { DealerCountryDto } from "src/app/models/dealer/dealer-country-dto";
import { DealerSeller } from "src/app/models/dealer/dealer-seller";
import { environment } from "src/environments/environment";

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class DealerService {

  private apiUrl = environment.apiUrl;
  private dealerCountry: DealerCountryDto;

  constructor(private httpClient: HttpClient) {
  }

  getDealer(dealer: Dealer): Observable<Dealer> {
    return this.httpClient.get<Dealer>(`${this.apiUrl}/api/dealers/${dealer.geoSalesCode}/${dealer.pandAC}`)
      .pipe(
        map((response: Dealer) => {
          return response;
        })
      );
  }

  getDealerSignature(geoSalesCode: string, pandAC: string): Observable<Dealer> {
    return this.httpClient.get<Dealer>(`${this.apiUrl}/api/dealers/signature/${geoSalesCode}/${pandAC}`)
      .pipe(
        map((response: Dealer) => {
          return response;
        })
      );
  }

  getDealerSeller(dealerSeller: DealerSeller): Observable<DealerSeller> {
    return this.httpClient.get<DealerSeller>(`${this.apiUrl}/api/sellers/${dealerSeller.sellerCode}`)
      .pipe(
        map((response: DealerSeller) => {
          return response;
        })
      );
  }

  getDealerSellerSignature(sellerK: number): Observable<DealerSeller> {
    return this.httpClient.get<DealerSeller>(`${this.apiUrl}/api/sellers/seller-id/${sellerK}`)
      .pipe(
        map((response: DealerSeller) => {
          return response;
        })
      );
  }

  getDealerName(dealerSeller: DealerSeller): Observable<DealerApsFordDetailAttributes> {
    const userName = dealerSeller.name;
    const entityName = "TMAC";
    return this.httpClient.get<DealerApsFordDetailAttributes>(`${this.apiUrl}/api/dealers/fordaps?userName=${userName}&entityName=${entityName}`)
      .pipe(
        map((response: DealerApsFordDetailAttributes) => {
          return response;
        })
      );
  }

  getDealerNameAndCode(userName: string): Observable<DealerApsFordDetailAttributes> {
    const entityName = "TMAC";
    return this.httpClient.get<DealerApsFordDetailAttributes>(`${this.apiUrl}/api/dealers/fordaps?userName=${userName}&entityName=${entityName}`)
      .pipe(
        map((response: DealerApsFordDetailAttributes) => {
          return response;
        })
      );
  }
  
  getDealerCountry(userName: string): Observable<DealerCountryDto> {
    const entityName = "TMAC";
    return this.httpClient.get<DealerCountryDto>(`${this.apiUrl}/api/dealers/dealerCountry?userName=${userName}&entityName=${entityName}`)
      .pipe(
        map((response: DealerCountryDto) => {
          return response;
        })
      );
  }
}