

export class EntryBankBoletoResponseDto {

    id: string;
    tipo: string;
    nota: string;
    serie: string;
    dealer: string;
    pedido: string;
    usuario: string;
    inclusao: string;

    constructor() {
    }
}