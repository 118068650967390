import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContractSignature } from 'src/app/models/contract/contract-signature';
import { ContractService } from 'src/app/services/contract/contract.service';

@Component({
  selector: 'app-legal-text-contract-bra-preb',
  templateUrl: './legal-text-contract-bra-preb.component.html',
  styleUrls: ['./legal-text-contract-bra-preb.component.scss']
})
export class LegalTextContractBraPrebComponent implements OnInit {

    contractSignature: ContractSignature;
    contract: number;
    document: string;
    uuid: string;
  
    constructor(
  
      private contractService: ContractService,
      private activatedRoute: ActivatedRoute,
    ) {
       
      this.contractSignature = new ContractSignature;
    }
  
    ngOnInit() {
      this.activatedRoute.queryParams.subscribe(params => {
  
        this.uuid = params.uuid;
        this.contractSummarySearch(this.uuid);
      });
    }
  
    contractSummarySearch(uuid: string) {
      this.contractService
      .getContractSignature(uuid)
      .subscribe((response: ContractSignature) => {
     
          this.contractSignature = response;   
       });
    }
  
  
  }
