export class FilterContractSearchResult {

  contractNumber: string;
	contractOriginalNumber: string;
	vin: string;	
	status: number;
	planName: string;
	dateStart: Date;
	coverageEndDate: Date;
	limite: string;

	customerDocumentType: string;
	customerDocument: string;
	customerName: string;
	customerLastName: string;
	customerPhone: string;
	customerEmail: string;
	customerCep: string;
	customerCepValid: boolean;
	customerAddress: string;
	customerAddressNumber: string;
	customerAddressComp: string;
	customerAddressDistrict: string;
	customerAddressCity: string;
	customerAddressState: string;
	customerCityIbge: string;

	dnPac: string;
	dnName: string;
	dnEmail: string;
	dnPhone: string;
	dnCity: string;
	sellerCode: string;
	sellerName: string;
	sellerPhone: string;
	sellerEmail: string;

	contractCodeFp2: number;
	country: string;
	operation: string;
	contractStatusCode: number;
	contractDateCreate: Date;
	contractDateUpdate: Date;
	contractDateEnd: Date;
	contractTypeCode: number;
	contractFinalPrice: number;
	contractRemarks: string
	productOriginalName: string;
	originalTable: string;
	productKey: number;
	productName: string;
	productDescription: string;
	productInvoiceMonths: number;
	productCode: string;
	wsd: Date;
	warrantyEndDate: Date;
	categoryVersionKey: number;
	vinStatusCode: number;
	vinUpdateDate: Date;
	vinSaleDate: Date;
	vinStartDate: Date;
	vinEndDate: Date;
	vinCost: number;
	vinSellerPrice: number;
	vinCustomerPrice: number;
	vinActualEndDate: Date;
	vinRateKey: number;
	vinKey: string;

	coverageName: string;
	coverageCategory: string;
	coverageStartDate: Date;
	coverageUpdateDate: Date;
	coverageMonths: number;
	coverageKm: number;
	coverageStartVisit: number;
	coverageVisitLimit: number;
	coverageVisitCounter: number;
	coverageAccount: string;
	coverageDescription: string;
	coverageKey: number;
	coverageCode: string;
	csId: string;

	dateExpirationLink: Date;
	paymentMethod: string
	paymentParcels: number
	contractValue: string;
	parcelValue: string;

  constructor() {
	}
}
