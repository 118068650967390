import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { StatusChassi } from 'src/app/models/enum/status-chassi';
import { VehicleInformationService } from 'src/app/services/vehicle-information/vehicle-information.service';
import { ChoosePlanFilter } from 'src/app/models/choose-plan/choose-plan-filter';
import { ChoosePlanService } from 'src/app/services/choose-plan/choose-plan.service';
import { ChoosePlan } from 'src/app/models/choose-plan/choose-plan';
import { MessageService } from 'primeng/api';
import { ChassiValidation } from 'src/app/models/chassi-validation/chassi-validation';
import { ChoosePlanProduct } from 'src/app/models/choose-plan/choose-plan-product';
import { ChoosePlanBuyng } from 'src/app/models/choose-plan/choose-plan-buyng';
import { ApsPermissionService } from 'src/app/services/aps-permission/aps-permission.service';
import { ApsPermitedScreen } from 'src/app/models/aps-permission/aps-permited-screen';
import { DomSanitizer } from '@angular/platform-browser';
import { ChoosePlanDuplicatedContract } from 'src/app/models/choose-plan/choose-plan-duplicated-contract';
import { MessageExceptionService } from 'src/app/services/message-exception/message-exception.service';
import { SaleChannel } from 'src/app/models/enum/sale-channel';
import { TranslateService } from '@ngx-translate/core';
import { SystemCountryService } from 'src/app/services/system-country/system-country.service';


export class PlanFordProtect {
	planSelectedEnum: SelectPlan;
}

export enum SelectPlan {
	TOOL_PIECE,
	TOOL,
	WARRANTY
}

@Component({
	selector: 'choose-plan',
	templateUrl: './choose-plan.component.html',
	styleUrls: ['./choose-plan.component.scss']
})
export class ChoosePlanComponent implements OnInit {

	planFordProtect: PlanFordProtect = new PlanFordProtect();
	chassiValidation: ChassiValidation;
	choosePlanFilter: ChoosePlanFilter;
	choosePlan: ChoosePlan;
	choosePlanBuyng: ChoosePlanBuyng;
	choosePlanDuplicatedContract: ChoosePlanDuplicatedContract;
	saleChannel: string;
	unityPrice: string = '$';

	constructor(private choosePlanService: ChoosePlanService,
		private router: Router,
		private route: ActivatedRoute,
		private apsPermissionService: ApsPermissionService,
		private translateService: TranslateService,
		private messageService: MessageService,
		private vehicleInformationService: VehicleInformationService,
		private sanitizer: DomSanitizer,
		private messageExceptionService: MessageExceptionService,
		private systemCountryService: SystemCountryService) {
		this.choosePlan = new ChoosePlan();
		this.choosePlanFilter = new ChoosePlanFilter();
		this.choosePlanBuyng = new ChoosePlanBuyng();
		this.choosePlanDuplicatedContract = new ChoosePlanDuplicatedContract();
		this.saleChannel = route.snapshot.paramMap.get('saleChannel');
		this.systemCountryService.setSystemCountry();
	}


	ngOnInit() {
		this.chassiValidation = this.vehicleInformationService.getAllChassiValidation();
		if (this.chassiValidation == null || this.chassiValidation.validated == StatusChassi.invalid) {
			this.router.navigate(['sales-channel', this.saleChannel], { skipLocationChange: true, replaceUrl: false });
		}

		this.apsPermissionService.permissionValidation(ApsPermitedScreen.screenPlansPolicy);
		this.getChoosePlanList();
		this.clearExtensionWarranty();

		if (this.choosePlanService.getChoosePlanBuyng() != null) {
			this.choosePlanBuyng = this.choosePlanService.getChoosePlanBuyng();
		}

		if (this.chassiValidation == null || this.chassiValidation.vin !== '') {
			this.getChoosePlanDuplicatedContract(this.chassiValidation.vin);
		}

		this.planFordProtect.planSelectedEnum = SelectPlan.WARRANTY;
	}

	clearExtensionWarranty() {
		this.choosePlanBuyng = new ChoosePlanBuyng();
	}

	addExtensionWarranty(extension: ChoosePlanProduct) {
		this.choosePlanBuyng.extension = extension;
		this.calculateTotalPrice();
	}

	addMaintenance(maintenance: ChoosePlanProduct) {
		this.choosePlanBuyng.maintenance = maintenance;
		this.calculateTotalPrice();
	}

	addPart(part: ChoosePlanProduct) {
		this.choosePlanBuyng.part = part;
		this.calculateTotalPrice();
	}

	calculateTotalPrice() {
		this.choosePlanBuyng.totalPrice = (this.choosePlanBuyng.extension.itemPrice +
			this.choosePlanBuyng.maintenance.itemPrice + this.choosePlanBuyng.part.itemPrice);
	}

	get tool() {
		return SelectPlan.TOOL;
	}

	get toolPiece() {
		return SelectPlan.TOOL_PIECE;
	}

	get warranty() {
		return SelectPlan.WARRANTY;
	}

	onClickPlan(planSelectedEnum: SelectPlan) {
		switch (planSelectedEnum) {
			case SelectPlan.TOOL:
				this.planFordProtect.planSelectedEnum = SelectPlan.TOOL;
				if (this.choosePlanDuplicatedContract.disabledManutencao == true) {
					this.messageService.add({ severity: 'warn', summary: this.translateService.instant('TRANSLATE.ATTENTION'), detail: this.translateService.instant('TRANSLATE.COSTUMER.MAINTENANCE.CONTRACT') });
				}
				break;
			case SelectPlan.TOOL_PIECE:
				this.planFordProtect.planSelectedEnum = SelectPlan.TOOL_PIECE;
				if (this.choosePlanDuplicatedContract.disabledReparo == true) {
					this.messageService.add({ severity: 'warn', summary: this.translateService.instant('TRANSLATE.ATTENTION'), detail: this.translateService.instant('TRANSLATE.COSTUMER.WEAR.CONTRACT') });
				}
				break;
			case SelectPlan.WARRANTY:
				this.planFordProtect.planSelectedEnum = SelectPlan.WARRANTY;
				if (this.choosePlanDuplicatedContract.disabledExtensao == true) {
					this.messageService.add({ severity: 'warn', summary: this.translateService.instant('TRANSLATE.ATTENTION'), detail: this.translateService.instant('TRANSLATE.COSTUMER.EXTENSION.CONTRACT') });
				}
				break;
			default:
				break;
		}
	}

	buttonComeBack() {
		this.router.navigate(['vehicle-information', this.saleChannel], { skipLocationChange: true, replaceUrl: false });
	}

	buttonClean() {
		this.choosePlanBuyng = new ChoosePlanBuyng();
	}

	buttonNext() {
		this.choosePlanBuyng.factoryDistanceLimit = this.choosePlan.factoryDistanceLimit;
		this.choosePlanBuyng.factoryTimeLimit = this.choosePlan.factoryTimeLimit;
		this.choosePlanService.setChoosePlanBuyng(this.choosePlanBuyng);
		this.router.navigate(['client-data', this.saleChannel], { skipLocationChange: true, replaceUrl: false });
	}

	getChoosePlanList() {
		if (this.choosePlanFilter != null) {
			this.choosePlanFilter.country = this.chassiValidation.country;
			this.choosePlanFilter.modelYear = this.chassiValidation.modelYear;
			this.choosePlanFilter.tma = this.chassiValidation.tma;
			this.choosePlanFilter.vehicleLine = this.chassiValidation.vehicleLine;
			this.choosePlanFilter.warrantyStartDate = this.chassiValidation.warrantyStartDate;
			this.choosePlanFilter.version = this.chassiValidation.version;

			if (this.choosePlanFilter.warrantyStartDate == null) {
				this.choosePlanFilter.warrantyStartDate = new Date();
			}
		}

		if (this.saleChannel == SaleChannel.EXTERNAL) {
			this.choosePlanFilter.saleType = 30; //Telemarketing
		} else {
			this.choosePlanFilter.saleType = 5; //dealer
		}

		this.choosePlanService.getChoosePlanProductList(this.choosePlanFilter)
			.subscribe((response: ChoosePlan) => {
				this.choosePlan = response;
				if (this.choosePlan.extensionList[0].unityPrice != null) {
					this.unityPrice = this.choosePlan.extensionList[0].unityPrice;
				}
				if (this.choosePlan.factoryDistanceLimit == null || this.choosePlan.factoryDistanceLimit == 0) {
					this.messageService.add({ severity: 'error', summary: this.translateService.instant('TRANSLATE.ERROR'), detail: this.translateService.instant('TRANSLATE.NO.RECORD.PLANS') });
				}
			}, (err) => {

				if (err.status === 404) {
					this.messageService.add({
						severity: 'error',
						summary: this.translateService.instant('TRANSLATE.ERROR'),
						detail: this.translateService.instant('TRANSLATE.VEHICLE.NO.PLANS.AVAILABLE')
					});
				} else if (err.status === 401) {

					this.messageService.add({
						severity: 'error',
						summary: this.translateService.instant('TRANSLATE.ERROR'),
						detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
					});

				} else {

					this.messageService.add({
						severity: 'error',
						summary: this.translateService.instant('TRANSLATE.ERROR'),
						detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
					});
				}

			});
	}

	private getChoosePlanDuplicatedContract(vin: string) {
		this.choosePlanService.getChoosePlanDuplicatedContract(vin)
			.subscribe((response: ChoosePlanDuplicatedContract) => {
				this.choosePlanDuplicatedContract = response;

			}, (err) => {
				this.messageExceptionService.getMessageException(err.status, this.translateService.instant('TRANSLATE.VEHICLE.NOT.ACCESS.LOGIN.AGAIN'));
			});
	}
}
