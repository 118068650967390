export class AddressPayerBoletoResponseDto {

    city: string;
    type: string;
    complement: string;
    district: string;
    label: string;
    state: string;
    streetNumber: string;
    zipCode: string;

    constructor() {
    }
}