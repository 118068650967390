export class ChoosePlanFilter {
    
	country: string;
	vehicleLine: string;
	tma: string;
	modelYear: number;
	warrantyStartDate: Date;
	saleType: number;
	version: string;

	constructor() {
		
	}
}
