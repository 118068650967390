import {Component, OnInit, VERSION} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import { ApsPermissionService } from './services/aps-permission/aps-permission.service';

@Component({
	selector: 'body',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	
	skipToMain: string;
	
	constructor(private readonly router: Router) {
	}
	
	ngOnInit() {
		this.router.events.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				this.setSkipLinkUrl(event.urlAfterRedirects);
			});
	}

	private setSkipLinkUrl(currentUrl: string) {
		if(!currentUrl.endsWith('#home-content')) {
			this.skipToMain = `${currentUrl}#home-content`;
		}
	}
}