export class ContractEdit {

	contractCodeFp2: number;
	vinKey: string;
	coverageKey: number;
	productKey: number;
	coverageCode: string;
	coverageCategory: string;
	productCode: string;
	contractStatusCode: number;
	coverageStartDate: Date;
	coverageEndDate: Date;
	coverageKm: number = 0;
	country: string;
	operation: string;
	vin: string;
	csId: string;
	contractRemarks: string;	
	coverageVisitCounter: number = 0;
	email: string;
	coverageStartVisit: number;

	constructor() {
	}
}