import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ChassiValidation } from 'src/app/models/chassi-validation/chassi-validation';
import { ChassiValidationService } from 'src/app/services/chassi-validation/chassi-validation.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { PlanSelecionService } from 'src/app/services/plan-selection/plan-selection.service';
import { PlanSelection } from 'src/app/models/plan-selection/plan-selection';
import {SelectItem} from 'primeng/api';
import { ProductService } from 'src/app/services/product/product.service';
import { Product } from 'src/app/models/product/product';
import { MotorAndModelYearGrouping } from 'src/app/models/motor-and-model-year-grouping/motor-and-model-year-grouping';
import { ApsPermissionService } from 'src/app/services/aps-permission/aps-permission.service';
import { MessageExceptionService } from 'src/app/services/message-exception/message-exception.service';

@Component({
	selector: 'app-plan-selection',
	templateUrl: './plan-selection.component.html'
})
export class PlanSelectionComponent implements OnInit {
	
	chassiValidationList: Array<ChassiValidation> = [];
	planSelectionList: Array<PlanSelection> = [];
	selectProducts: SelectItem[] = [];
	products: Array<Product> = [];

	constructor(private chassiValidationService: ChassiValidationService,
		private planSelecionService: PlanSelecionService,
		private translateService: TranslateService,
		private messageService: MessageService,
		private productService: ProductService,
		private router: Router,
		private apsPermissionService: ApsPermissionService,
		private messageExceptionService: MessageExceptionService) {
	}

	ngOnInit() {
		this.chassiValidationList = this.chassiValidationService.getAllChassiValidationList();

		if(this.chassiValidationList.length <= 0) {
			this.router.navigate(['direct-sale']);
		}

		this.getplanSelecion();
		this.getProducts();	
	}

	getplanSelecion() {
		this.planSelecionService.getPlanSelection(this.chassiValidationList)
			.subscribe((response: Array<PlanSelection>) => {
				this.planSelectionList = response;
			}, (err) => {
				this.messageExceptionService.getMessageException(err.status, 'Sem acesso, por gentileza, fecha a página e faça o login novamente!');
			});
	}

	getProducts() {
		this.productService.getProducts()
			.subscribe((products: Array<Product>)=> {
				this.products = products;

				products.forEach(product => {
					this.selectProducts.push({label: product.name, value: product.id});
				});
			});
	}

	getSelectedProducts(event: Event, motorAndModelYearGrouping: MotorAndModelYearGrouping) {
		let unitPrice: number = 0;
		for (let i = 0; i < motorAndModelYearGrouping.plan.length; i++) {
			unitPrice = unitPrice + this.getPriceProduct(motorAndModelYearGrouping.plan[i]);
		}
		motorAndModelYearGrouping.unitPrice = unitPrice;
		motorAndModelYearGrouping.totalPrice = unitPrice * motorAndModelYearGrouping.qtd;
	}

	getPriceProduct(value: any): number{
		for (let i = 0; i < this.products.length; i++) {
			if (this.products[i].id === value) {
				return this.products[i].customerPrice;
			}
		}
	}

	getNameProduct(value: number): string{
		for (let i = 0; i < this.products.length; i++) {
			if (this.products[i].id === value){
				return this.products[i].name;
			}
		}
	}

	buttonPlan() {
		this.planSelectionList.forEach(planSelection =>{
			planSelection.motorAndModelYearGrouping.forEach(motorAndModelYearGrouping => {
				motorAndModelYearGrouping.plan = null;
				motorAndModelYearGrouping.totalPrice = 0;
				motorAndModelYearGrouping.unitPrice = 0;		
			})
		});
	}

	buttonComeBack() {
		this.router.navigate(['direct-sale']);
	}

	buttonNextWindow() {		
		let isValid: boolean = true;

		this.planSelectionList.forEach(planSelection =>{
			planSelection.motorAndModelYearGrouping.forEach(motorAndModelYearGrouping => {
				if(motorAndModelYearGrouping.plan == null || motorAndModelYearGrouping.plan.length <= 0) {
					isValid = false;
				}
			})
		});
		
		if(isValid) {
			this.planSelecionService. setAllPlanSelectionList(this.planSelectionList);
			this.router.navigate(['customer']);
		} else {
			this.messageService.add({severity:'error', summary: 'Error', detail: 'Selecionar plano para o veículo.'});
		}
	}
	
}
