import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng';
import { ConstantsFordProtect } from 'src/app/models/constants/constants-ford-protect';
import { PriceName } from 'src/app/models/enum/price-name';
import { PriceDto } from 'src/app/models/product/price-dto';
import { PriceService } from 'src/app/services/price/price.service';

@Component({
  selector: 'eligibility-details-edit',
  templateUrl: './eligibility-details-edit.component.html',
  styleUrls: ['./eligibility-details-edit.component.scss']
})
export class EligibilityDetailsEditComponent implements OnInit {
  
  listStatus = [
    { label: 'TRANSLATE.SELECT' },
    { label: 'TRANSLATE.ACTIVE', value: ConstantsFordProtect.COVERAGE_ACTIVE },
    { label: 'TRANSLATE.INACTIVE', value: ConstantsFordProtect.COVERAGE_INACTIVE }
  ];

  selectedStatus: number;
  placeholdercustomerPrices: string = 'TRANSLATE.SELECT';
  customersPricesConcat: Array<any> = [];
  customersPrices: Array<any> = [];
  priceName: string = PriceName.PRICE_CUSTOMER;
  selectedCustomerPrice: number;
  idPriceName: number;
  selectDisabled: boolean;

  constructor(
    private priceService: PriceService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private router: Router
  ) { }

  ngOnInit() {

    this.getByCustomersPrices();
    this.buttonCancel();
  }
  
  buttonReturn() {
    this.router.navigate(['view-plans'], { skipLocationChange: false, replaceUrl: false });
  }

  buttonEdit() {

    
    this.selectDisabled = false;
     
    (<HTMLInputElement>document.getElementById("titleDetails")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("titleEdit")).style.display = 'block';    
    (<HTMLInputElement>document.getElementById("subTitle")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnReturn")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnEdit")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnCancel")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnSave")).style.display = 'block';

  }

  buttonCancel() {

    this.selectDisabled = true;      
    (<HTMLInputElement>document.getElementById("status")).style.color = '#202020';
    (<HTMLInputElement>document.getElementById("status")).style.backgroundColor = '#E5E5E5';  
    (<HTMLInputElement>document.getElementById("customerPrice")).style.color = '#202020';
    (<HTMLInputElement>document.getElementById("customerPrice")).style.backgroundColor = '#E5E5E5';
    (<HTMLInputElement>document.getElementById("titleDetails")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("titleEdit")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("subTitle")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnReturn")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnEdit")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnCancel")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnSave")).style.display = 'none';

    this.restoringValues(); 
  }

  statusChange(event: any) {
    this.selectedStatus = event.value;
  }

  getByCustomersPrices() {

    this.priceService.getByCustomersPrices(this.priceName)
      .subscribe((response: Array<PriceDto>) => {

        this.customersPrices = response;

        this.customersPricesConcat = this.customersPrices.map(object => {

          let formattedPrice;

          if (object.itemPriceUnit === 'R$') {
            formattedPrice = object.itemPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

          } else if (object.itemPriceUnit === '$') {
            formattedPrice = object.itemPrice.toLocaleString('en-US', { style: 'currency', currency: 'USD' });

          }
          return {
            label: `${formattedPrice}`,
            id: object.id
          }
        });

        if (this.customersPricesConcat.length > 0) {
          this.placeholdercustomerPrices = 'TRANSLATE.SELECT';
        } else {
          this.placeholdercustomerPrices = 'TRANSLATE.NO.RESULT.FOUND';
        }

      }, (err) => {
        if (err.status == 400) {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('TRANSLATE.ERROR'),
            detail: this.translateService.instant('TRANSLATE.PRICE.NOT.FOUND')
          });
        } else if (err.status === 401) {

          this.messageService.add({
           severity: 'error',
           summary: this.translateService.instant('TRANSLATE.ERROR'),
           detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
          });
       
       } else {
       
         this.messageService.add({
           severity: 'error',
           summary: this.translateService.instant('TRANSLATE.ERROR'),
           detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
         });
       }
       
      });
  }
  
  customerPriceChange(event: any) {
    this.selectedCustomerPrice = event.value;
    const idPrices = JSON.stringify(this.selectedCustomerPrice);
    this.idPriceName = JSON.parse(idPrices).id;
  }

  buttonSave() {

    if (this.selectedStatus === undefined) {

      this.selectedStatus = null;
    }
    if (this.selectedCustomerPrice === undefined) {

      this.selectedCustomerPrice = null;
    }
    else {
      this.restoringValues(); 
    }
  
  }
  
  restoringValues() {
    this.selectedStatus = undefined;
    this.selectedCustomerPrice = undefined;
  }
  
}
