import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ApsPermitedScreen } from 'src/app/models/aps-permission/aps-permited-screen';
import { ChassiValidation } from 'src/app/models/chassi-validation/chassi-validation';
import { DealerCountryDto } from 'src/app/models/dealer/dealer-country-dto';
import { LanguageDealer } from 'src/app/models/enum/language-dealer';
import { SaleChannel } from 'src/app/models/enum/sale-channel';
import { StatusChassi } from 'src/app/models/enum/status-chassi';
import { ApsPermissionService } from 'src/app/services/aps-permission/aps-permission.service';
import { ChassiValidationService } from 'src/app/services/chassi-validation/chassi-validation.service';
import { ChoosePlanService } from 'src/app/services/choose-plan/choose-plan.service';
import { ClientDataService } from 'src/app/services/client-data/client-data.service';
import { ContractSummaryService } from 'src/app/services/contract-summary/contract-summary.service';
import { DealerService } from 'src/app/services/dealer/dealer.service';
import { MessageExceptionService } from 'src/app/services/message-exception/message-exception.service';
import { SystemCountryService } from 'src/app/services/system-country/system-country.service';
import { VehicleInformationService } from 'src/app/services/vehicle-information/vehicle-information.service';

@Component({
	selector: 'app-sales-channel',
	templateUrl: './sales-channel.component.html',
	styleUrls: ['./sales-channel.component.css']
})
export class SalesChannelComponent implements OnInit {

	isChassi: boolean = true; // vai ficar true ate definir a regra de negocio sem chassi
	chassi: string = "";
	chassiValidation: ChassiValidation;
	inputChassiStyleCss: String = "width: 200px; height: 34px; font-size: 16px;";
	isValidChassi: number = StatusChassi.valid;
	saleChannel: String = SaleChannel.DEALER;
	dealerCountry: DealerCountryDto;
	dealerName: string = sessionStorage.getItem('userId');
	lang: string;
	errorMessageVin: string;

	constructor(private chassiValidationService: ChassiValidationService,
		private vehicleInformationService: VehicleInformationService,
		private translateService: TranslateService,
		private messageService: MessageService,
		private router: Router,
		private apsPermissionService: ApsPermissionService,
		private choosePlanService: ChoosePlanService,
		private clientDataService: ClientDataService,
		private contractSummaryService: ContractSummaryService,
		private messageExceptionService: MessageExceptionService,
		private dealerService: DealerService,
		private systemCountryService: SystemCountryService
	) {
		this.systemCountryService.setSystemCountry();
	}

	ngOnInit() {
		this.apsPermissionService.permissionValidation(ApsPermitedScreen.screenSales);
		this.vehicleInformationService.getAllChassiValidationClean();
		this.choosePlanService.getChoosePlanBuyngClean();
		this.clientDataService.getClientDataClean();
		this.contractSummaryService.getContractSummaryClean();

		this.getDealerCountry();
	}

	buttonNext() {
		this.messageService.clear();
		if (this.chassiValidation && this.chassiValidation.validated == StatusChassi.valid) {
			this.router.navigate(['vehicle-information', this.saleChannel], { skipLocationChange: true, replaceUrl: false });
		} else {
			this.messageService.add({ severity: 'error', summary: this.translateService.instant('TRANSLATE.ERROR'), detail: this.translateService.instant('TRANSLATE.CHASSI.INVALID') });
		}
	}

	onKeyUp(event: KeyboardEvent): void {

		this.chassi = this.chassi.toUpperCase();
		if (event.key !== "Control" && this.chassi.length === 17) {
			this.buttonSearchChassi();
		}
	}

	buttonSearchChassi() {
		this.messageService.clear();
		this.chassiValidationService.getAllChassiValidationListClean();

		if (this.chassi !== '') {
			this.searchChassiSaveAndVector(this.chassi);
		}
		else {
			this.messageService.add({
				severity: 'error',
				summary: this.translateService.instant('TRANSLATE.ERROR'),
				detail: this.translateService.instant('TRANSLATE.ENTER.CHASSIS.CORRECTLY')
			});
		}
	}

	searchChassiSaveAndVector(chassi: string) {
		this.messageService.clear();
		this.chassiValidationService.getChassiValidation(this.systemCountryService.getSystemCountry(), chassi, this.getDealerLanguage())
			.subscribe((response: ChassiValidation) => {

				this.chassiValidation = this.verifyChassiValidation(response);
				this.chassiValidation.vin = chassi;
				this.chassiValidation.idVinCategoryVersion = response.idVinCategoryVersion;
				this.errorMessageVin = this.chassiValidation.errorMessage;

				if (this.chassiValidation.validated == StatusChassi.valid
					&& this.errorMessageVin !== 'VIN.ERROR.SAVE_VECTOR') {

					this.messageService.add({
						severity: 'success',
						summary: this.translateService.instant('TRANSLATE.SUCCESS'),
						detail: this.translateService.instant('TRANSLATE.VEHICLE.FOUND')
					});
					this.vehicleInformationService.setAllChassiValidation(this.chassiValidation);
					this.inputChassiStyleCss = this.inputChassiStyleCss + ' border: 2px solid #0c8e1c;';

				}
				else {

					this.inputChassiStyleCss = this.inputChassiStyleCss + ' border: 2px solid #ec2626;';
					this.messageService.add({
						severity: 'error',
						summary: this.translateService.instant('TRANSLATE.ERROR'),
						detail: this.translateService.instant('TRANSLATE.VEHICLE.NOT.FOUND.TYPE.AGAIN')
					});
				}
			}, (err) => {

					this.chassiValidation = null;
					if (err.status === 400) {
						this.messageService.add({
							severity: 'error',
							summary: this.translateService.instant('TRANSLATE.ERROR'),
							detail: this.translateService.instant('TRANSLATE.VEHICLE.NOT.FOUND.TYPE.AGAIN')
						});

					} else if (err.status === 401) {

						this.messageService.add({
							severity: 'error',
							summary: this.translateService.instant('TRANSLATE.ERROR'),
							detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
						});

					} else {

						this.messageService.add({
							severity: 'error',
							summary: this.translateService.instant('TRANSLATE.ERROR'),
							detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
						});
					}

				});
	}

	verifyChassiValidation(chassiValidation: ChassiValidation): ChassiValidation {

		if (chassiValidation.errorMessage != null && chassiValidation.errorMessage.trim().toUpperCase() === 'TRANSLATE.ERROR.SAVE_VECTOR') {
			chassiValidation.validated = StatusChassi.invalid;
		} else {
			chassiValidation.validated = StatusChassi.valid;
		}

		return chassiValidation;
	}

	/*
	 * Consulta no backend o país do dealer
	 */
	getDealerCountry() {
		this.dealerService.getDealerCountry(this.dealerName)
			.subscribe(response => {
				this.dealerCountry = response;
			}, (error) => {

				if (error.status === 404) {
					// Esse erro será analizado e tratado futuramente.

				} if (error.status === 401) {

					this.messageService.add({
					 severity: 'error',
					 summary: this.translateService.instant('TRANSLATE.ERROR'),
					 detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
					});
			 
			 } else {
			 
				 this.messageService.add({
					 severity: 'error',
					 summary: this.translateService.instant('TRANSLATE.ERROR'),
					 detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
				 });
			 }

			});
	}

	/*
	 * Recupera o pais do usuario logado pelo token de acesso;
	 * Caso não encontre, recupera o país do SITECODE;
	 * Em ambos os casos, faz o parse do país do dealer com a tradução oficial (pt-BR, es-CL, es-CO etc)
	 */
	getDealerLanguage(): string {
		if (this.systemCountryService.getSystemCountry() != null) {
			this.lang = LanguageDealer[this.systemCountryService.getSystemCountry()];
		} else {
			this.lang = LanguageDealer[this.dealerCountry.countryAlternative];
		}
		return this.lang;
	}

}
