import { ChoosePlanProduct } from "./choose-plan-product";

export class ChoosePlan {
    
	factoryTimeLimit: number;
	factoryDistanceLimit: number;
	extensionList: Array<ChoosePlanProduct> = []
	maintenanceList: Array<ChoosePlanProduct> = []
	partList: Array<ChoosePlanProduct> = []
	
	constructor() {
		
	}
}
