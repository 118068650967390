export class Dealer {
    
    dealerCode: string; 
    dealerPAndAC: string;
    pandAC: string;
    divisionCode: string;
    oldRegionCode: string;
    geoSalesCode: string;
    dealerSubCode: string;
    legalName: string;
    dealerName: string;
    dealerEmail : string;
    dealerPhone : string;

	constructor() {
	
	}
}