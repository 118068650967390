export class SellerFull {

    code: string;
    name: string;
    phone: string;
    email: string;
    document: string;
    birthday: Date;

}
