export class PaymentMethod {

	uuid: string;
	cardNumber: string;
	cardValidateMonth: number;
  cardValidateYear: number;
	cardSecurityCode: number;
	cardDocument: string;
	cardName: string;
	cardNumberOfInstallment: number;
	cardTypeEnum: string;

	constructor() {
	}
}