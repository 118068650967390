import { AdditionalInformationPagarMe } from "./additional-information-pagar-me";
import { DebitPagarMe } from "./debit-pagar-me";

export class PixPagarMe {
	expires_in: String;
	additional_information: Array<AdditionalInformationPagarMe> = [];

	constructor() {
	
	}
}