import { FilterContractSearchResultSimplified } from "./filter-contract-search-result-simplified";

export class FilterContractSearchResultTotalRecord {
	
	totalRecord: number;
    filterContractSearchResultSimplifiedDtos: Array<FilterContractSearchResultSimplified> = [];

  	constructor() {
	}
}
