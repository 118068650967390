export class FilterContractSearch {
		[x: string]: any;

    vin: string = '';
    document: string = '';
    contractStatus: number = 0;
    contractDateStart: Date = null;
    contractDateEnd: Date = null;
    validityDateStart: Date = null;
    validityDateEnd: Date = null;
    dealer: string = '';
    contractNumber: number = null;
    contractOriginalNumber: string = '';
    countries: Array<string>;
    globalContract: number=  0;
    salesTypeFordId: number = 0;
    productId: number = 0;
    rowsPage: number = 20; //indica a quantidade de resgistro que vem da busca no banco
    numberPage: number = 0; //indica o numero da pagina para a paginação

    constructor() {
	}
}
