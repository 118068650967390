import { CustomerFull } from "../customer/customer -full";
import { DealerFull } from "../dealer/dealerFull";
import { PaymentFull } from "../payments/payment-full";
import { ProductFull } from "../product/product-full";
import { SellerFull } from "../seller/sellerFull";
import { VinFull } from "../vin/vin-full";

export class ContractPendingSignature {
    subscribe(arg0: (response: ContractPendingSignature) => void, arg1: (err: any) => void): ContractPendingSignature {
        throw new Error('Method not implemented.');
    }
    contractId: number;
    country: string;
    operation: string;
    status: number;
    user: string;
    saleDate: Date;
    startDate: Date;
    saleType: number;
    productPolicyId: number;
    finalPrice: number;
    odometerStart: number;

    vin: VinFull = new VinFull();
    products: Array<ProductFull> = [];
    dealers: DealerFull = new DealerFull();
    sellers: SellerFull = new SellerFull();
    payments: PaymentFull = new PaymentFull();
    customers: CustomerFull = new CustomerFull;

    constructor() {
    }
}