import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng';
import { ConstantsFordProtect } from 'src/app/models/constants/constants-ford-protect';
import { ViewPlans } from 'src/app/models/enum/view-plans';
import { NewPlansApplication } from 'src/app/models/interface/common/new-plans.application';
import { CoverageCategoryOws } from 'src/app/models/product/coverage-category-ows';
import { PriceDto } from 'src/app/models/product/price-dto';
import { ProductPolicy } from 'src/app/models/product/product-policy';
import { ProductVisit } from 'src/app/models/product/product-visit';
import { ApsPermissionService } from 'src/app/services/aps-permission/aps-permission.service';
import { CoverageService } from 'src/app/services/coverage/coverage.service';
import { PriceService } from 'src/app/services/price/price.service';
import { ProductPolicyService } from 'src/app/services/product-policy/product-policy.service';
import { ProductService } from 'src/app/services/product/product.service';

@Component({
  selector: 'view-plans',
  templateUrl: './view-plans.component.html',
  styleUrls: ['./view-plans.component.scss']
})
export class ViewPlansComponent implements OnInit {

  bottonItems: NewPlansApplication[];
  selectedNewPlans: NewPlansApplication;
  selectTypePlan: string;
  product: any;
  coverage: CoverageCategoryOws;
  products: Array<any> = [];
  selectedProducts: Array<any> = [];
  selectedCountries: Array<string> = [];
  selectedProductsResult: Array<any> = [];

  statusPlan = [
    { label: 'TRANSLATE.PRODUCT', value: ViewPlans.PRODUCT },
    { label: 'TRANSLATE.COVERAGE', value: ViewPlans.COVERAGE },
    { label: 'TRANSLATE.PRICE', value: ViewPlans.PRICE },
    { label: 'TRANSLATE.ELIGIBILITY', value: ViewPlans.ELIGIBILITY }
  ];

  constructor(
    private apsPermissionService: ApsPermissionService,
    public translateService: TranslateService,
    private messageService: MessageService,
    private router: Router,
    private productService: ProductService,
    private coverageService: CoverageService,
    private productPolicyService: ProductPolicyService,
    private priceService: PriceService
  ) { }

  ngOnInit() {
    this.getMenu();
    this.selectTypePlan = ViewPlans.PRODUCT;
    this.findAllPrices();
  }

  countries() {

    return [
      { label: this.translateService.instant('TRANSLATE.ARGENTINA'), value: ConstantsFordProtect.ARG },
      { label: this.translateService.instant('TRANSLATE.BRAZIL'), value: ConstantsFordProtect.BRA },
      { label: this.translateService.instant('TRANSLATE.CHILE'), value: ConstantsFordProtect.CHL },
      { label: this.translateService.instant('TRANSLATE.COLOMBIA'), value: ConstantsFordProtect.COL }
    ];
  }

  countryChange(event: any) {
    this.selectedCountries = event.value;
    this.getAllPlansByCountry(this.selectedCountries);
  }

  productChange(event: any) {
    this.selectedProducts = event.value;
  }

  async getMenu() {

    // renderiza os itens de menu, na primeira vez que acessar a tela
    this.navMenuItemsArray();

    // renderiza ao fazer a troca de linguagem
    this.translateService.onLangChange.subscribe(
      async (translate: LangChangeEvent) => (
        this.navMenuItemsArray()
      ));
  }

  async navMenuItemsArray() {

    this.bottonItems = [
      {
        label: this.translateService.instant('TRANSLATE.ADD'),
        icon: 'fa fa-plus',
      },
      {
        label: this.translateService.instant('TRANSLATE.PRODUCT'),
        routerLink: '/new-product'
      },
      {
        label: this.translateService.instant('TRANSLATE.COVERAGE'),
        routerLink: '/new-coverage'
      },
      {
        label: this.translateService.instant('TRANSLATE.PRICE'),
        routerLink: '/new-price'
      },
      {
        label: this.translateService.instant('TRANSLATE.ELIGIBILITY'),
        routerLink: '/new-eligibility'
      }
    ];
  }

  changeLang(plan: string) {

    if (plan === 'Produto' || plan === 'Producto') {
      this.router.navigate(['new-product'], { skipLocationChange: false, replaceUrl: false });

    } else if (plan === 'Cobertura' || plan === 'Cobertura') {
      this.router.navigate(['new-coverage'], { skipLocationChange: false, replaceUrl: false });

    } else if (plan === 'Preço' || plan === 'Precio') {
      this.router.navigate(['new-price'], { skipLocationChange: false, replaceUrl: false });

    } else if (plan === 'Elegibilidade' || plan === 'Elegibilidad') {
      this.router.navigate(['new-eligibility'], { skipLocationChange: false, replaceUrl: false });

    } else {
      this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'URL, não encontrada.' });
    }

  }

  generalSearch() {
    this.cleanFilters();
    this.findAllPrices();
  }

  findAllPrices() {

    if (this.selectTypePlan === ViewPlans.PRICE) {

      if (this.selectedProducts.length > 0) {

        this.selectedProductsResult = this.selectedProducts;
      } else {

        this.priceService.getAllPrices()
          .subscribe((response: Array<PriceDto>) => {

            this.products = response;

          }, (err) => {
            if (err.status == 400) {
              this.messageService.add({
                severity: 'error',
                summary: this.translateService.instant('TRANSLATE.ERROR'),
                detail: this.translateService.instant('TRANSLATE.PRICE.NOT.FOUND')
              });
            } else if (err.status === 401) {

              this.messageService.add({
                severity: 'error',
                summary: this.translateService.instant('TRANSLATE.ERROR'),
                detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
              });

            } else {

              this.messageService.add({
                severity: 'error',
                summary: this.translateService.instant('TRANSLATE.ERROR'),
                detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
              });
            }

          });

      }
    }

  }

  getAllPlansByCountry(countries: Array<string>) {

    if (countries.length > 0) {

      switch (this.selectTypePlan) {
        case ViewPlans.PRODUCT:

          if (this.selectedProducts.length > 0) {

            this.selectedProductsResult = this.selectedProducts;
          } else {

            this.productService.getProductsByCountry(countries)
              .subscribe((response: Array<ProductVisit>) => {

                this.products = response.map(objProduct => {
                  objProduct.country = this.getCountryName(objProduct.country);
                  return objProduct;
                });

              }, (err) => {
                if (err.status == 400) {

                  this.messageService.add({
                    severity: 'error',
                    summary: this.translateService.instant('TRANSLATE.ERROR'),
                    detail: this.translateService.instant('TRANSLATE.PRODUCTS.NOT.FOUND')
                  });
                } else if (err.status === 401) {

                  this.messageService.add({
                    severity: 'error',
                    summary: this.translateService.instant('TRANSLATE.ERROR'),
                    detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
                  });

                } else {

                  this.messageService.add({
                    severity: 'error',
                    summary: this.translateService.instant('TRANSLATE.ERROR'),
                    detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
                  });
                }

              });
          }
          break;

        case ViewPlans.COVERAGE:

          if (this.selectedProducts.length > 0) {

            this.selectedProductsResult = this.selectedProducts;
          } else {

            this.coverageService.getCoveragesByCountry(countries)
              .subscribe((response: Array<CoverageCategoryOws>) => {

                this.products = response.map(objCoverage => {
                  objCoverage.country = this.getCountryName(objCoverage.country);
                  return objCoverage;
                });

              }, (err) => {
                if (err.status == 400) {

                  this.messageService.add({
                    severity: 'error',
                    summary: this.translateService.instant('TRANSLATE.ERROR'),
                    detail: this.translateService.instant('TRANSLATE.COVERAGE.NOT.FOUND')
                  });
                } else if (err.status === 401) {

                  this.messageService.add({
                    severity: 'error',
                    summary: this.translateService.instant('TRANSLATE.ERROR'),
                    detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
                  });

                } else {

                  this.messageService.add({
                    severity: 'error',
                    summary: this.translateService.instant('TRANSLATE.ERROR'),
                    detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
                  });
                }

              });
          }
          break;

        case ViewPlans.ELIGIBILITY:

          if (this.selectedProducts.length > 0) {

            this.selectedProductsResult = this.selectedProducts;
          } else {

            this.productPolicyService.getAllProductPoliciesByCountry(countries)
              .subscribe((response: Array<ProductPolicy>) => {

                this.products = response.map(objEligibility => {
                  objEligibility.country = this.getCountryName(objEligibility.country);
                  return objEligibility;
                });

              }, (err) => {

                if (err.status == 400) {
                  this.messageService.add({
                    severity: 'error',
                    summary: this.translateService.instant('TRANSLATE.ERROR'),
                    detail: this.translateService.instant('TRANSLATE.ELEGIBILITY.NOT.FOUND')
                  });
                } else if (err.status === 401) {

                  this.messageService.add({
                    severity: 'error',
                    summary: this.translateService.instant('TRANSLATE.ERROR'),
                    detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
                  });

                } else {

                  this.messageService.add({
                    severity: 'error',
                    summary: this.translateService.instant('TRANSLATE.ERROR'),
                    detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
                  });
                }

              });

          }
          break;
      }
    }

  }

  getCountryName(code: string) {
    const countryMap: {[key: string]: string} = {
      'ARG': this.translateService.instant('TRANSLATE.ARGENTINA'),
      'BRA': this.translateService.instant('TRANSLATE.BRAZIL'),
      'CHL': this.translateService.instant('TRANSLATE.CHILE'),
      'COL': this.translateService.instant('TRANSLATE.COLOMBIA')
    }

    return countryMap[code] || code;
  }

  validateSearch() {

    if (this.selectTypePlan === ViewPlans.PRICE) {

      if (this.selectedProducts.length > 0) {

        this.selectedProductsResult = this.selectedProducts;
      } else {

        this.findAllPrices();
        this.selectedProductsResult = this.products;

      }

    } else if (this.selectedCountries.length > 0) {

      if (this.selectedProducts.length > 0) {

        this.selectedProductsResult = this.selectedProducts;
      } else {

        this.selectedProductsResult = this.products;

      }

    } else {

      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('TRANSLATE.ERROR'),
        detail: this.translateService.instant('TRANSLATE.ERROR.SELECT.COUNTRY')
      });
    }

  }

  cleanFilters() {
    this.products = [];
    this.selectedCountries = [];
    this.selectedProductsResult = [];
    this.selectedProducts = [];
    this.product = "";
    this.findAllPrices();
  }

}