import { AbstractControl } from "@angular/forms";

export function validationCpfCnpj(control: AbstractControl) {

  const cpfCnpjGet = control.value?.trim();

  if (cpfCnpjGet) {

    const cpfCnpj = cpfCnpjGet.replace(/[^\d]+/g, '');

    if (!cpfCnpj || cpfCnpj.length !== 11 && cpfCnpj.length !== 14) {
      return { invalidCpf: true };
    }

    if (cpfCnpj.length === 11) {

      let add = 0;
      for (let i = 0; i < 9; i++) {
        add += parseInt(cpfCnpj.charAt(i)) * (10 - i);
      }

      let result = 11 - (add % 11);
      result = result === 10 || result === 11 ? 0 : result;
      if (result !== parseInt(cpfCnpj.charAt(9))) {
        return { invalidCpf: true };
      }

      add = 0;
      for (let i = 0; i < 10; i++) {
        add += parseInt(cpfCnpj.charAt(i)) * (11 - i);
      }

      result = 11 - (add % 11);
      result = result === 10 || result === 11 ? 0 : result;
      if (result !== parseInt(cpfCnpj.charAt(10))) {
        return { invalidCpf: true };
      }

    }
    else if (cpfCnpj.length === 14) {

      var tamanho = cpfCnpj.length - 2;
      var numeros = cpfCnpj.substring(0, tamanho);
      var digitos = cpfCnpj.substring(tamanho);
      var soma = 0;
      var pos = tamanho - 7;

      for (var i = tamanho; i >= 1; i--) {
        soma += +numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) {
          pos = 9;
        }
      }

      var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

      if (+resultado !== +digitos.charAt(0)) {
        return { invalidCnpj: true };
      }

      tamanho += 1;
      numeros = cpfCnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;

      for (var i = tamanho; i >= 1; i--) {
        soma += +numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) {
          pos = 9;
        }
      }

      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

      if (+resultado !== +digitos.charAt(1)) {
        return { invalidCnpj: true };
      }
    }

  } else {
    console.log("CPF ou CNPJ indefinidos: " + cpfCnpjGet);
  }
  return null;

}