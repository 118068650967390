import { environment } from "src/environments/environment";

export class ConstantsFordProtect {
	public static SYSTEM_COUNTRY: string = "SYSTEM_COUNTRY";
	public static TERM_UNIT: string = "MO";
	public static DISTANCE_UNIT: string = "KM";
	public static MAINTENANCE: string = "MANUTENCAO";
	public static EXTENSION: string = "EXTENSAO";
	public static REPAIRS: string = "REPAROS";
	public static SIGNALS: string = "SINISTROS";
	public static WINCHES: string = "GUINCHO";
	public static WEAR: string = "DESGASTE";

	// paises
	public static BRA: string = "BRA";
	public static ARG: string = "ARG";
	public static CHL: string = "CHL";
	public static COL: string = "COL";
	
	public static PRICE_CUSTOMER: string = "PRICE_CUSTOMER";
	public static SELLER_PRICE: string = "SELLER_PRICE";
	public static COST_FORD: string = "COST_FORD";
	public static REAL: string = "R$";
	public static PESO: string = "$";
	public static DEALER: number = 5;
	public static TELEMARKETING: number = 30;
	public static COVERAGE_ACTIVE: number = 1;
	public static COVERAGE_INACTIVE: number = 0;
	public static PRODUCT_ACTIVE: number = 25;
	public static PRODUCT_INACTIVE: number = 30;
	
	public static OPEN_API_ENDPOINT = environment.apiUrl+"/api/contracts/signature/, "+environment.apiUrl+"/api/contracts/contract-signature/save, "+environment.apiUrl+"/api/sellers/seller-id/, "+environment.apiUrl+"/api/dealers/signature/";	
	// adicionar as URLS que são abertas no front
	public static OPEN_URL_FRONT = "/contract-signature?uuid=";

	// contracts
	public static CONTRACT_ATIVO: number = 5;
	public static CONTRACT_BLOQUEADO: number = 10;
	public static CONTRACT_CANCELADO: number = 15;
	public static CONTRACT_FINALIZADO: number = 20;
	public static CONTRACT_CRIADO: number = 25;
	public static CONTRACT_PENDENTE_PAGAMENTO: number = 30;
	public static CONTRACT_PENDENTE_CANCELAMIENTO: number = 55;
	public static CONTRACT_PENDENTE_ACTIVACAO: number = 60;
	public static CONTRACT_PENDENTE_ALTERACAO: number = 65;
	public static CONTRACT_COM_ERRO: number = 95;

	// dates
	public static DATE_START: string = "2024-01-01";
	public static DATE_END: string = "2099-01-01";

	// Invoices
	public static INVOICE: string = "Nota Fiscal";

	// Months
	public static JANUARY: string = "01";
	public static FEBRUARY: string = "02";
	public static MARCH: string = "03";
	public static APRIL: string = "04";
	public static MAY: string = "05";
	public static JUNE: string = "06";
	public static JULY: string = "07";
	public static AUGUST: string = "08";
	public static SEPTEMBER: string = "09";
	public static OCTOBER: string = "10";
	public static NOVEMBER: string = "11";
	public static DECEMBER: string = "12";

	// file types
	public static TERMO_CONTRATACAO: number = 1;
	public static TERMO_COBRANCA: number = 2;
	public static CONTRATACAO_SUCESSO_COL: number = 6;
	public static CONTRATACAO_SUCESSO_CHL: number = 7;

	// product codes
	public static BAS1: string = "BAS1";
	public static PLS1: string = "PLS1";
	public static PREB: string = "PREB";
	public static PMPR: string = "PMPR";
	public static FLEEC: string = "FLEEC";
    public static FORD_GO: string = "FDGO";

}

