import {Component, OnInit, VERSION} from '@angular/core';
import {environment} from '../../../environments/environment';

/** Class for the app component that is bootstrapped to run the application
 */
@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
	
	version: string = VERSION.full;	/** the Angular version */
	envProd: boolean = environment.production; /** whether we are production or not */
	yearCorporateDate: number;

	constructor() {
	}

	ngOnInit() {
		let newDate = new Date();
		this.yearCorporateDate = newDate.getFullYear();
	}
}