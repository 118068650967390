import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { ChassiValidation } from "src/app/models/chassi-validation/chassi-validation";
import { environment } from "src/environments/environment";

const httpOptions = {
	headers: new HttpHeaders({
        'Content-Type': 'application/json'
	})
};

@Injectable({
  providedIn: 'root'
})
export class ChassiValidationService {

    private apiUrl = environment.apiUrl;
    private chassiValidationList: Array<ChassiValidation> = [];

    constructor(private httpClient: HttpClient) {
    }

    getChassiValidation(country: String , chassi: String, lang: String): Observable<ChassiValidation> {
      
      return this.httpClient.get<ChassiValidation>(`${this.apiUrl}/api/vins/validate/${country}/${chassi}?lang=${lang}`)
        .pipe(
          map((response: ChassiValidation) => {
            return response;
          })
        );
    }

    setAllChassiValidationList(chassiValidations: Array<ChassiValidation>) {
      this.chassiValidationList = chassiValidations;
    }

    getAllChassiValidationList(): Array<ChassiValidation> {
      return this.chassiValidationList;
    }

    getAllChassiValidationListClean() {
      this.chassiValidationList = [];
    }
}
