import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng';
import { ApsPermitedScreen } from 'src/app/models/aps-permission/aps-permited-screen';
import { ConstantsFordProtect } from 'src/app/models/constants/constants-ford-protect';
import { NewProduct } from 'src/app/models/product/new-product';
import { ApsPermissionService } from 'src/app/services/aps-permission/aps-permission.service';
import { ProductService } from 'src/app/services/product/product.service';

@Component({
	selector: 'new-product',
	templateUrl: './new-product.component.html',
	styleUrls: ['./new-product.component.scss']
})
export class NewProductComponent implements OnInit {

	listCountries = [
		{ label: 'TRANSLATE.SELECT' },
		{ label: 'TRANSLATE.ARGENTINA', value: ConstantsFordProtect.ARG },
		{ label: 'TRANSLATE.BRAZIL', value: ConstantsFordProtect.BRA },
		{ label: 'TRANSLATE.CHILE', value: ConstantsFordProtect.CHL },
		{ label: 'TRANSLATE.COLOMBIA', value: ConstantsFordProtect.COL }
	];

	listStatus = [
		{ label: 'TRANSLATE.SELECT' },
		{ label: 'TRANSLATE.ACTIVE', value: ConstantsFordProtect.PRODUCT_ACTIVE },
		{ label: 'TRANSLATE.INACTIVE', value: ConstantsFordProtect.PRODUCT_INACTIVE }
	];

	userform: FormGroup;
	newProduct: NewProduct;
	selectedCountry: any;
	selectedStatus: any;
	actionCodeUppercase: string;
	accountCodeUppercase: string;
	coverageUppercase: string;
	insuranceCodeUppercase: string;
	productCodeUppercase: string;

	constructor(
		private fb: FormBuilder,
		private serviceProduct: ProductService,
		private messageService: MessageService,
		private apsPermissionService: ApsPermissionService,
		private translateService: TranslateService,
		private router: Router
	) {
		this.newProduct = new NewProduct();

	}

	ngOnInit() {

		this.apsPermissionService.permissionValidation(ApsPermitedScreen.screenViewsPlans);

		this.userform = this.fb.group({
			'optionCountry': [null, Validators.required],
			'optionStatus': [null, Validators.required],
			'inputProductName': ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50)]],
			'inputVisits': ['', [Validators.required, Validators.min(1), Validators.max(99), Validators.pattern('[0-9]*')]],
			'inputCode': ['', [Validators.required, Validators.minLength(1), Validators.maxLength(10)]],
			'inputDescriptionProduct': ['', [Validators.required, Validators.minLength(4), Validators.maxLength(300)]],
			'inputAccount': [null, [Validators.maxLength(9)]],
			'inputActionCode': [null, [Validators.maxLength(1)]],
			'inputCoverageCode': [null, [Validators.maxLength(4)]],
			'inputInsuranceCode': [null, [Validators.maxLength(4)]],
			'inputInvoiceMonths': [null, [Validators.max(999), Validators.pattern('[0-9]*')]],
			'inputProductDetails': [null]
		});

	}

	buttonReturn() {
		this.router.navigate(['view-plans'], { skipLocationChange: false, replaceUrl: false });
	}

	countryChange(event: any) {
		this.selectedCountry = event.value;
	}

	statusChange(event: any) {
		this.selectedStatus = event.value;
	}

	productCodeOnKeyUp(event: any) {
		this.productCodeUppercase = event.target.value.toUpperCase();
	}

	accountOnKeyUp(event: any) {
		this.accountCodeUppercase = event.target.value.toUpperCase();
	}

	actionCodeOnKeyUp(event: any) {
		this.actionCodeUppercase = event.target.value.toUpperCase();
	}

	coverageCodeOnKeyUp(event: any) {
		this.coverageUppercase = event.target.value.toUpperCase();
	}

	insuranceCodeOnKeyUp(event: any) {
		this.insuranceCodeUppercase = event.target.value.toUpperCase();
	}

	buttonSave() {

		if (this.selectedCountry === undefined || this.selectedCountry === null) {
			this.userform.get('optionCountry').markAsTouched();
			this.selectedCountry = null;
		}
		if (this.selectedStatus === undefined || this.selectedStatus === null) {
			this.userform.get('optionStatus').markAsTouched();
			this.selectedStatus = null;
		}
		if (this.userform.get('inputProductName').invalid) {
			this.userform.get('inputProductName').markAsPending();
		}
		if (this.userform.get('inputVisits').invalid) {
			this.userform.get('inputVisits').markAsPending();
		}
		if (this.userform.get('inputCode').invalid) {
			this.userform.get('inputCode').markAsPending();
		}
		if (this.userform.get('inputDescriptionProduct').invalid) {
			this.userform.get('inputDescriptionProduct').markAsPending();
		}
		else if (this.userform.status == "VALID") {
			this.newProduct.country = this.selectedCountry;
			this.newProduct.statusC = this.selectedStatus;
			this.newProduct.csId = this.apsPermissionService.getUserCsid();
			this.newProduct.productCode = this.productCodeUppercase;
			this.newProduct.dateStart = ConstantsFordProtect.DATE_START;
			this.newProduct.dateEnd = ConstantsFordProtect.DATE_END;
			this.newProduct.createDate = this.transformDateYYYYMMDD(new Date());

			if (this.actionCodeUppercase !== null || this.actionCodeUppercase !== undefined) {

				this.newProduct.actionCode = this.actionCodeUppercase;
			}
			if (this.accountCodeUppercase !== null || this.accountCodeUppercase !== undefined) {

				this.newProduct.account = this.accountCodeUppercase;
			}
			if (this.coverageUppercase !== null || this.coverageUppercase !== undefined) {

				this.newProduct.coverageCode = this.coverageUppercase;
			}
			if (this.insuranceCodeUppercase !== null || this.insuranceCodeUppercase !== undefined) {

				this.newProduct.insuranceCode = this.insuranceCodeUppercase;
			}

			this.serviceProduct.createProduct(this.newProduct)
				.subscribe((response: NewProduct) => {

					this.newProduct = response;

					this.messageService.add({
						severity: 'success',
						summary: this.translateService.instant('TRANSLATE.SUCCESS'),
						detail: this.translateService.instant('TRANSLATE.SUCCESS.CREATE')
					});
					this.buttonReturn();

				}, (err) => {

					if (err.status === 404) {

						this.messageService.add({
							severity: 'error',
							summary: this.translateService.instant('TRANSLATE.ERROR'),
							detail: this.translateService.instant('TRANSLATE.ERROR.CREATE.PRODUCT')
						});
					} else if (err.status === 401) {

						this.messageService.add({
							severity: 'error',
							summary: this.translateService.instant('TRANSLATE.ERROR'),
							detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
						});

					} else {

						this.messageService.add({
							severity: 'error',
							summary: this.translateService.instant('TRANSLATE.ERROR'),
							detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
						});
					}

				});

		} else {

			this.messageService.add({
				severity: 'error',
				summary: this.translateService.instant('TRANSLATE.ERROR'),
				detail: this.translateService.instant('TRANSLATE.ERROR.PLEASE.REQUIRED.FIELDS')
			});
		}

	}

	//TODO: Criar uma classe common para reutilizar esse metodo posteriormente
	transformDateYYYYMMDD(date: Date): string {
		let newDateStart = new Date(date);
		return newDateStart.getFullYear().toString() + "-" + ((("0") + ((newDateStart.getMonth() + 1))).toString().slice(-2)) + "-" + ((("0") + newDateStart.getDate()).toString().slice(-2));
	}

}
