import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { VinCategory } from "src/app/models/vin/vin-category";
import { VinSaveAndVector } from "src/app/models/vin/vin-save-and-vector";
import { environment } from "src/environments/environment";

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class VinService {

  private apiUrl = environment.apiUrl;
  private vinValidationList: Array<VinSaveAndVector> = [];

  constructor(private httpClient: HttpClient) {
  }

  getChassiValidation(country: String, chassi: String, lang: String): Observable<VinSaveAndVector> {
    return this.httpClient.get<VinSaveAndVector>(`${this.apiUrl}/api/vins/validate/${country}/${chassi}?lang=${lang}`)
      .pipe(
        map((response: VinSaveAndVector) => {
          return response;
        })
      );
  }

  getByCountryAndYear(country: String, modelYear: number): Observable<Array<VinCategory>> {
    return this.httpClient.get<Array<VinCategory>>(`${this.apiUrl}/api/v1/vins/search/${country}/${modelYear}`)
      .pipe(
        map((response: Array<VinCategory>) => {
          return response;
        })
      );
  }

  setAllVinValidationList(vinValidations: Array<VinSaveAndVector>) {
    this.vinValidationList = vinValidations;
  }

  getAllVinValidationList(): Array<VinSaveAndVector> {
    return this.vinValidationList;
  }

  getAllVinValidationListClean() {
    this.vinValidationList = [];
  }

}
