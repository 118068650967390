import { BillingAddressPagarMe } from "./billing-address-pagar-me";

export class CardPagarMe {
    holder_name: string;
    number: string;    
    exp_month: string;
    exp_year: string;    
    cvv: string;
    billing_address: BillingAddressPagarMe; 

	constructor() {
	
	}
}