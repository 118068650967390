import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaymentContractSummaryShort } from 'src/app/models/payment-method/payment-contract-summary-short';
import { PaymentMethodService } from 'src/app/services/payment-method/payment-method.service';

@Component({
  selector: 'app-payment-legal-text-contract-bra-bas1',
  templateUrl: './payment-legal-text-contract-bra-bas1.component.html',
  styleUrls: ['./payment-legal-text-contract-bra-bas1.component.scss']
})
export class PaymentLegalTextContractBraBas1Component implements OnInit {

  paymentContractSummaryShort: PaymentContractSummaryShort;

  contract: number;
  document: string;
  month: string;
  city: string;
  productCode: string;
  productName: string;
  customerPrice: number;
  uuid: string;

  constructor(
    private paymentMethodService: PaymentMethodService,
    private activatedRoute: ActivatedRoute) {
    this.paymentContractSummaryShort = new PaymentContractSummaryShort;
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.uuid = params.uuid;
      this.contractPaymentSummarySearch(this.uuid);
    });
  }

  contractPaymentSummarySearch(uuid: string) {
    this.paymentMethodService
      .getPaymentContractSummaryShort(uuid)
      .subscribe((response: PaymentContractSummaryShort) => {

        this.paymentContractSummaryShort = response;

        if (this.paymentContractSummaryShort.country === 'BRA') {
          this.city = 'São Paulo'          
        }

        this.productCode = this.paymentContractSummaryShort.productCode; 
        if (this.paymentContractSummaryShort.maintenance != null) {
          this.productName = this.paymentContractSummaryShort.maintenance.productName;
          this.customerPrice = this.paymentContractSummaryShort.maintenance.customerPrice;
        }

        const salesDate = new Date(this.paymentContractSummaryShort.salesDate);
        const mes = salesDate.getMonth() + 1;

        switch (mes) {

          case 1:
            this.month = 'janeiro';
            break;

          case 2:
            this.month = 'fevereiro';
            break;

          case 3:
            this.month = 'março';
            break;

          case 4:
            this.month = 'abril';
            break;

          case 5:
            this.month = 'maio';
            break;

          case 6:
            this.month = 'junho';
            break;

          case 7:
            this.month = 'julho';
            break;

          case 8:
            this.month = 'agosto';
            break;

          case 9:
            this.month = 'setembro';
            break;

          case 10:
            this.month = 'outubro';
            break;

          case 11:
            this.month = 'novembro';
            break;

          case 12:
            this.month = 'dezembro';
            break;
        }
      });

  }

}
