import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { ContractReactivation } from "src/app/models/contract/contract-reactivation";
import { PaymentBoletoRequestDto } from "src/app/models/payment-method/payment-boleto-request";
import { PaymentBoletoResponseDto } from "src/app/models/payment-method/payment-boleto-response";
import { PaymentContractSummary } from "src/app/models/payment-method/payment-contract-summary";
import { PaymentContractSummaryShort } from "src/app/models/payment-method/payment-contract-summary-short";
import { PaymentMethod } from "src/app/models/payment-method/payment-method";
import { PaymentMethodRequest } from "src/app/models/payment-method/payment-method-request";
import { GatewayListBoletoResponseDto } from "src/app/models/payment-method/sa-payments/response/gateway-list-boleto-response-dto";
import { ContractPaymentSignature } from "src/app/models/payment-signature/contract-payment-signature";
import { environment } from "src/environments/environment";

const httpOptions = {
	headers: new HttpHeaders({
        'Content-Type': 'application/json'
	})
};

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodService {

    private apiUrl = environment.apiUrl;
    private paymentContractSummary: PaymentContractSummary;
    private paymentContractSummaryShort: PaymentContractSummaryShort;    
    private contractPaymentSignature: ContractPaymentSignature;
   
    constructor(private httpClient: HttpClient, private messageService: MessageService) {
    }

    setPaymentContractSummary(paymentContractSummary: PaymentContractSummary) {
      this.paymentContractSummary = paymentContractSummary;
    }

    getContractSummary(): PaymentContractSummary {
      return this.paymentContractSummary;
    }    
    
    setPaymentContractSummaryShort(paymentContractSummaryShort: PaymentContractSummaryShort) {
      this.paymentContractSummaryShort = paymentContractSummaryShort;
    }
    
    getContractSummaryShort(): PaymentContractSummaryShort {
      return this.paymentContractSummaryShort;
    }        

    getPaymentContractSummary(uuid: string): Observable<PaymentContractSummary> {      
      return this.httpClient.get<PaymentContractSummary>(`${this.apiUrl}/api/payment/contract/summary/${uuid}`)
        .pipe(
          map((response: PaymentContractSummary) => {
            return response;
          })
        );
    }

    getPaymentContractSummaryShort(uuid: string): Observable<PaymentContractSummaryShort> {      
      return this.httpClient.get<PaymentContractSummaryShort>(`${this.apiUrl}/api/payment/contract/summary/short/${uuid}`)
        .pipe(
          map((response: PaymentContractSummaryShort) => {
            return response;
          })
        );
    }    

    postPaymentMethod(paymentMethod: PaymentMethod): Observable<PaymentMethodRequest>{
      return this.httpClient.post(`${this.apiUrl}/api/payment/method/credit/card`, paymentMethod, httpOptions)
      .pipe(
        map((response: PaymentMethodRequest) => {
          return response;
        })
      );
    }

    postPaymentMethodContractReactivation(contractReactivation: ContractReactivation): Observable<boolean>{
      return this.httpClient.post(`${this.apiUrl}/api/payment/contract/reactivate`, contractReactivation, httpOptions)
      .pipe(
        map((response: boolean) => {
          return response;
        })
      );
    }

    save(contractPaymentSignature: ContractPaymentSignature): Observable<ContractPaymentSignature> {
      return this.httpClient.post<ContractPaymentSignature>(`${this.apiUrl}/api/payment/contract/after-payment-signature/save`, contractPaymentSignature);
    }
    
    postPaymentBoleto(requestDto: PaymentBoletoRequestDto): Observable<PaymentBoletoResponseDto>{
      return this.httpClient.post(`${this.apiUrl}/api/payment/method/boleto`, requestDto, httpOptions)
      .pipe(
        map((response: PaymentBoletoResponseDto) => {
          return response;
        })
      );
    }

    findBoletoSaPaymentsByContractId(contractId: string): Observable<GatewayListBoletoResponseDto> {      
      return this.httpClient.get<GatewayListBoletoResponseDto>(`${this.apiUrl}/api/sapayments/boleto/${contractId}`)
        .pipe(
          map((response: GatewayListBoletoResponseDto) => {
            return response;
          })
        );
    }

    downloadBoleto(boletoId: string): Observable<Blob> {
      let headers = new HttpHeaders();
      headers = headers.set('Accept','application/pdf');      
      return this.httpClient.get(`${this.apiUrl}/api/sapayments/boleto/download?boletoId=${boletoId}`, {headers: headers, responseType: 'blob'});
    }

    setContractPaymentSignature(contractPaymentSignature: ContractPaymentSignature) {
      this.contractPaymentSignature = contractPaymentSignature;
    }

    getContractPaymentSignature(): ContractPaymentSignature {
      return this.contractPaymentSignature;
    }

}
