import { AddressPagarMe } from "./address-pagar-me";
import { PhonesPagarMe } from "./phones-pagar-me";

export class CustomerPagarMe {
	name: string;
	email: string;
	type: string;
	document: string;
	document_type: string;
	address: AddressPagarMe;
	phones: PhonesPagarMe;	

	constructor() {
	
	}
}