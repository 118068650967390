import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { PlanSelecionService } from 'src/app/services/plan-selection/plan-selection.service';

@Component({
	selector: 'app-customer',
	templateUrl: './customer.component.html'
})
export class CustomerComponent implements OnInit {

	constructor(private planSelecionService: PlanSelecionService,
		private translateService: TranslateService,
		private messageService: MessageService,		
		private router: Router) {
	}

	ngOnInit() {
	}

	buttonNextWindow() {		
		
	}

	buttonComeBack() {
		this.router.navigate(['plan-selection']);
	}

}
