import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContractSignature } from 'src/app/models/contract/contract-signature';
import { ContractService } from 'src/app/services/contract/contract.service';
import { PaymentMethodService } from 'src/app/services/payment-method/payment-method.service';

@Component({
  selector: 'app-legal-text-contract-bra-bas1',
  templateUrl: './legal-text-contract-bra-bas1.component.html',
  styleUrls: ['./legal-text-contract-bra-bas1.component.scss']
})
export class LegalTextContractBraBas1Component implements OnInit {
 
	contractSignature: ContractSignature;

	contract: number;
	document: string;
	month: string;
  city: string;
  uuid: string;

	constructor(
		private contractService: ContractService,
		private activatedRoute: ActivatedRoute
	) {
		 
		this.contractSignature = new ContractSignature;
	}

	ngOnInit() {
		this.activatedRoute.queryParams.subscribe(params => {

			this.uuid = params.uuid;
			this.contractSummarySearch(this.uuid);
		});
	}

	contractSummarySearch(uuid: string) {
		this.contractService
		.getContractSignature(uuid)
		.subscribe((response: ContractSignature) => {
	 
				this.contractSignature = response;

				if (this.contractSignature.country === 'BRA') {
          this.city = 'São Paulo'
        }

        const salesDate = new Date(this.contractSignature.salesDate);
        const mes = salesDate.getMonth() + 1;

        switch (mes) {

          case 1:
              this.month = 'janeiro';
            break;

          case 2:
              this.month = 'fevereiro';
            break;

          case 3:
              this.month = 'março';
            break;

          case 4:
              this.month = 'abril';
            break;

          case 5:
              this.month = 'maio';
            break;

          case 6:
              this.month = 'junho';
            break;

          case 7:
              this.month = 'julho';
            break;

          case 8:
              this.month = 'agosto';
            break;

          case 9:
              this.month = 'setembro';
            break;

          case 10:
              this.month = 'outubro';
            break;

          case 11:
              this.month = 'novembro';
            break;

          case 12:
              this.month = 'dezembro';
            break;
        }
      });
			
	}


}
