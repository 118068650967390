import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { ContractSummary } from "src/app/models/contract-summary/contract-summary";
import { Contract } from "src/app/models/contract/contract";
import { ContractFull } from "src/app/models/contract/contract-full";
import { ContractPendingPayment } from "src/app/models/contract/contract-pending-payment";
import { ContractPendingSignature } from "src/app/models/contract/contract-pending-signature";

const httpOptions = {
	headers: new HttpHeaders({
        'Content-Type': 'application/json'
	})
};

@Injectable({
  providedIn: 'root'
})
export class ContractSummaryService {

    private apiUrl = environment.apiUrl;
    private contractSummary: ContractSummary;

    constructor(private httpClient: HttpClient, private messageService: MessageService) {
    }

    setContractSummary(contractSummary: ContractSummary) {
      this.contractSummary = contractSummary;
    }

    getContractSummary(): ContractSummary {
      return this.contractSummary;
    }

    getContractSummaryClean() {
      this.contractSummary = new ContractSummary();
    }

    save(contractFull: ContractFull, lang: String): Observable<ContractFull> {
      return this.httpClient.post<ContractFull>(`${this.apiUrl}/api/v1/contracts/full?lang=${lang}`, contractFull);
    }   
    
    saveContractPendingPayment(contractPendingPayment: ContractPendingPayment): Observable<ContractPendingPayment> {
      return this.httpClient.post<ContractPendingPayment>(`${this.apiUrl}/api/contracts/pending/payment`, contractPendingPayment);
    }  
    
    saveContractPendingSignature(contractPendingSignature: ContractPendingSignature): Observable<ContractPendingSignature> {      
      return this.httpClient.post<ContractPendingSignature>(`${this.apiUrl}/api/v1/contracts/pendingSignature`, contractPendingSignature);
    }   
}
