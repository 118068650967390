import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ApsPermitedScreen } from 'src/app/models/aps-permission/aps-permited-screen';
import { ChassiValidation } from 'src/app/models/chassi-validation/chassi-validation';
import { ContractManual } from 'src/app/models/contract-manual/contract-manual';
import { SalesTypeFord } from 'src/app/models/contract/sales-type-ford';
import { CoverageCategory } from 'src/app/models/product/coverage-category';
import { ProductActive } from 'src/app/models/product/product-active';
import { ProductFull } from 'src/app/models/product/product-full';
import { VinSaveAndVector } from 'src/app/models/vin/vin-save-and-vector';
import { VinValidation } from 'src/app/models/vin/vin-validation';
import { ApsPermissionService } from 'src/app/services/aps-permission/aps-permission.service';
import { ChassiValidationService } from 'src/app/services/chassi-validation/chassi-validation.service';
import { ContractManualService } from 'src/app/services/contract-manual/contract-manual.service';
import { ContractService } from 'src/app/services/contract/contract.service';
import { CoverageService } from 'src/app/services/coverage/coverage.service';
import { ProductService } from 'src/app/services/product/product.service';

@Component({
	selector: 'app-contract-manual',
	templateUrl: './contract-manual.component.html',
	styleUrls: ['./contract-manual.component.scss']
})
export class ContractManualComponent implements OnInit {

	countries = [
		{ label: 'Argentina', value: "ARG" },
		{ label: 'Brasil', value: "BRA" },
		{ label: 'Chile', value: "CHL" },
		{ label: 'Colombia', value: "COL" }
	];

	contractGesb = [
		{ label: 'Não', value: false },
		{ label: 'Sim', value: true }
	];

	salesTypeFords: Array<SalesTypeFord> = [];
	salesTypeFord: SalesTypeFord;

	country: string = "BRA";
	isContractGesb: boolean = false;

	productActiveCountries: Array<ProductActive> = [];
	productActive: ProductActive;

	coverageCategorys: Array<CoverageCategory> = [];
	coverageCategory: CoverageCategory;

	vins: string = '';
	vinSaveAndVectors: Array<VinSaveAndVector> = [];
	contractsManual: Array<ContractManual> = [];

	errorsVins: Array<String> = [];
	hasErrors: boolean = false;
	contractErrors: boolean = false;
	description: string;
	blockedUi: boolean = false;

	constructor(private translateService: TranslateService,
		private messageService: MessageService,
		private apsPermissionService: ApsPermissionService,
		private contractService: ContractService,
		private productService: ProductService,
		private contractManualService: ContractManualService,
		private chassiValidationService: ChassiValidationService,
		private coverageService: CoverageService,
	) {
		this.coverageCategory = new CoverageCategory();
	}

	ngOnInit() {
		this.apsPermissionService.permissionValidation(ApsPermitedScreen.screenManualContract);
		this.searchSalesTypeFord();
		this.searchProductActiveCountry();
	}

	private searchSalesTypeFord() {
		this.contractService.searchSalesTypeFord()
			.subscribe((response: Array<SalesTypeFord>) => {
				this.salesTypeFords = response;

				if (this.salesTypeFords.length > 0) {
					this.salesTypeFord = this.salesTypeFords[0];
				}
			}
				, (err) => {
					this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Tipo de venda não encontrado.' });
				});
	}

	private searchProductActiveCountry() {
		this.productService.getActivesProductsAndNotRestrictedByCountry(this.country)
			.subscribe((response: Array<ProductActive>) => {
				this.productActiveCountries = response;

				if (this.productActiveCountries.length > 0) {
					this.productActive = this.productActiveCountries[0];
					this.searchCoverageCategoryAndProductId(this.productActive.id);
				} else {
					this.productActive = new ProductActive();
				}

			}, (err) => {
				this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Produtos não encontrado.' });
			});
	}

	formatDescription(description: string): string {

		return description.length > 50 ? description.substring(0, 50) + '...' : description;
	}

	private searchCoverageCategoryAndProductId(productId: number) {
		this.coverageService.getSearchCoverageCategoryAndProductId(productId)
			.subscribe((response: Array<CoverageCategory>) => {
				this.coverageCategorys = response;

				if (this.coverageCategorys.length > 0) {
					this.coverageCategory = this.coverageCategorys[0];
					this.description = this.coverageCategory.description.length > 50 ? this.coverageCategory.description.substring(0, 50) + '...' : this.coverageCategory.description;
					
				} else {
					this.coverageCategory = new CoverageCategory();
				}
			}
				, (err) => {
					this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Produtos não encontrados.' });
				});
	}

	countryChange(event: any) {
		this.searchProductActiveCountry();
	}

	productChange(event: any) {
		this.searchCoverageCategoryAndProductId(event.value.id);
	}

	buttonCleanVins() {
		this.vins = '';
		this.errorsVins= [];
		this.hasErrors = false;
	}

	buttonValidationVins() {
		this.buttonCleanContract();
		if (this.validationComboBox()) {
			this.blockedUi = true;
			let vinValidation: VinValidation = new VinValidation();
			vinValidation.country = this.country;
			vinValidation.vins = this.verificationBreakVin();
			this.searchValidationVinsSaveAndVector(vinValidation);
		}
	}

	buttonRemoveVin(vinSaveAndVector: VinSaveAndVector) {
		this.vinSaveAndVectors.forEach((element, index) => {
			if (element.vin === vinSaveAndVector.vin) this.vinSaveAndVectors.splice(index, 1);
		});
	}

	private searchValidationVinsSaveAndVector(vinValidation: VinValidation) {
		vinValidation.vins.forEach(chassi => {
			this.chassiValidationService.getChassiValidation(vinValidation.country, chassi, "pt-BR")
				.subscribe((response: ChassiValidation) => {
					let vinSaveAndVector: VinSaveAndVector = new VinSaveAndVector();
					vinSaveAndVector.country = vinValidation.country;
					vinSaveAndVector.modelYear = response.modelYear;
					vinSaveAndVector.operation = "CAR";
					vinSaveAndVector.status = "ATIVO";
					vinSaveAndVector.tma = response.tma;
					vinSaveAndVector.vin = response.vin;
					vinSaveAndVector.warrantyStartDate = response.warrantyStartDate;
					vinSaveAndVector.wersCode = response.vehicleLine;
					vinSaveAndVector.modelYear = response.modelYear;
					vinSaveAndVector.vehicleLine = response.vehicleLine;
					vinSaveAndVector.version = response.version;
					vinSaveAndVector.productId = this.productActive.id;
					vinSaveAndVector.productName = this.productActive.name;
					vinSaveAndVector.coverageId = this.coverageCategory.id;
					vinSaveAndVector.coverageName = this.coverageCategory.name;
					this.vinSaveAndVectors.push(vinSaveAndVector);
					this.blockedUi = false;
				}
					, (err) => {
						this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Validação de vins não encontrado.' });
						this.blockedUi = false;
					});
		});

	}

	private verificationBreakVin(): Array<string> {
		if (this.breakVinsSplitEnter().length > 0) {
			return this.breakVinsSplitEnter();
		}

		if (this.breakVinsSplitVirgulas().length > 0) {
			return this.breakVinsSplitVirgulas();
		}
	}

	private breakVinsSplitEnter(): Array<string> {
		return this.vins.split('\n');
	}

	private breakVinsSplitVirgulas(): Array<string> {
		return this.vins.split(',');
	}

	private isExistCaracterBreakSplit(): boolean {
		let resultVigula = this.vins.includes(',');
		if (resultVigula) {
			return true;
		} else {
			return false;
		}
	}

	private validationComboBox() {
		let validation: boolean = true;
		if (this.salesTypeFord !== null && this.salesTypeFord.id > 0) {
			if (this.productActive !== null && this.productActive.id > 0) {
				if (this.coverageCategory !== null && this.coverageCategory.id > 0) {
					if (this.vins == null || (this.vins.length != undefined && this.vins.length <= 0)) {
						validation = false;
						this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Campo vins inválido.' });

					} else if (this.isExistCaracterBreakSplit()) {
						validation = false;
						this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Campo vins com separador inválido.' });
					}
				} else {
					validation = false;
					this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Cobertura não selecionada.' });
				}
			} else {
				validation = false;
				this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Produtos não selecionado.' });
			}
		} else {
			validation = false;
			this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Tipo de venda não selecionado.' });
		}

		return validation;
	}

	buttonCleanValidationVins() {
		this.vinSaveAndVectors = [];
	}

	buttonValidationContractVins() {
		if (this.validationComboBox()) {
			for (let count = 0; count < this.vinSaveAndVectors.length; count++) {
				if (this.vinSaveAndVectors[count].status == null || this.vinSaveAndVectors[count].status.match('VIN.ERROR.SAVE_VECTOR') === null) {
					this.contractsManual.push(this.createContractManual(this.vinSaveAndVectors[count]));
					this.buttonRemoveVin(this.vinSaveAndVectors[count]);
					this.buttonValidationContractVins();
				}
			}
		}
	}

	private createContractManual(vinSaveAndVector: VinSaveAndVector): ContractManual {
		let contractManual: ContractManual = new ContractManual();
		contractManual.hasError = null;
		contractManual.country = vinSaveAndVector.country;
		contractManual.vin = vinSaveAndVector.vin;
		contractManual.cvvgDate = vinSaveAndVector.warrantyStartDate;
		contractManual.startDate = vinSaveAndVector.warrantyStartDate;
		contractManual.endDate = null;
		contractManual.saleType = this.salesTypeFord.id;
		contractManual.isGlobalContract = this.isContractGesb;
		contractManual.user = this.apsPermissionService.getUserCsid();
		contractManual.status = 5;
		contractManual.tmaCode = vinSaveAndVector.tma;
		contractManual.vehicleLineCode = vinSaveAndVector.vehicleLine;
		contractManual.modelYear = vinSaveAndVector.modelYear;

		let productFull: ProductFull = new ProductFull();
		productFull.productId = vinSaveAndVector.productId;
		productFull.productName = vinSaveAndVector.productName;
		productFull.coverageId = vinSaveAndVector.coverageId;
		productFull.coverageName = vinSaveAndVector.coverageName;
		productFull.priceBookId = 9999999999;
		productFull.productPolicyId = 9999999999;

		contractManual.product = productFull;
		return contractManual;
	}

	buttonCleanContract() {
		this.contractsManual = [];
	}

	buttonRemoveVinContract(contractManual: ContractManual) {
		this.contractsManual.forEach((element, index) => {
			if (element.vin === contractManual.vin) this.contractsManual.splice(index, 1);
		});
	}

	buttonCreateContract() {
		this.contractManualService.createContractManual(this.contractsManual)
			.subscribe((response: Array<ContractManual>) => {
				this.contractsManual = response;

				this.contractsManual.forEach(contractManual => {
					if (contractManual.hasError == true) {
						this.errorsVins.push(contractManual.vin);
						this.hasErrors = true;
						return;
					}
				});
				
				if (this.hasErrors === true) {
	
					this.messageService.add({ severity: 'warn', summary: 'Atenção', detail: 'Verifique o(s) status de cada chassi.' });
				} else {
					
					this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Contrato(s) criado(s) com sucesso.' });
				}

			}, (err) => {
					this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Ao criar contratos manuais.' });
			});
	}

}