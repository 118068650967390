import { CreditCardPagarMe } from "./credit-card-pagar-me";
import { DebitCardPagarMe } from "./debit-card-pagar-me";
import { PixPagarMe } from "./pix-pagar-me";

export class PaymentPagarMe {

	payment_method: string;
	credit_card: CreditCardPagarMe;
	debit_card: DebitCardPagarMe;
	pix: PixPagarMe;	

	constructor() {
	
	}
}