import {Component, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ApsPermitedScreen } from 'src/app/models/aps-permission/aps-permited-screen';
import { AdditionalInformationPagarMe } from 'src/app/models/pagar-me/additional-information-pagar-me';
import { AddressPagarMe } from 'src/app/models/pagar-me/address-pagar-me';
import { BillingAddressPagarMe } from 'src/app/models/pagar-me/billing-address-pagar-me';
import { CardPagarMe } from 'src/app/models/pagar-me/card-pagar-me';
import { CreditCardPagarMe } from 'src/app/models/pagar-me/credit-card-pagar-me';
import { CustomerPagarMe } from 'src/app/models/pagar-me/customer-pagar-me';
import { DebitCardPagarMe } from 'src/app/models/pagar-me/debit-card-pagar-me';
import { ItemPagarMe } from 'src/app/models/pagar-me/item-pagar-me';
import { MobilePhonePagarMe } from 'src/app/models/pagar-me/mobile-phone-pagar-me';
import { OrdersPagarMe } from 'src/app/models/pagar-me/orders-pagar-me';
//import { PaymentDebitPagarMe } from 'src/app/models/pagar-me/payment-debit-pagar-me';
import { PaymentPagarMe } from 'src/app/models/pagar-me/payment-pagar-me';
import { PhonesPagarMe } from 'src/app/models/pagar-me/phones-pagar-me';
import { PixPagarMe } from 'src/app/models/pagar-me/pix-pagar-me';
import { ChargePagarMeResponse } from 'src/app/models/pagar-me/response/charges-pagar-me-response';
import { LastTransactionPagarMeResponse} from 'src/app/models/pagar-me/response/last-transaction-pagar-me-response';
import { OrdersPagarMeResponsePix } from 'src/app/models/pagar-me/response/orders-pagar-me-response.pix';
import { ApsPermissionService } from 'src/app/services/aps-permission/aps-permission.service';
import { OrdersPagarMeService } from 'src/app/services/orders-pagar-me/orders-pagar-me.service';

@Component({
	selector: 'app-import-contract',
	templateUrl: './import-contract.component.html'
})
export class ImportContractComponent implements OnInit {

	uploadedFiles: any[] = [];
	showProgressBar: boolean = false;
	userform: FormGroup;
	cardPagarMeCredit: CardPagarMe;
	cardPagarMeDebit: CardPagarMe;	
	itemPagarMe: ItemPagarMe;
	ordersPagarMe: OrdersPagarMe;
	cards: CardPagarMe[] = [];
	dataCard: string[] = [];
	CREDIT_CARD: string = "credit_card";
	DEBIT_CARD: string = "debit_card";
	PIX: string = "pix";
	ordersPagarMeResponsePix: OrdersPagarMeResponsePix;

	constructor(private messageService: MessageService, private apsPermissionService: ApsPermissionService, private ordersPagarMeService: OrdersPagarMeService, private fb: FormBuilder) {
		this.cardPagarMeCredit = new CardPagarMe();
		this.cardPagarMeDebit = new CardPagarMe();		
		this.itemPagarMe = new ItemPagarMe();	
		this.ordersPagarMeResponsePix = new OrdersPagarMeResponsePix();
	}

	ngOnInit() {
		this.apsPermissionService.permissionValidation(ApsPermitedScreen.screenContractImport);

		this.userform = this.fb.group({
			'number': new FormControl('', ),
			'holderName': new FormControl('', ),
			'expiration': new FormControl('', ),
			'cvv': new FormControl('', ),
			'numberDebit': new FormControl('', ),
			'holderNameDebit': new FormControl('', ),
			'expirationDebit': new FormControl('', ),
			'cvvDebit': new FormControl('', ),
			'pixAmount': new FormControl('', )
		});

		this.ordersPagarMe = new OrdersPagarMe();
		this.ordersPagarMeResponsePix = new OrdersPagarMeResponsePix();
	}

	onUpload(event) {
	    for(let file of event.files) {
	        this.uploadedFiles.push(file);
	    }

	    this.messageService.add({severity:'success', summary: 'Success', detail: 'Message Content'});
	}

	bt(){
		this.showProgressBar = true;
		this.messageService.add({severity:'success', summary: 'Success', detail: 'Message Content'});
		for (let i = 0; i < 10000; i++) {
			
		}
		this.showProgressBar = false;
	}

	validateCard(): void {
		this.ordersPagarMe = this.setOrdersPagarMe(this.CREDIT_CARD);
		this.ordersPagarMeService.save(this.ordersPagarMe).subscribe((response: OrdersPagarMe) => {
			this.messageService.add({severity:'success', summary:'Sucesso', detail:'Pagamento autorizado com sucesso.'})
		}, (err) => {
			this.messageService.add({severity:'error', summary:'Erro', detail:'Pagamento não autorizado.'})
		});			
	} 
	
	validateDebitCard(): void {
		this.ordersPagarMe = this.setOrdersPagarMe(this.DEBIT_CARD);
		this.ordersPagarMeService.saveDebit(this.ordersPagarMe).subscribe((response: OrdersPagarMe) => {
			this.messageService.add({severity:'success', summary:'Sucesso', detail:'Pagamento autorizado com sucesso.'})
		}, (err) => {
			this.messageService.add({severity:'error', summary:'Erro', detail:'Pagamento não autorizado.'})
		});			
	}
	
	validatePix(): OrdersPagarMeResponsePix {
		let chargePagarMeResponse = new ChargePagarMeResponse();				
		let ordersPagarMeResponsePix = this.ordersPagarMeResponsePix;

		this.ordersPagarMe = this.setOrdersPagarMe(this.PIX);
		this.ordersPagarMeService.savePix(this.ordersPagarMe).subscribe((response: OrdersPagarMeResponsePix) => {
			response.charges.forEach(function (value) {
				chargePagarMeResponse = (value as ChargePagarMeResponse);
				ordersPagarMeResponsePix.charges.push(chargePagarMeResponse);

			})			
			this.messageService.add({severity:'success', summary:'Sucesso', detail:'Aguardando Pagamento.'})
			
		}, (err) => {
			this.messageService.add({severity:'error', summary:'Erro', detail:'Pagamento não autorizado.'})
		});
		return ordersPagarMeResponsePix;
	} 	

	setOrdersPagarMe(type): OrdersPagarMe {
		let ordersPagarMe = new OrdersPagarMe();
		
		let itemPagarMe = new ItemPagarMe();
		itemPagarMe.amount = 2990.00;
		if (type == this.PIX ) {
			itemPagarMe.amount = this.itemPagarMe.amount;
		}
		itemPagarMe.description = "TERMO DE CONTRATAÇÃO - GARANTIA ESTENDIDA REDUZIDA";
		itemPagarMe.quantity = "1";
		itemPagarMe.code = 123;

		ordersPagarMe.items.push(itemPagarMe);

		let customerPagarMe = new CustomerPagarMe();
		customerPagarMe.name = "Tony Stark";
		customerPagarMe.email = "tony@stark.com";
		customerPagarMe.document = "03154435026"
		customerPagarMe.document_type = "CPF";
		customerPagarMe.type = "INDIVIDUAL";

		let addressPagarMe = new AddressPagarMe();
		addressPagarMe.line_1 = "QNB 2";
		addressPagarMe.zip_code = "72115020";		
		addressPagarMe.city = "Brasilia";
		addressPagarMe.state = "DF";
		addressPagarMe.country = "BR";

		customerPagarMe.address = addressPagarMe;

		let mobilePhonePagarMe = new MobilePhonePagarMe();
		mobilePhonePagarMe.country_code = "55";
		mobilePhonePagarMe.area_code = "61"; 
		mobilePhonePagarMe.number =  "61988776655"; 

		let phonesPagarMe = new PhonesPagarMe()
		phonesPagarMe.mobile_phone = mobilePhonePagarMe;
		customerPagarMe.phones = phonesPagarMe;
		ordersPagarMe.customer = customerPagarMe;
		
		if (type == this.CREDIT_CARD ) {
			this.cardPagarMeCredit.exp_month = this.setExpirationCard("M");
			this.cardPagarMeCredit.exp_year = this.setExpirationCard("Y");		
		}

		if (type == this.DEBIT_CARD ) {
			this.cardPagarMeDebit.exp_month = this.setExpirationDebitCard("M");
			this.cardPagarMeDebit.exp_year = this.setExpirationDebitCard("Y");		
		}

		let billingAdressPagarMe = new BillingAddressPagarMe();
		billingAdressPagarMe.line_1 = "QNB 2";
		billingAdressPagarMe.zip_code = "72115020";
		billingAdressPagarMe.city = "Brasilia"
		billingAdressPagarMe.state = "DF";
		billingAdressPagarMe.country = "BR";

		this.cardPagarMeCredit.billing_address = billingAdressPagarMe;

		if (type == this.CREDIT_CARD ) {
			let creditCardPagarMe = new CreditCardPagarMe();
			creditCardPagarMe.installments = 1;
			creditCardPagarMe.recurrence = false;
			creditCardPagarMe.statement_descriptor = "FORD PROTECT";
			creditCardPagarMe.card = this.cardPagarMeCredit;		

			let paymentPagarMe = new PaymentPagarMe();
			paymentPagarMe.payment_method = type;
			paymentPagarMe.credit_card = creditCardPagarMe;
			
			ordersPagarMe.payments.push(paymentPagarMe);
		}

		if (type == this.DEBIT_CARD ) {
			let debitCardPagarMe = new DebitCardPagarMe();
			debitCardPagarMe.installments = 1;
			debitCardPagarMe.recurrence = false;
			debitCardPagarMe.statement_descriptor = "FORD PROTECT";
			debitCardPagarMe.card = this.cardPagarMeDebit;		

			let paymentPagarMe = new PaymentPagarMe();
			paymentPagarMe.payment_method = type;
			paymentPagarMe.debit_card = debitCardPagarMe;
			
			ordersPagarMe.payments.push(paymentPagarMe);
		}

		if (type == this.PIX ) {
			let additionalInformation = new AdditionalInformationPagarMe();
			additionalInformation.name = "Quantidade";
			additionalInformation.value = "2";

			let pixPagarMe = new PixPagarMe();
			pixPagarMe.expires_in = "52134613";
			pixPagarMe.additional_information.push(additionalInformation);

			let paymentPagarMe = new PaymentPagarMe();
			paymentPagarMe.payment_method = type;
			paymentPagarMe.pix = pixPagarMe;
			
			ordersPagarMe.payments.push(paymentPagarMe);
		}		

		return ordersPagarMe;
	}		

	setExpirationCard(param): string {
		this.dataCard = null;
		if (this.cardPagarMeCredit.exp_year != null) {
			this.dataCard = this.cardPagarMeCredit.exp_year.split("/", 2);
		}
		if (param == "M") {		
			return this.dataCard[0];
		}
		if (param == "Y") {
			return this.dataCard[1];
		}
	}

	setExpirationDebitCard(param): string {
		this.dataCard = null;
		if (this.cardPagarMeDebit.exp_year != null) {
			this.dataCard = this.cardPagarMeDebit.exp_year.split("/", 2);
		}
		if (param == "M") {		
			return this.dataCard[0];
		}
		if (param == "Y") {
			return this.dataCard[1];
		}
	}	
}
