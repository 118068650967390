import { ChoosePlanProduct } from "./choose-plan-product";

export class ChoosePlanBuyng {
	
	extension: ChoosePlanProduct = new ChoosePlanProduct;
	maintenance: ChoosePlanProduct = new ChoosePlanProduct;
	part: ChoosePlanProduct = new ChoosePlanProduct;
	factoryTimeLimit: number;
	factoryDistanceLimit: number;
	totalPrice: number = 0;
	
	constructor() {
		
	}
}
