import { CreateBoletoResponseDto } from "./sa-payments/response/create-boleto-response-dto";

export class PaymentBoletoRequestDto {

	contractId: number;
	documentId: string;
	dueDate: string;
	uuid: string;

	constructor() {
	}
}