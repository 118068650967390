import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng';
import { ApsPermitedScreen } from 'src/app/models/aps-permission/aps-permited-screen';
import { ConstantsFordProtect } from 'src/app/models/constants/constants-ford-protect';
import { CoverageCategory } from 'src/app/models/product/coverage-category';
import { NewCoverageCategory } from 'src/app/models/product/new-coverage-category';
import { ProductActive } from 'src/app/models/product/product-active';
import { ApsPermissionService } from 'src/app/services/aps-permission/aps-permission.service';
import { CoverageService } from 'src/app/services/coverage/coverage.service';
import { ProductService } from 'src/app/services/product/product.service';

@Component({
  selector: 'new-coverage',
  templateUrl: './new-coverage.component.html',
  styleUrls: ['./new-coverage.component.scss']
})
export class NewCoverageComponent implements OnInit {

  listCountries = [
    { label: 'TRANSLATE.SELECT' },
    { label: 'TRANSLATE.ARGENTINA', value: ConstantsFordProtect.ARG },
    { label: 'TRANSLATE.BRAZIL', value: ConstantsFordProtect.BRA },
    { label: 'TRANSLATE.CHILE', value: ConstantsFordProtect.CHL },
    { label: 'TRANSLATE.COLOMBIA', value: ConstantsFordProtect.COL }
  ];
  
  listTypeCoverage = [
    { label: 'TRANSLATE.SELECT' },
    { label: 'TRANSLATE.UPPERCASE.MAINTENANCE', value: ConstantsFordProtect.MAINTENANCE },
    { label: 'TRANSLATE.UPPERCASE.EXTENSION', value: ConstantsFordProtect.EXTENSION },
    { label: 'TRANSLATE.UPPERCASE.REPAIRS', value: ConstantsFordProtect.REPAIRS },
    { label: 'TRANSLATE.UPPERCASE.SIGNALS', value: ConstantsFordProtect.SIGNALS },
    { label: 'TRANSLATE.UPPERCASE.WINCHES', value: ConstantsFordProtect.WINCHES },
    { label: 'TRANSLATE.UPPERCASE.WEAR', value: ConstantsFordProtect.WEAR }    
  ];
  
  
  userform: FormGroup;
  coverageCategory: CoverageCategory;
  newCoverageCategory: NewCoverageCategory;  
  selectedCountry: string;
  selectedProductName: any;
  selectedCoverageType: any = ConstantsFordProtect.MAINTENANCE;

  productActiveCountries: Array<ProductActive> = [];
  productActive: ProductActive;
  productPlanYear: string;
  productAccount: string;
  productActionCode: string;
  productCoverageCode: string;
  productInsuranceCode: string;
  placeholderProduct: string = 'TRANSLATE.SELECT';

  coverageCategorys: Array<CoverageCategory> = [];  

  constructor(
    private fb: FormBuilder,
    private serviceProduct: ProductService,
    private messageService: MessageService,
    private apsPermissionService: ApsPermissionService,
    private translateService: TranslateService,
    private router: Router,
    private coverageService: CoverageService
  ) {
    this.coverageCategory = new CoverageCategory();
    this.newCoverageCategory = new NewCoverageCategory();
  }

  ngOnInit() {

    this.apsPermissionService.permissionValidation(ApsPermitedScreen.screenViewsPlans);

    this.userform = this.fb.group({
      'optionCountry': [null, Validators.required],
			'optionProductName': [null, Validators.required],
			'optionCoverageType': [null, Validators.required],
			'inputCoverageName': ['', [Validators.required, Validators.minLength(4), Validators.maxLength(20)]],
      'inputCoverageDescription': ['', [Validators.required, Validators.minLength(4), Validators.maxLength(100)]],
      'inputOwsCoverage': ['', [Validators.required, Validators.minLength(4), Validators.maxLength(8)]],
      'inputMonths': ['', [Validators.required, Validators.min(1), Validators.max(99), Validators.maxLength(2), Validators.pattern('[0-9]*')]],
      'inputDistance': ['', [Validators.required, Validators.min(1), Validators.max(999999), Validators.maxLength(7), Validators.pattern('[0-9]*')]],
			'inputTotalVisits': ['', [Validators.required, Validators.min(1), Validators.max(99),  Validators.maxLength(2), Validators.pattern('[0-9]*')]],
			'inputInitialVisit': ['', [Validators.required, Validators.min(1), Validators.max(99),  Validators.maxLength(2), Validators.pattern('[0-9]*')]],
      'inputAccount': [null, [Validators.maxLength(9)]],
			'inputActionCode': [null, [Validators.maxLength(1)]],
			'inputCoverageCode': [null, [Validators.maxLength(4)]],
			'inputInsuranceCode': [null, [Validators.maxLength(4)]]
    });

  }

  buttonReturn() {
    this.router.navigate(['view-plans'], { skipLocationChange: false, replaceUrl: false });
  }
 
  countryChange(event: any) {
    this.selectedCountry = event.value;

		this.searchProductActiveCountry(this.selectedCountry);
	}  

  private searchProductActiveCountry(country: string) {

		this.serviceProduct.getActivesProductsByCountry(country)
			.subscribe((response: Array<ProductActive>) => {
				this.productActiveCountries = response;

        if (this.productActiveCountries.length > 0) {
          this.placeholderProduct = 'TRANSLATE.SELECT';
        } else {
          this.placeholderProduct = 'TRANSLATE.NO.RESULT.FOUND';
        }

				if (this.productActiveCountries.length > 0) {
					this.productActive = this.productActiveCountries[0];
					this.searchCoverageCategoryAndProductId(this.productActive.id);

				} else {
					this.productActive = new ProductActive();
				}

			}, (err) => {
				this.messageService.add({ severity: 'error',
        summary: this.translateService.instant('TRANSLATE.ERROR'),
        detail: this.translateService.instant('TRANSLATE.PRODUCTS.NOT.FOUND') 
      });
		});
	}

  productChange(event: any) {    
		this.searchCoverageCategoryAndProductId(event.value.id);
	}

  private searchCoverageCategoryAndProductId(productId: number) {
		this.coverageService.getSearchCoverageCategoryAndProductId(productId)
			.subscribe((response: Array<CoverageCategory>) => {
				this.coverageCategorys = response;
        this.selectedProductName = productId;        

				if (this.coverageCategorys.length > 0) {
          this.coverageCategory = this.coverageCategorys[0];
          this.productPlanYear = this.coverageCategory.planYear;
          this.productAccount = this.coverageCategory.account;
          this.productActionCode =  this.coverageCategory.actionCode;       
          this.productCoverageCode = this.coverageCategory.coverageCode;
          this.productInsuranceCode = this.coverageCategory.insuranceCode;
				} else {
          this.coverageCategory = new CoverageCategory();
				}
			}
				, (err) => {
					this.messageService.add({ 
            severity: 'error', 
            summary: this.translateService.instant('TRANSLATE.ERROR'),
						detail: this.translateService.instant('TRANSLATE.PRODUCTS.NOT.FOUND')
          });
				});
	}

  optionCoverageTypeChange(event: any) {
		this.selectedCoverageType = event.value;
	}

  buttonSave() {

    if (this.selectedCountry === undefined || this.selectedProductName === null) {
			this.userform.get('optionCountry').markAsTouched();
			this.selectedCountry = null;
		}
    if (this.selectedProductName === undefined || this.selectedProductName === null) {
			this.userform.get('optionProductName').markAsTouched();
			this.selectedProductName = null;
		}
    if (this.selectedCoverageType === undefined || this.selectedCoverageType === null) {
			this.userform.get('optionCoverageType').markAsTouched();
			this.selectedCoverageType = null;
		}
    if (this.userform.get('inputCoverageName').invalid) {
			this.userform.get('inputCoverageName').markAsPending();
		}
    if (this.userform.get('inputCoverageDescription').invalid) {
			this.userform.get('inputCoverageDescription').markAsPending();
		}
    if (this.userform.get('inputOwsCoverage').invalid) {
			this.userform.get('inputOwsCoverage').markAsPending();
		}
    if (this.userform.get('inputMonths').invalid) {
			this.userform.get('inputMonths').markAsPending();
		}
    if (this.userform.get('inputDistance').invalid) {
			this.userform.get('inputDistance').markAsPending();
		}
    if (this.userform.get('inputTotalVisits').invalid) {
			this.userform.get('inputTotalVisits').markAsPending();
		}
    if (this.userform.get('inputInitialVisit').invalid) {
			this.userform.get('inputInitialVisit').markAsPending();
		}
    if (this.userform.get('inputDistance').invalid) {
			this.userform.get('inputDistance').markAsPending();
		}
    if (this.userform.get('inputTotalVisits').invalid) {
			this.userform.get('inputTotalVisits').markAsPending();
		}
    if (this.userform.valid) {

      this.newCoverageCategory.productId = this.productActive.id;
      this.newCoverageCategory.type = this.selectedCoverageType;
      this.newCoverageCategory.distanceUnit = ConstantsFordProtect.DISTANCE_UNIT;
      this.newCoverageCategory.termUnit = ConstantsFordProtect.TERM_UNIT;
      this.newCoverageCategory.createUser = this.apsPermissionService.getUserCsid();
      this.newCoverageCategory.owsCoverage = this.newCoverageCategory.owsCoverage.toUpperCase();
  
      this.coverageService.createCoverage(this.newCoverageCategory)
      .subscribe((response: NewCoverageCategory) => {
        
        this.newCoverageCategory = response;
      
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('TRANSLATE.SUCCESS'),
          detail: this.translateService.instant('TRANSLATE.SUCCESS.CREATE')
        });
        this.buttonReturn();
  
      }, (err) => {
  
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('TRANSLATE.ERROR'),
          detail: this.translateService.instant('TRANSLATE.ERROR.CREATE.COVERAGE')
        });
      });

    } else {

      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('TRANSLATE.ERROR'),
        detail: this.translateService.instant('TRANSLATE.ERROR.PLEASE.REQUIRED.FIELDS')
      });
    }

  } 

}