import { PaymentContractSummaryProduct } from "./payment-contract-summary-product";

export class PaymentContractSummaryShort {
    
    contractId: number;
	vin: string;
	salesDate: Date;
	startDate: Date;
	endDate: Date;
	term: number;
	distance: number;
	visit: number;
	statusPayment: number;
	statusContract: number;	
	due: Date;
	total: number;
	modelYear: number;
	cvvg: Date;
	odometerStart: number;
	vehicleLine: string;
	ibgeNumber: string;
	extension: PaymentContractSummaryProduct;
	maintenance: PaymentContractSummaryProduct;
	part: PaymentContractSummaryProduct;
	factoryDistanceLimit: number = 0;
	factoryTimeLimit: number = 0;
	createContractDate: Date;
	daysExpireContract: number;
	extendedWarranty: number;
	productCode: string;
	country: string;

	constructor() {
	}
}