import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng';
import { ApsPermitedScreen } from 'src/app/models/aps-permission/aps-permited-screen';
import { ChassiValidation } from 'src/app/models/chassi-validation/chassi-validation';
import { SaleChannel } from 'src/app/models/enum/sale-channel';
import { StatusChassi } from 'src/app/models/enum/status-chassi';
import { ApsPermissionService } from 'src/app/services/aps-permission/aps-permission.service';
import { SystemCountryService } from 'src/app/services/system-country/system-country.service';
import { VehicleInformationService } from 'src/app/services/vehicle-information/vehicle-information.service';

@Component({
	selector: 'app-vehicle-information',
	templateUrl: './vehicle-information.component.html',
	styleUrls: ['./vehicle-information.component.scss']
})
export class VehicleInformationComponent implements OnInit {

	chassiValidation: ChassiValidation;
	validationKm: String;
	enabledOdometro: boolean;
	dayWarrantyStartDate: string;
	saleChannel: string;
	dateAttachment: string;

	constructor(
		private vehicleInformationService: VehicleInformationService,
		private router: Router,
		private route: ActivatedRoute,
		private translateService: TranslateService,
		private messageService: MessageService,
		private apsPermissionService: ApsPermissionService,
		private systemCountryService: SystemCountryService
	) {
		this.saleChannel = route.snapshot.paramMap.get('saleChannel');
		this.systemCountryService.setSystemCountry();
	}

	ngOnInit() {
		this.chassiValidation = this.vehicleInformationService.getAllChassiValidation();

		if (this.chassiValidation == null || this.chassiValidation.validated == StatusChassi.invalid) {
			if (this.saleChannel == SaleChannel.EXTERNAL) {
				this.router.navigate(['sales-channel-external'], { skipLocationChange: true, replaceUrl: false });
			} else {
				this.router.navigate(['sales-channel'], { skipLocationChange: true, replaceUrl: false });
			}
		}

		this.apsPermissionService.permissionValidation(ApsPermitedScreen.screenVinValidation)
		this.isValidationKm();
		this.getDayWarrantyStartDate();
	}

	isValidationKm() {
		if (this.chassiValidation != null && this.chassiValidation.warrantyStartDate == null) {

			// renderiza os itens de menu, na primeira vez que acessar a tela
			this.validationKm = this.translateService.instant('TRANSLATE.YES');
			// renderiza ao fazer a troca de linguagem
			this.translateService.onLangChange.subscribe(
				async (translate: LangChangeEvent) => (

					this.validationKm = this.translateService.instant('TRANSLATE.YES')
				));

			this.enabledOdometro = true;

		} else {

			// renderiza os itens de menu, na primeira vez que acessar a tela
			this.validationKm = this.translateService.instant('TRANSLATE.NOT');
			// renderiza ao fazer a troca de linguagem
			this.translateService.onLangChange.subscribe(
				async (translate: LangChangeEvent) => (

					this.validationKm = this.translateService.instant('TRANSLATE.NOT')
				));

			this.enabledOdometro = false;
		}
	}

	buttonComeBack() {
		if (this.saleChannel == SaleChannel.EXTERNAL) {
			this.router.navigate(['sales-channel-external'], { skipLocationChange: true, replaceUrl: false });
		} else {
			this.router.navigate(['sales-channel'], { skipLocationChange: true, replaceUrl: false });
		}
	}

	next() {
		if (this.enabledOdometro == true) {
			this.router.navigate(['choose-plan', this.saleChannel], { skipLocationChange: true, replaceUrl: false });
		} else if (this.enabledOdometro == false && this.chassiValidation.odometro > 0) {
			this.router.navigate(['choose-plan', this.saleChannel], { skipLocationChange: true, replaceUrl: false });
		} else {
			this.messageService.add({ severity: 'error', summary: this.translateService.instant('TRANSLATE.ERROR'), detail: this.translateService.instant('TRANSLATE.ERROR.USED.VEHICLE') });
		}
	}

	/*
		Método para transformar a data:
		1 - Adiciona um zero a esquerda, caso o mes seja de 1 a 9;
		2 - Retorna o getUTCDate(), para garantir que o dia é o mesmo
		que vem do backend;
		3 - Assincrono pois dependendo do momento ele ainda não tem a data;
	*/
	async getDayWarrantyStartDate() {
		this.dayWarrantyStartDate = "";

		if (this.chassiValidation.warrantyStartDate != null) {
			this.dayWarrantyStartDate = this.transformDate(new Date(this.chassiValidation.warrantyStartDate));
		}
	}

	transformDate(date: Date): string {
		let newDateStart = new Date(date);
		return ((("0") + newDateStart.getDate()).toString().slice(-2)) + ((("0") + ((newDateStart.getMonth() + 1))).toString().slice(-2)) + newDateStart.getFullYear().toString();
	}

	newDate() {

		let dateStart = new Date(this.chassiValidation.warrantyStartDate + 'T00:00:00');
		var numberDate = String(dateStart.getUTCDate()).padStart(2, '0');
		var numberMonth = dateStart.getUTCMonth() + 1;
		var numberYear = dateStart.getUTCFullYear();
		let nameMonth = '';

		switch (numberMonth) {

			case 1:
				nameMonth = this.translateService.instant('TRANSLATE.MONTH.JANUARY');
				break;

			case 2:
				nameMonth = this.translateService.instant('TRANSLATE.MONTH.FEBRUARY');
				break;

			case 3:
				nameMonth = this.translateService.instant('TRANSLATE.MONTH.MARCH');
				break;

			case 4:
				nameMonth = this.translateService.instant('TRANSLATE.MONTH.APRIL');
				break;

			case 5:
				nameMonth = this.translateService.instant('TRANSLATE.MONTH.MAY');
				break;

			case 6:
				nameMonth = this.translateService.instant('TRANSLATE.MONTH.JUNE');
				break;

			case 7:
				nameMonth = this.translateService.instant('TRANSLATE.MONTH.JULY');
				break;

			case 8:
				nameMonth = this.translateService.instant('TRANSLATE.MONTH.AUGUST');
				break;

			case 9:
				nameMonth = this.translateService.instant('TRANSLATE.MONTH.SEPTEMBER');
				break;

			case 10:
				nameMonth = this.translateService.instant('TRANSLATE.MONTH.OCTOBER');
				break;

			case 11:
				nameMonth = this.translateService.instant('TRANSLATE.MONTH.NOVEMBER');
				break;

			case 12:
				nameMonth = this.translateService.instant('TRANSLATE.MONTH.DECEMBER');
				break;

			default:
				return this.dateAttachment = '';

		}
	
		return this.dateAttachment = numberDate + "/" + nameMonth + "/" + numberYear;

	}

}