import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Address } from "src/app/models/address/address";
import { MessageService } from "primeng/api";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { Product } from "src/app/models/product/product";
import { environment } from "src/environments/environment";

const httpOptions = {
	headers: new HttpHeaders({
        'Content-Type': 'application/json'
	})
};

@Injectable({
  providedIn: 'root'
})
export class AddressService {

    private apiUrl = environment.apiUrl;
        
    constructor(private httpClient: HttpClient, private messageService: MessageService) {
    }

    getAddress(zipCode: string): Observable<Address> {
		return this.httpClient.get<Address>(`${this.apiUrl}/api/address/zip-codes/${zipCode}`)
			.pipe(
				map((response: Address) => {
                    return response;
                })
			);
	}
}