export class ContractReactivation {
	
	contractId: number;
	documentClient: string;
	emailClient: string;
	nameClient: string;
	uuid: string;

	constructor() {
	}
}