import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { ApsPermitedScreen } from 'src/app/models/aps-permission/aps-permited-screen';
import { ApsPermissionService } from 'src/app/services/aps-permission/aps-permission.service';

/** Angular component that utilizes the PrimeNG p-menubar component
 * to display the navigation menu for the application
 */
@Component({
	selector: 'app-nav',
	templateUrl: './nav.component.html'
})
export class NavComponent implements OnInit {
	
	navMenuItems: MenuItem[];
	sidebarVisible: boolean;
	showSidebar = () => { this.sidebarVisible = true; };
	hideSidebar = () => { this.sidebarVisible = false; };	
	allPermissions: String[] = [];
	isScreenTest: boolean = false;
	isScreenSales: boolean = false;
	isScreenContractReview: boolean = false;
	isScreenContractExport: boolean = false;
	isScreenContractEdit: boolean = false;
	isScreenManualContract: boolean = false;
	isScreenSignature: boolean = false;
	isScreenPlansPolicy: boolean = false;
	isScreenContractView: boolean = false;
	isScreenContractSearch: boolean = false;
	isScreenContractSearchDealer: boolean = false;
	isScreenContractViewDealer: boolean = false;
	isScreenVehicleModel: boolean = false;
	isScreenSalesTelemarketing: boolean = false;
	isScreenSalesFord: boolean = false;
	isScreenHome: boolean = false;
	isScreenCustomerValidation: boolean = false;
	isScreenVinValidation: boolean = false;
	isScreenContractImport: boolean = false;	
	isScreenViewsPlans: boolean = false;	

	constructor(private apsPermissionService: ApsPermissionService, private translateService: TranslateService) {}

	ngOnInit() {
		setTimeout(()=> {
			this.getMenu();
		}, 500);
	}

	async getListAllPermissions(): Promise<String[]> {
		let allPermissions : String[] = [];
		await this.apsPermissionService.getListPermissions().then((list: String[]) => {
			allPermissions = list;
		});
		return allPermissions
	}
	
	async loadScreenPermissions() {
		await this.checkPermission(ApsPermitedScreen.screenSales).then((success: boolean) => {			
			if (success == true) {				
				this.isScreenSales = true;
			}
		});
		await this.checkPermission(ApsPermitedScreen.screenSalesTelemarketing).then((success: boolean) => {			
			if (success == true) {				
				this.isScreenSalesTelemarketing = true;
			}
		});
		await this.checkPermission(ApsPermitedScreen.screenContractSearch).then((success: boolean) => {			
			if (success == true) {				
				this.isScreenContractSearch = true;
			}
		});		
		await this.checkPermission(ApsPermitedScreen.screenContractSearchDealer).then((success: boolean) => {			
			if (success == true) {				
				this.isScreenContractSearchDealer = true;
			}
		});		
		await this.checkPermission(ApsPermitedScreen.screenManualContract).then((success: boolean) => {			
			if (success == true) {				
				this.isScreenManualContract = true;
			}
		});		
		await this.checkPermission(ApsPermitedScreen.screenContractImport).then((success: boolean) => {			
			if (success == true) {				
				this.isScreenContractImport = true;
			}
		});	
		await this.checkPermission(ApsPermitedScreen.screenViewsPlans).then((success: boolean) => {			
			if (success == true) {				
				this.isScreenViewsPlans = true;
			}
		});	
	}	

	async getMenu() {	
		/*
		lê todas as permissões do usuário logado 
		e armazena na variável allPermissions
		*/		
		await this.getListAllPermissions().then((success : String[]) => {
			this.allPermissions = success;
		});	
	
		await this.loadScreenPermissions();

		// renderiza os itens de menu, na primeira vez que acessar a tela
		this.navMenuItemsArray();

		// renderiza ao fazer a troca de linguagem
		this.translateService.onLangChange.subscribe(
			async (translate: LangChangeEvent) => ( 

				this.navMenuItemsArray()				
		));

	}

	async checkPermission(permissionName:string): Promise<boolean> {		
		let checkPermission = false;
		this.allPermissions.forEach(pm => {
			if (pm === permissionName) {
				checkPermission = true;
			}
		})

		return checkPermission;
	}
/*
	async checkPermissionOK(permissionName:string): Promise<boolean> {		
	    let permissions = this.apsPermissionService.getListPermissions();
		let checkPermission = false;

		await permissions.then((pm: String[]) => {
			pm.forEach(name => {
				if (name == permissionName) {
					checkPermission = true;
				}
			}) 
		})
		return checkPermission;
	}
*/

	async navMenuItemsArray() {

		this.navMenuItems = [
			{
				label: this.translateService.instant('TRANSLATE.HOME'),
				icon:'pi pi-fw pi-home',
				routerLink: '/home',
				command: this.hideSidebar
			},
			{
				label: this.translateService.instant('TRANSLATE.CONTRACT'),
				icon:'pi pi-fw pi-file',
				items: await this.getItensContrato()
			},
			{
				label: this.translateService.instant('TRANSLATE.PROFILE'),
				icon:'pi pi-fw pi-user',
				visible: false,				
				items: [
					{
						label: this.translateService.instant('TRANSLATE.EDIT'), 
						icon: 'pi pi-fw pi-user-edit',
						visible: false,
					},
					{
						label: this.translateService.instant('TRANSLATE.EXIT'), 
						icon: 'pi pi-fw pi-power-off',
						visible: false,
					}						
				]
			}
		]
		
		if (this.isScreenViewsPlans) {
			this.navMenuItems = this.navMenuItems.concat(
				{
					label: this.translateService.instant('TRANSLATE.ADMINISTRATION'),
					icon:'fa fa-users',
					items: await this.getItensBackOffice()
				}
			);
		}	

	}

	async getItensContrato(): Promise<MenuItem[]> {
		let novaListaContrato: MenuItem[] = [];

		if (this.isScreenSales) {
			novaListaContrato = novaListaContrato.concat(
				{
					label: this.translateService.instant('TRANSLATE.NEW'), 
					icon: 'pi pi-fw pi-id-card',
					routerLink: '/sales-channel',
					command: this.hideSidebar
				}
			);
		}	

		if (this.isScreenSalesTelemarketing) {
			novaListaContrato = novaListaContrato.concat(
				{
					label: this.translateService.instant('TRANSLATE.NEW.EXTERNAL'), 
					icon: 'pi pi-fw pi-id-card',
					routerLink: '/sales-channel-external',
					command: this.hideSidebar
				}
			);
		}

		if (this.isScreenContractSearch) {
			novaListaContrato = novaListaContrato.concat(
				{
					label: this.translateService.instant('TRANSLATE.SEARCH'), 
					icon: 'pi pi-fw pi-search',
					routerLink: '/contract-search',
					command: this.hideSidebar
				}
			);
		}
		
		if (this.isScreenContractSearchDealer) {
			novaListaContrato = novaListaContrato.concat(
				{
					label: this.translateService.instant('TRANSLATE.SEARCH'), 
					icon: 'pi pi-fw pi-search',
					routerLink: '/contract-search-dealer',
					command: this.hideSidebar
				}
			);
		}


		 if (this.isScreenManualContract) {
		 	novaListaContrato = novaListaContrato.concat(
		 		{
		 			label: this.translateService.instant('TRANSLATE.MANUAL'), 
		 			icon: 'pi pi-fw pi-file',
		 			routerLink: '/contract-manual',
		 			command: this.hideSidebar
		 		}
		 	);
		 }
		
		 //Os campos e menu abaixo, devem permacer comentados, para futuramente serem usados novamente		
		// if (this.isScreenContractImport) {
		// 	novaListaContrato = novaListaContrato.concat(
		// 		{
		// 			label: this.translateService.instant('TRANSLATE.IMPORT'), 
		// 			icon: 'pi pi-fw pi-cloud-upload',
		// 			routerLink: '/import-contract',
		// 			command: this.hideSidebar
		// 		}	
		// 	);
		// }

		return novaListaContrato;
	}

	async getItensBackOffice(): Promise<MenuItem[]> {
		let newListBackOffice: MenuItem[] = [];

		if (this.isScreenViewsPlans) {
			newListBackOffice = newListBackOffice.concat(
				{
					label: this.translateService.instant('TRANSLATE.PLAN'), 
					icon: 'fa fa-paste',
					routerLink: '/view-plans',
					command: this.hideSidebar
				}
			);
		}	
		
		if (this.isScreenViewsPlans) {
			newListBackOffice = newListBackOffice.concat(
				{
					label: this.translateService.instant('TRANSLATE.REPORT'), 
					icon: 'fas fa-file-excel',
					routerLink: '/reports',
					command: this.hideSidebar
				}
			);
		}	
	
		return newListBackOffice;
	}

}
