import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { ChassiValidation } from "src/app/models/chassi-validation/chassi-validation";
import { environment } from "src/environments/environment";

const httpOptions = {
	headers: new HttpHeaders({
        'Content-Type': 'application/json'
	})
};

@Injectable({
  providedIn: 'root'
})
export class VehicleInformationService {

    private apiUrl = environment.apiUrl;
    private chassiValidation: ChassiValidation;

    constructor(private httpClient: HttpClient) {
    }
   
    setAllChassiValidation(chassiValidation: ChassiValidation) {
      this.chassiValidation = chassiValidation;
    }

    getAllChassiValidation(): ChassiValidation {
      return this.chassiValidation;
    }

    getAllChassiValidationClean() {
      this.chassiValidation = new ChassiValidation();
    }
}
