import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'app-dealer-sale',
	templateUrl: './dealer-sale.component.html'
})
export class DealerSaleComponent implements OnInit {

	constructor() {
	}

	ngOnInit() {
	}

}
