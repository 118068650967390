export class AddressPagarMe {
	line_1: string;
	zip_code: string;
	city: string;
	state: string;
	country: string;

	constructor() {
	
	}
}