export class ProductPolicyResponseDto {

	country: string;
	productId: number;
	coverageCategoryId: number;
	productPolicyId: number;
	priceBookId: number;

	constructor() {
	}
	
}