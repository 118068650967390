export class CustomerFull {

    country: string;
    documentType: string;
    documentNumber: string;
    name: string;
    phone: string;
    email: string;
    lastName: string;
    cep: string;
    streetName: string;
    streetNumber: string;
    streetComplement: string;
    district: string;
    state: string;
    city: string;
    customerSocialName: string;
}