import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { Product } from "src/app/models/product/product";
import { environment } from "src/environments/environment";
import { ClientData } from "src/app/models/client-data/client-data"

const httpOptions = {
	headers: new HttpHeaders({
        'Content-Type': 'application/json'
	})
};

@Injectable({
  providedIn: 'root'
})
export class ClientDataService {

    private apiUrl = environment.apiUrl;
    private clientData: ClientData;

    constructor(private httpClient: HttpClient, private messageService: MessageService) {
    }

    setClientData(clientData: ClientData) {
      this.clientData = clientData;
    }

    getClientData(): ClientData {
      return this.clientData;
    }

    getClientDataClean() {
      this.clientData = new ClientData();
    }

    //@TODO Verificar: NÃO FOI IMPLEMENTADO NO BACK
    getCustomers(country: string, nationalId: string): Observable<ClientData> {
      return this.httpClient.get<ClientData>(`${this.apiUrl}/api/customers/id/${country}/${nationalId}`)
        .pipe(
          map((response: ClientData) => {
                      return response;
                  })
        );
	  }
}