export class ChoosePlanProduct {
    
	planName: string;
	limitVisits: number;
	limitDistance: number;
	limitTime: number;
	unitDistance: string;
	unitTime: string;
	productId: number;
	coverageCategoryId: number;
	priceName: string;
	itemPrice: number = 0;
	unityPrice: string;
	coverageDescription: string;
	offerMonths: number;
	productPolicyKey: number;
	pricebookKey: number;
	productDetails: string;
	
  	constructor() {	

	}
}
