export class VinSaveAndVector {
    
    operation: string;
    country: string;
    vin: string;
    warrantyStartDate: Date;
	wersCode: string;
	modelYear: number;
	originalContractId: string;
	status: string;
    tma: string;
    vehicleLine: string;
    version: string;

    productId: number;
    productName: string;
    coverageId: number;
    coverageName: string;

    constructor() {
	}
}