
export class Address {
    
    country: string;
    address: string;
    addressDistrict: string;
    city: string;
    state: string;
    zipCode: string;
	
	constructor() {
		
	}
}
