export class CoverageCategory {
	
	id: number;
	name: string;
	description: string;
	term: number;
	distance: number;
	visit: number;
	startVisit: number;
	planYear: string;
	account: string;
	actionCode: string;
	coverageCode: string;
	insuranceCode: string;
}