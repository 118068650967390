import { FileFull } from "../files/file-full";

export class ContractPaymentSignature {

	contractId: number;
	transactionId: number;
	country: string;
	operation: string;
	user: string;
	uuid: string;

	files: FileFull = new FileFull();

	constructor() {
	}
}