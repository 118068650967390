import { HomePhonePagarMe } from "./home-phone-pagar-me";
import { MobilePhonePagarMe } from "./mobile-phone-pagar-me";

export class PhonesPagarMe {
	home_phone: HomePhonePagarMe;
	mobile_phone: MobilePhonePagarMe;

	constructor() {
	
	}
}