import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ContractManual } from "src/app/models/contract-manual/contract-manual";

const httpOptions = {
	headers: new HttpHeaders({
        'Content-Type': 'application/json'
	})
};

@Injectable({
  providedIn: 'root'
})
export class ContractManualService {

    private apiUrl = environment.apiUrl;
    
    constructor(private httpClient: HttpClient) {
    }
    
    createContractManual(contractManuais: Array<ContractManual>): Observable<Array<ContractManual>> {
      return this.httpClient.post<Array<ContractManual>>(`${this.apiUrl}/api/v1/contracts/manual`, contractManuais, httpOptions);
    } 
}