import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { FilterContractSearchResult } from "src/app/models/contract/filter-contract-search-result";
import { FilterContractSearch } from "src/app/models/contract/filter-contract-search";
import { SalesTypeFord } from "src/app/models/contract/sales-type-ford";
import { ContractEdit } from "src/app/models/contract/contract-edit";
import { FilterContractSearchResultTotalRecord } from "src/app/models/contract/filter-contract-search-result-total-record";
import { FilterContractSearchResultSimplified } from "src/app/models/contract/filter-contract-search-result-simplified";
import { DownloadFile } from "src/app/models/contract/download-file";
import { ContractSignature } from "src/app/models/contract/contract-signature";
import { CreateContractSignature } from "src/app/models/contract/create-contract-signature";
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContractService {

    private apiUrl = environment.apiUrl;
    private filterContractSearchResultSimplified: FilterContractSearchResultSimplified;;
    private filterContractSearch: FilterContractSearch;
    
    constructor(private httpClient: HttpClient) {
    }

    setFilterContractSearchResultSimplified(filterContractSearchResultSimplified: FilterContractSearchResultSimplified) {
      this.filterContractSearchResultSimplified = new FilterContractSearchResultSimplified;
      this.filterContractSearchResultSimplified = filterContractSearchResultSimplified;
    }

    getFilterContractSearchResultSimplified(): FilterContractSearchResultSimplified {
      return this.filterContractSearchResultSimplified;
    }

    setFilterContractSearch(filterContractSearch: FilterContractSearch) {
      this.filterContractSearch = new FilterContractSearch();
      this.filterContractSearch = filterContractSearch;
    }

    getFilterContractSearch(): FilterContractSearch {
      return this.filterContractSearch;
    }

    contractSearch(filterContractSearch: FilterContractSearch): Observable<FilterContractSearchResultTotalRecord> {
      return this.httpClient.post<FilterContractSearchResultTotalRecord>(`${this.apiUrl}/api/contracts/search`, filterContractSearch);
    }

    dealerContractSearch(filterContractSearch: FilterContractSearch): Observable<FilterContractSearchResultTotalRecord> {
      return this.httpClient.post<FilterContractSearchResultTotalRecord>(`${this.apiUrl}/api/contracts/dealer/search`, filterContractSearch);
    }

    contractSearchExcel(filterContractSearch: FilterContractSearch): Observable<Array<FilterContractSearchResult>> {
      return this.httpClient.post<Array<FilterContractSearchResult>>(`${this.apiUrl}/api/contracts/search/excel`, filterContractSearch);
    }

    dealerContractSearchExcel(filterContractSearch: FilterContractSearch): Observable<Array<FilterContractSearchResult>> {
      return this.httpClient.post<Array<FilterContractSearchResult>>(`${this.apiUrl}/api/contracts/dealer/search/excel`, filterContractSearch);
    }

    // @TODO Verificar: Este endpoint não existe no backend 
    contractSearchTotalRecord(filterContractSearch: FilterContractSearch): Observable<number> {
      return this.httpClient.post<number>(`${this.apiUrl}/api/contracts/search/total/record`, filterContractSearch);
    }
    
    contractEditPost(contractEdit: ContractEdit): Observable<boolean> {
      return this.httpClient.post<boolean>(`${this.apiUrl}/api/contracts/edit`, contractEdit);
    }   

    searchSalesTypeFord(): Observable<Array<SalesTypeFord>> {
      return this.httpClient.get<Array<SalesTypeFord>>(`${this.apiUrl}/api/contracts/search/sales/type`);
    }

    searchContractId(contractId: number, productId: number, coverageCategoryId: number): Observable<FilterContractSearchResult> {
      return this.httpClient.get<FilterContractSearchResult>(`${this.apiUrl}/api/contracts/search/${contractId}/${productId}/${coverageCategoryId}`);
    }

    downloadFileSignatureBase64(contractId: number): Observable<DownloadFile> {
      return this.httpClient.get<DownloadFile>(`${this.apiUrl}/api/contracts/download/${contractId}`);
    }

    downloadFile(contractId: number): Observable<DownloadFile> {
      return this.httpClient.get<DownloadFile>(`${this.apiUrl}/api/file/term/${contractId}`);
    }
  
    sendPaymentLinkByEmail(contractId: number) : Observable<boolean> {
      return this.httpClient.get<boolean>(`${this.apiUrl}/api/contracts/payment/link/${contractId}`);
    }

    public getContractSignature(uuid: string): Observable<ContractSignature> {
      return this.httpClient.get<ContractSignature>(`${this.apiUrl}/api/contracts/signature/${uuid}`)
        .pipe(          
          map((response: ContractSignature) => {
            return response;
          })
        );
    }

    save(createContractSignature: CreateContractSignature): Observable<CreateContractSignature> {
      return this.httpClient.post<CreateContractSignature>(`${this.apiUrl}/api/contracts/signature/save`, createContractSignature);
    }    
}