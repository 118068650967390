import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { cpf } from 'cpf-cnpj-validator';
import { MessageService } from 'primeng/api';
import { ContractSummary } from 'src/app/models/contract-summary/contract-summary';
import { ContractReactivation } from 'src/app/models/contract/contract-reactivation';
import { PaymentBoletoRequestDto } from 'src/app/models/payment-method/payment-boleto-request';
import { PaymentBoletoResponseDto } from 'src/app/models/payment-method/payment-boleto-response';
import { PaymentContractSummaryShort } from 'src/app/models/payment-method/payment-contract-summary-short';
import { PaymentMethod } from 'src/app/models/payment-method/payment-method';
import { PaymentMethodRequest } from 'src/app/models/payment-method/payment-method-request';
import { CreateBoletoRequestDto } from 'src/app/models/payment-method/sa-payments/request/create-boleto-request-dto';
import { CreateBoletoResponseDto } from 'src/app/models/payment-method/sa-payments/response/create-boleto-response-dto';
import { GatewayListBoletoResponseDto } from 'src/app/models/payment-method/sa-payments/response/gateway-list-boleto-response-dto';
import { ContractPaymentSignature } from 'src/app/models/payment-signature/contract-payment-signature';
import { MessageExceptionService } from 'src/app/services/message-exception/message-exception.service';
import { PaymentMethodService } from 'src/app/services/payment-method/payment-method.service';

@Component({
	selector: 'app-payment-method',
	templateUrl: './payment-method.component.html',
	styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent implements OnInit {

	typeCard = [
		{ label: 'Crédito', value: "CREDIT" },
		{ label: 'Débito', value: "DEBIT" }
	];

	monthCard = [
		{ label: '01', value: 1 },
		{ label: '02', value: 2 },
		{ label: '03', value: 3 },
		{ label: '04', value: 4 },
		{ label: '05', value: 5 },
		{ label: '06', value: 6 },
		{ label: '07', value: 7 },
		{ label: '08', value: 8 },
		{ label: '09', value: 9 },
		{ label: '10', value: 10 },
		{ label: '11', value: 11 },
		{ label: '12', value: 12 }
	];

	portionCard = [
		{ label: '1x', value: 1 },
		{ label: '2x', value: 2 },
		{ label: '3x', value: 3 },
		{ label: '4x', value: 4 },
		{ label: '5x', value: 5 },
		{ label: '6x', value: 6 },
		{ label: '7x', value: 7 },
		{ label: '8x', value: 8 },
		{ label: '9x', value: 9 },
		{ label: '10x', value: 10 },
		{ label: '11x', value: 11 },
		{ label: '12x', value: 12 }
	];

	yearCard = [];

	portion: number = 1;
	validateMonth: any;
	validateYear: any;

	portionTotal: number;
	contract: number;
	uuid: string;
	document: string;
	productCode: string;
	paymentContractSummaryShort: PaymentContractSummaryShort;
	paymentMethod: PaymentMethod;
	createBoletoRequestDto: CreateBoletoRequestDto;
	createBoletoResponseDto: CreateBoletoResponseDto;
	paymentBoletoRequestDto: PaymentBoletoRequestDto;
	contractPaymentSignature: ContractPaymentSignature;

	blockedUi: boolean = false;
	isSubmitted: boolean;
	isSubmittedDownload: boolean = false;
	isContractCreated: boolean = false;
	isToShowBackButton: boolean = false;

	isBarcode: boolean = false;
	isHasBoleto: boolean = false;
	barcode: string;
	dueDate: string;
	idBoleto: string;

	isValidationPayment: boolean = false;
	isValidationSignature: boolean = false;
	paymentMethodRequest: PaymentMethodRequest;
	isExpireContract: boolean = false;

	contractReactivation: ContractReactivation;
	isBlockedExpireContract: boolean = false;

	contractSummary: ContractSummary;
	contractIdDownload: number = 0;

	maxDatePermitted: Date = new Date();
	minDatePermitted: Date = new Date();

	salesDate: Date = new Date();
	labelGerarBoleto: string;

	constructor(private translateService: TranslateService,
		private messageService: MessageService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private paymentMethodService: PaymentMethodService,
		private messageExceptionService: MessageExceptionService) {

		this.paymentContractSummaryShort = this.paymentMethodService.getContractSummary();
		if (this.paymentContractSummaryShort == null) {
			this.paymentContractSummaryShort = new PaymentContractSummaryShort();
		}
		this.paymentMethod = new PaymentMethod();
		this.paymentMethodRequest = new PaymentMethodRequest();
		this.contractReactivation = new ContractReactivation();
		this.createBoletoRequestDto = new CreateBoletoRequestDto();
		this.createBoletoResponseDto = new CreateBoletoResponseDto();
		this.paymentBoletoRequestDto = new PaymentBoletoRequestDto();
	}

	ngOnInit() {
		this.activatedRoute.queryParams.subscribe(params => {
			this.uuid = params.uuid;

			this.contractSummarySearch(this.uuid);
			this.portionTotal = this.paymentContractSummaryShort.total / this.portion;
			this.yearCalculate();
			this.paymentMethod.uuid = this.uuid;
			this.validateMonth = this.monthCard[0];
			this.findBoletoByContractId(this.contract.toString());

			if (this.paymentContractSummaryShort.extension != undefined ||
				this.paymentContractSummaryShort.productCode != 'PREB') {
				this.isToShowBackButton = true;
			}
		});

		this.labelGerarBoleto = "Gerar Boleto";
	}

	contractSummarySearch(uuid: string) {
		this.paymentMethodService.getPaymentContractSummaryShort(uuid)
			.subscribe((response: PaymentContractSummaryShort) => {
				this.paymentContractSummaryShort = response;
				this.setDateBoleto(this.paymentContractSummaryShort.salesDate);
				this.portionCalculate();

				if (response.statusPayment == 5) { // 5 = PAGAMENTO REALIZADO
					this.paymentMethodRequest.success = true;
					this.paymentMethodRequest.message = 'Pagamento realizado com sucesso.';
					this.paymentMethodRequest.statusCard = 'Pagamento realizado com sucesso.';
					this.isValidationPayment = true;
					(<HTMLInputElement>document.getElementById("btnStatusPayment")).style.display = 'none';


					if (response.statusContract == 5) { // 5 = CONTRATO ATIVO
						this.paymentMethodRequest.message += " O seu contrato já está ativo.";
						this.paymentMethodRequest.statusCard += " O seu contrato já está ativo.";
						this.isValidationSignature = true;
						(<HTMLInputElement>document.getElementById("btnStatusPayment")).style.display = 'none';

					}
				} else {
					if (this.expireContract(response.createContractDate) > response.daysExpireContract) {
						this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Contrato expirado entrar em contato com vendedor.' });
						this.isExpireContract = true;
						this.contractReactivation.uuid = this.paymentMethod.uuid;
					}
				}
			}, (err) => {
				this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Busca de contrato inválida, aguarde e tente novamente.' });
			});
	}

	private expireContract(createContractDate: Date): number {
		let data = new Date();
		let datafim = new Date(createContractDate);
		let diffInMs = Math.abs(data.getTime() - datafim.getTime());
		let diffInDays = diffInMs / (1000 * 60 * 60 * 24);
		return diffInDays;
	}

	yearCalculate() {
		let date: Date = new Date();
		let dateLabelYear: number = date.getFullYear();

		for (let i = 0; i < 15; i++) {
			let year = dateLabelYear + i;
			this.yearCard.push({ label: year, value: year });
		}

		this.validateYear = this.yearCard[0];
	}

	portionCalculate() {
		this.portionTotal = this.paymentContractSummaryShort.total / this.portion;
	}

	contractPayment() {
		if (this.validation()) {
			this.isSubmitted = true;
			this.blockedUi = true;

			this.paymentMethod.cardTypeEnum = 'CREDIT';
			this.paymentMethod.cardValidateMonth = this.validateMonth.value;
			this.paymentMethod.cardValidateYear = this.validateYear.value;
			this.paymentMethod.cardNumberOfInstallment = this.portion;
			this.paymentMethod.uuid = this.uuid;

			this.paymentMethodService.postPaymentMethod(this.paymentMethod)
				.subscribe((response: PaymentMethodRequest) => {
					if (response.success) {
						this.paymentMethodRequest = response;
						this.paymentMethodRequest.message = 'Pagamento realizado com sucesso.';
						this.isValidationPayment = response.success;
						this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Pagamento realizado com sucesso.' });
						this.contractIdDownload = this.contract;
						if (this.paymentContractSummaryShort.extension != undefined) {
							this.save();
						}
					} else {
						this.messageService.add({ severity: 'error', summary: 'Erro', detail: response.message });
					}
					this.isSubmitted = false;
					this.blockedUi = false;
					if (this.paymentContractSummaryShort.extension != undefined) {
						this.save();
					}
				}, (err) => {
					this.isSubmitted = false;
					this.blockedUi = false;

					this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Pagamento inválido, aguarde e tente novamente.' });
				});
		}
	}

	validation(): boolean {
		let isValid = true;
		if (this.paymentMethod.cardNumber == undefined || this.paymentMethod.cardNumber.length <= 0) {
			this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Número do cartão deve ser preenchido.' });
			isValid = false;
		}

		if (this.paymentMethod.cardSecurityCode == undefined) {
			this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'CVV deve ser preenchido.' });
			isValid = false;
		}

		if (this.paymentMethod.cardName == undefined) {
			this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Nome impresso no cartão deve ser preenchido.' });
			isValid = false;
		}

		if (this.paymentMethod.cardName != undefined && this.paymentMethod.cardName.replace(/([^\d])+/gim, '').length > 0) {
			this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Nome impresso no cartão não pode ter numero.' });
			isValid = false;
		}

		if (this.paymentMethod.cardDocument == undefined) {
			this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'CPF deve ser preenchido.' });
			isValid = false;
		}

		if (this.paymentMethod.cardDocument != undefined && cpf.isValid(this.paymentMethod.cardDocument) == false) {
			this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'CPF inválido.' });
			isValid = false;
		}

		return isValid;
	}

	reactivateContract() {
		this.isSubmitted = true;
		this.blockedUi = true;		

		this.paymentMethodService.postPaymentMethodContractReactivation(this.contractReactivation)
			.subscribe((response: boolean) => {

				if (response) {
					this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Solicitação realizada com sucesso.' });
				} else {
					this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Solicitação inválida, aguarde e tente novamente.' });
				}

				this.isBlockedExpireContract = response;
				this.isSubmitted = false;
				this.blockedUi = false;
			}, (err) => {
				this.isSubmitted = false;
				this.blockedUi = false;

				this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Solicitação inválida, aguarde e tente novamente.' });
			});
	}

	isEmail(search: string): boolean {
		let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
		return regexp.test(search);
	}

	save(): void {

		this.isSubmitted = true;
		this.blockedUi = true;

		this.contractPaymentSignature = this.paymentMethodService.getContractPaymentSignature();
		this.paymentMethodService.save(this.contractPaymentSignature).subscribe((response: ContractPaymentSignature) => {
			this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Contrato criado com sucesso.' })
			this.contractIdDownload = response.contractId;
			this.isContractCreated = true;
			this.blockedUi = false;
		}, (err) => {
			this.messageExceptionService.getMessageException(err.status, 'Não foi possível salvar o contrato, por gentileza, fecha a página e faça o login novamente!');
			this.isSubmitted = false;
			this.blockedUi = false;
			this.isContractCreated = false;
		});
	}

	/**
	 * @param contractId 
	 * 1 - Se nenhum boleto tiver sido gerado, o label do botão deverá ser "Gerar Boleto";
	 * 2 - Se algum boleto tiver sido gerado anteriormente, então o label deverá ser "Gerar Novo Boleto";
	 * 3 - O boleto é considerado PAGO quando o status é do tipo COMPLETED;
	 * 4 - Se o boleto for do tipo PENDING e a data de vencimento for menor do que a data atual,
	 * então é aberta a possibilidade para a geração de um novo boleto;
	 */
	findBoletoByContractId(contractId: string) {
		this.paymentMethodService.findBoletoSaPaymentsByContractId(contractId)
			.subscribe((gateway: GatewayListBoletoResponseDto) => {
				gateway.response.forEach(boleto => {
					const dueDate = new Date(boleto.dueDate);
					const today = new Date();
					if (boleto.status != "" && boleto.status != "COMPLETED") {
						this.isHasBoleto = true;
						this.barcode = boleto.code;
						this.dueDate = boleto.dueDate;
						this.idBoleto = boleto.id;
					} else {
						this.isHasBoleto = false;
						this.barcode = "";
						this.dueDate = "";
						this.idBoleto = "";
					}
				});
			});
	}

	saveBoletoContract() {
		this.isSubmitted = true;
		this.blockedUi = true;
		this.isBarcode = false;
		this.isHasBoleto = false;

		this.setPaymentBoletoRequestDto();

		this.paymentMethodService.postPaymentBoleto(this.paymentBoletoRequestDto)
			.subscribe((response: PaymentBoletoResponseDto) => {
				if (response.httpCode == 200) {
					this.createBoletoResponseDto = response.boletoResponseDto;
					this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: response.message });
					this.isBarcode = true;
					this.isHasBoleto = true;
					this.barcode = this.createBoletoResponseDto.code;
					this.dueDate = this.createBoletoResponseDto.dueDate;
					this.idBoleto = this.createBoletoResponseDto.id;

				} else {
					this.messageService.add({ severity: 'error', summary: 'Erro', detail: response.message });
				}
				this.isSubmitted = false;
				this.blockedUi = false;
			}, (err) => {
				this.isSubmitted = false;
				this.blockedUi = false;
				this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Não foi possível criar o boleto.' });
			});
	}

	setPaymentBoletoRequestDto() {
		this.paymentBoletoRequestDto.documentId = this.document;
		this.paymentBoletoRequestDto.contractId = this.contract;
		this.paymentBoletoRequestDto.dueDate = this.createBoletoRequestDto.dueDate;
		this.paymentBoletoRequestDto.uuid = this.uuid;
	}

	downloadBoletoSaPayments() {
		this.isSubmittedDownload = true;
		this.blockedUi = true;

		// delay de 1 segundo
		setTimeout(() => {

			this.paymentMethodService.downloadBoleto(this.idBoleto)
				.subscribe((blob: Blob): void => {
					const file = new Blob([blob], { type: 'application/pdf' });
					const fileURL = URL.createObjectURL(file);
					window.open(fileURL, '_boleto', 'width=1000, height=800');
					this.isSubmittedDownload = false;
					this.blockedUi = false;
				}, (err) => {
					this.isSubmittedDownload = false;
					this.blockedUi = false;
					this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Não foi possível fazer o download do boleto.' });
				});

		}, 1000);

	}

	copyBarcodeToClipboard() {
		navigator.clipboard.writeText(this.barcode)
			.then(e => {
				this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: "Código de barras copiado para a área de transferência." });
			}).catch(e => {
				this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Não foi possível copiar o código de barras.' });
			});
	}

	setDateBoleto(date: Date) {
		this.salesDate = new Date(date);
		const today = new Date();
		const diferenca = ((today.getDate() - (this.salesDate.getDate() + 1) * 1))
		this.maxDatePermitted.setDate((this.salesDate.getDate() + 15) * 1);
		this.minDatePermitted.setDate((this.salesDate.getDate() + 2 + diferenca) * 1);
	}

	buttonComeBack() {
		this.paymentMethodService.setPaymentContractSummaryShort(this.paymentContractSummaryShort);
		this.router.navigate(['payment-signature'], { queryParams: { uuid: this.uuid } });
	}

	filterInput(event: KeyboardEvent) {

		const input = event.target as HTMLInputElement;
		const inputValue = input.value;
		const lettersOnly = inputValue.replace(/[^a-zA-Z\s]/g, '');

		input.value = lettersOnly;

	}

}