import { FileFull } from "../files/file-full";
import { DealerFull } from "../dealer/dealerFull";
import { SellerFull } from "../seller/sellerFull";

export class CreateContractSignature {
    
  contractId: number;
	user: string;
	country: string;
  
	files: FileFull = new FileFull();

  constructor() {
  }
}