import { AddressPayerBoletoRequestDto } from "./address-payer-boleto-request-dto";

export class PayerBoletoRequestDto {

    name: string;
    nationalId: string;
    address: AddressPayerBoletoRequestDto = new AddressPayerBoletoRequestDto;

    constructor() {
    }
}