export class DealerFull {
    
    code: string;
    pAndAC: string;
    divisionCode: string;
    oldRegionCode: string;
    geoSalesCode: string;
    subCode: string;
    name: string;
    email: string;
    phone: string;

	constructor() {
	
	}
}