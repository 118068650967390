import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SignaturePad } from 'angular2-signaturepad';
import html2pdf from 'html2pdf.js';
import { MessageService } from 'primeng/api';
import { ChassiValidation } from "src/app/models/chassi-validation/chassi-validation";
import { ChoosePlanBuyng } from 'src/app/models/choose-plan/choose-plan-buyng';
import { ClientData } from 'src/app/models/client-data/client-data';
import { ContractSummary } from 'src/app/models/contract-summary/contract-summary';
import { DownloadFile } from 'src/app/models/contract/download-file';
import { PaymentContractSummaryShort } from 'src/app/models/payment-method/payment-contract-summary-short';
import { PaymentMethodRequest } from 'src/app/models/payment-method/payment-method-request';
import { ContractPaymentSignature } from 'src/app/models/payment-signature/contract-payment-signature';
import { ContractService } from 'src/app/services/contract/contract.service';
import { MessageExceptionService } from 'src/app/services/message-exception/message-exception.service';
import { PaymentMethodService } from 'src/app/services/payment-method/payment-method.service';

@Component({
	selector: 'app-payment-signature',
	templateUrl: './payment-signature.component.html',
	styleUrls: ['./payment-signature.component.scss']
})
export class PaymentSignatureComponent implements OnInit {

	isContractCreated: boolean = false;
	isSubmitted = false;
	blockedUi = false;
	contractIdDownload: number = 0;

	paymentSignatureForm: FormGroup;
	submitted = false;
	invalidSignature = true;
	signatureImgBase64: string;
	chassiValidation: ChassiValidation;
	clientData: ClientData;
	contractSummary: ContractSummary;
	termsUser: boolean = false;
	dateAttachment: Date = new Date();
	choosePlanBuyng: ChoosePlanBuyng;
	@ViewChild(SignaturePad) signaturePad: SignaturePad;	
	paymentContractSummaryShort: PaymentContractSummaryShort;
	contractPaymentSignature: ContractPaymentSignature;
	paymentMethodRequest: PaymentMethodRequest;
	contract: number;
	document: string;
	isAllowed: boolean;
	productCode: string;	
	errorContract: boolean = false;
	uuid: string;

	constructor(
		private messageService: MessageService,
		private fb: FormBuilder,
		private router: Router,
		private translateService: TranslateService,
		private paymentMethodService: PaymentMethodService,
		private messageExceptionService: MessageExceptionService,
		private contractService: ContractService,
		private activatedRoute: ActivatedRoute
	) {
		this.choosePlanBuyng = new ChoosePlanBuyng;
		this.paymentContractSummaryShort = new PaymentContractSummaryShort;
	}

	private signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
		'minWidth': 1,
		'canvasWidth': 600,
		'canvasHeight': 100,
	};

	ngAfterViewInit() {
		this.signaturePad.set('minWidth', 1);
		this.signaturePad.clear();		
	}

	drawComplete() {
		this.invalidSignature = this.validateSignature();
	}

	drawStart() {
	}

	drawClean() {
		this.signaturePad.clear();
		this.invalidSignature = this.validateSignature();
	}

	ngOnInit() {
		this.activatedRoute.queryParams.subscribe(params => {

			this.uuid = params.uuid;
			this.contractSummarySearch(this.uuid);
		});

		this.paymentSignatureForm = this.fb.group({
			termsUser: [false, Validators.requiredTrue]
		});		
	}

	contractSummarySearch(uuid: string) {
		this.paymentMethodService
			.getPaymentContractSummaryShort(uuid)
			.subscribe((response: PaymentContractSummaryShort) => {
				
				this.paymentContractSummaryShort = response;

				this.contractPaymentSignature = this.setContractPaymentSignature();

				this.productCode = this.paymentContractSummaryShort.productCode;				

				if (this.productCode == null) {
					this.productCode = this.paymentContractSummaryShort.maintenance.productCode;
				}
				this.handleRouteLogic();				

			},
			(error: any) => {
				this.errorContract = true;

				this.messageService.add(
					{
						severity: 'error', summary: 'Erro'
						, detail: 'Não foi possível buscar informações do contrato.'
					});
			});
	}

	handleRouteLogic() {		
		if (this.paymentContractSummaryShort.extension == undefined && this.paymentContractSummaryShort.country != 'BRA') {
			this.router.navigate(['payment-method']
				, {
					queryParams: {						
						uuid: this.uuid
					}
				});
		}
	}

	get f() {
		return this.paymentSignatureForm.controls;
	}

	getDay(): number {
		return this.dateAttachment.getDate();
	}

	getMonth(): string {
		var numberMonth = this.dateAttachment.getMonth();
		let nameMonth = '';

		switch (numberMonth) {
			case 0:
				nameMonth = "Janeiro";
				break;
			case 1:
				nameMonth = "Fevereiro";
				break;
			case 2:
				nameMonth = "Março";
				break;
			case 3:
				nameMonth = "Abril";
				break;
			case 4:
				nameMonth = "Maio";
				break;
			case 5:
				nameMonth = "Junho";
				break;
			case 6:
				nameMonth = "Julho";
				break;
			case 7:
				nameMonth = "Agosto";
				break;
			case 8:
				nameMonth = "Setembro";
				break;
			case 9:
				nameMonth = "Outubro";
				break;
			case 10:
				nameMonth = "Novembro";
				break;
			case 11:
				nameMonth = "Dezembro";
				break;
		}

		return nameMonth;
	}

	getYear(): number {
		return this.dateAttachment.getFullYear();
	}

	buttonNext() {
		this.submitted = true;
		if (this.paymentSignatureForm.invalid) {
			return;
		}
		if (this.invalidSignature) {
			return;
		}
		this.setSignatureImgBase64();
		this.save();
	}

	downloadPDF2() {
		var element = document.getElementById('pdfPaymentMethod');
		var opt = {
			margin: 0,
			filename: 'TERMO_CONTRATACAO_GARANTIA_ESTENDIDA_REDUZIDA.pdf',
			image: { type: 'jpeg', quality: 0.98 },
			html2canvas: { scale: 2 },
			jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
		};
		html2pdf().from(element).set(opt).save();
	}

	save(): void {		
		this.isSubmitted = true;
		this.paymentMethodService.setContractPaymentSignature(this.setContractPaymentSignature())
		this.paymentMethodService.setPaymentContractSummaryShort(this.paymentContractSummaryShort);
		this.chassiValidation = new ChassiValidation();
		this.choosePlanBuyng = new ChoosePlanBuyng();
		this.clientData = new ClientData();
		this.contractPaymentSignature = new ContractPaymentSignature();
		this.paymentContractSummaryShort = new PaymentContractSummaryShort;
		this.blockedUi = false;

		this.router.navigate(['payment-method'], {
			queryParams: {
				uuid: this.uuid
			}
		});
	}

	nationalId(cpfCnpj: string): string {
		if (cpfCnpj.length == 11) {
			return 'PF';
		} else {
			return 'PJ';
		}
	}

	contractStartDate(): Date {
		let date = new Date();
		if (this.chassiValidation.warrantyStartDate != null) {
			date = this.chassiValidation.warrantyStartDate;
		}
		return date;
	}

	validateSignature(): boolean {
		let retorno = true;
		if (!this.signaturePad.isEmpty()) {
			retorno = false;
		}
		return retorno;
	}

	setSignatureImgBase64() {
		this.signatureImgBase64 = this.signaturePad.toDataURL();
	}

	fordImageLogo(valor) {
		if (valor == 1) {
			document.getElementById("fordImage").style.display = "";
		} else {
			document.getElementById("fordImage").style.display = "none";
		}
	}

	exportPDF() {
		this.showLogo();
		this.downloadPDF();
		this.hideLogo();
	}

	showLogo() {
		this.fordImageLogo(1);
	}

	hideLogo() {
		setTimeout(this.fordImageLogo, 10000);
	}

	private setContractPaymentSignature(): ContractPaymentSignature {

		let contractPaymentSignature = new ContractPaymentSignature();

		contractPaymentSignature.contractId = this.paymentContractSummaryShort.contractId;
		contractPaymentSignature.user = "EMISSAO";

		contractPaymentSignature.files.type = 1;
		contractPaymentSignature.files.name = "Ford Protect Termo de Contratacao";
		contractPaymentSignature.files.description = "TERMO DE CONTRATAÇÃO - GARANTIA ESTENDIDA REDUZIDA";
		contractPaymentSignature.files.fileReference = "1";
		contractPaymentSignature.files.file = this.signatureImgBase64;

		return contractPaymentSignature;
	}

	downloadPDF() {
		this.blockedUi = true;
		this.contractService.downloadFile(this.contractIdDownload)
			.subscribe((response: DownloadFile) => {
				const source = response.pdfByteArray;
				const link = document.createElement("a");
				link.href = source;
				link.download = `Termos do Contrato.pdf`
				link.click();

				this.blockedUi = false;
			}, (err) => {
				this.blockedUi = false;
				this.messageExceptionService.getMessageException(err.status, 'Arquivo não encontrado.');
			});
	}
}
