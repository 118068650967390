import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConstantsFordProtect } from 'src/app/models/constants/constants-ford-protect';

@Component({
  selector: 'coverage-details-edit',
  templateUrl: './coverage-details-edit.component.html',
  styleUrls: ['./coverage-details-edit.component.scss']
})
export class CoverageDetailsEditComponent implements OnInit {

  inputCoverageName: string;
  inputCoverageDescription: string;
  selectTypeCoverage: string;
  inputOwsCoverage: string;
  inputMonths: number;
  inputDistance: number;
  inputTotalVisits: number;
  inputInitialVisit: number;
  
  selectDisabled: boolean;

  listTypeCoverage = [
    { label: 'TRANSLATE.SELECT' },
    { label: 'TRANSLATE.UPPERCASE.MAINTENANCE', value: ConstantsFordProtect.MAINTENANCE },
    { label: 'TRANSLATE.UPPERCASE.EXTENSION', value: ConstantsFordProtect.EXTENSION },
    { label: 'TRANSLATE.UPPERCASE.REPAIRS', value: ConstantsFordProtect.REPAIRS },
    { label: 'TRANSLATE.UPPERCASE.SIGNALS', value: ConstantsFordProtect.SIGNALS },
    { label: 'TRANSLATE.UPPERCASE.WINCHES', value: ConstantsFordProtect.WINCHES },
    { label: 'TRANSLATE.UPPERCASE.WEAR', value: ConstantsFordProtect.WEAR }    
  ];

  constructor(
    private router: Router,

  ) { }

  ngOnInit() {

    this.buttonCancel();

  }

  buttonReturn() {
    this.router.navigate(['view-plans'], { skipLocationChange: false, replaceUrl: false });
  }

  buttonEdit() {

    (<HTMLInputElement>document.getElementById("coverageIdName")).disabled = false;
    (<HTMLInputElement>document.getElementById("coverageDescription")).disabled = false;
     this.selectDisabled = false;
    (<HTMLInputElement>document.getElementById("owsCoverage")).disabled = false;
    (<HTMLInputElement>document.getElementById("months")).disabled = false;
    (<HTMLInputElement>document.getElementById("distance")).disabled = false;
    (<HTMLInputElement>document.getElementById("totalVisits")).disabled = false;
    (<HTMLInputElement>document.getElementById("initialVisit")).disabled = false;

    (<HTMLInputElement>document.getElementById("titleDetails")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("titleEdit")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("subTitle")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnReturn")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnEdit")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnCancel")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnSave")).style.display = 'block';

  }

  buttonCancel() {

    (<HTMLInputElement>document.getElementById("coverageIdName")).disabled = true;
    (<HTMLInputElement>document.getElementById("coverageDescription")).disabled = true;
    this.selectDisabled = true;      
    (<HTMLInputElement>document.getElementById("typeCoverage")).style.color = '#202020';
    (<HTMLInputElement>document.getElementById("typeCoverage")).style.backgroundColor = '#E5E5E5';
    (<HTMLInputElement>document.getElementById("owsCoverage")).disabled = true;
    (<HTMLInputElement>document.getElementById("months")).disabled = true;
    (<HTMLInputElement>document.getElementById("distance")).disabled = true;
    (<HTMLInputElement>document.getElementById("totalVisits")).disabled = true;
    (<HTMLInputElement>document.getElementById("initialVisit")).disabled = true;

    (<HTMLInputElement>document.getElementById("titleDetails")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("titleEdit")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("subTitle")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnReturn")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnEdit")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnCancel")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnSave")).style.display = 'none';

    this.restoringValues();

  }

  optionCoverageTypeChange(event: any) {
		this.selectTypeCoverage = event.value;
	}

  months(months: any) {
    this.inputMonths = this.formatNumber(months);
  }
  
  distance(distance: any) {
    this.inputDistance = this.formatNumber(distance);
  }

  totalVisits(totalVisits: any) {
    this.inputTotalVisits = this.formatNumber(totalVisits);
  }
  
  initialVisit(initialVisit: any) {
    this.inputInitialVisit = this.formatNumber(initialVisit);
  }
  
  formatNumber(event: any) {
    const input = event.target;
    let value = input.value;
    const valueFormat = value.replace(/[^0-9]/g, '');

    input.value = valueFormat;
    return valueFormat;
  }

  buttonSave() {

    this.inputCoverageName = (<HTMLInputElement>document.getElementById("coverageIdName")).value;
    this.inputCoverageDescription = (<HTMLInputElement>document.getElementById("coverageDescription")).value;
    this.inputOwsCoverage = (<HTMLInputElement>document.getElementById("owsCoverage")).value;
  
    if (this.inputCoverageName === undefined ||
      this.inputCoverageName.length < 4 ||
      this.inputCoverageName.length > 20) {

      this.inputCoverageName = null;
    }
    if (this.inputCoverageDescription === undefined ||
      this.inputCoverageDescription.length < 4 ||
      this.inputCoverageDescription.length > 100) {

      this.inputCoverageDescription = null;
    } 
    if (this.inputOwsCoverage === undefined ||
      this.inputOwsCoverage.length < 4 ||
      this.inputOwsCoverage.length > 8) {

      this.inputOwsCoverage = null;
    } 
    if (this.selectTypeCoverage === '') {

      this.selectTypeCoverage = null;
    }
    if (this.inputMonths === undefined) {

      this.inputMonths = null;
    }
    if (this.inputDistance === undefined) {
      this.inputDistance = null;
    }
    if (this.inputTotalVisits === undefined) {

      this.inputTotalVisits = null;
    }
    if (this.inputInitialVisit === undefined) {

      this.inputInitialVisit = null;
    }
    else {

     this.restoringValues();  

    }

  }

  restoringValues() {

    this.inputCoverageName = '';
    this.inputCoverageDescription = '';
    this.inputOwsCoverage = '';
    this.selectTypeCoverage = '';
    this.inputMonths = undefined;
    this.inputDistance = undefined;
    this.inputTotalVisits = undefined;
    this.inputInitialVisit = undefined;
  }

}
