import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConstantsFordProtect } from 'src/app/models/constants/constants-ford-protect';

@Component({
  selector: 'price-details-edit',
  templateUrl: './price-details-edit.component.html',
  styleUrls: ['./price-details-edit.component.scss']
})
export class PriceDetailsEditComponent implements OnInit {

  listNamePrice = [
    { label: 'TRANSLATE.SELECT' },
    { label: 'TRANSLATE.SELECT.PRICE.CUSTOMER', value: ConstantsFordProtect.PRICE_CUSTOMER },
    { label: 'TRANSLATE.SELECT.SELLER.PRICE', value: ConstantsFordProtect.SELLER_PRICE },
    { label: 'TRANSLATE.SELECT.COST.FORD', value: ConstantsFordProtect.COST_FORD }
  ];

  listCurrency = [
    { label: 'TRANSLATE.SELECT' },
    { label: 'TRANSLATE.SELECT.COUNTRY.CURRENCY.BRA', value: ConstantsFordProtect.REAL },
    { label: 'TRANSLATE.SELECT.COUNTRY.CURRENCY.OTHERS', value: ConstantsFordProtect.PESO }
  ];

  selectedNamePrice: string;
  selectedCurrency: string;
  inputPrice: string;
  selectDisabled: boolean;
  descriptionPrice: string = "Ola descrição Teste";
  
  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    
    this.buttonCancel();
  }
 
  buttonReturn() {
    this.router.navigate(['view-plans'], { skipLocationChange: false, replaceUrl: false });
  }
  
  namePriceChange(event: any) {
    this.selectedNamePrice = event.value;
  }
  
  currencyChange(event: any) {
    this.selectedCurrency = event.value;
  }

  formatNumber(event: any) {
    const input = event.target as HTMLInputElement;
    let priceTreatment = input.value;
    priceTreatment = priceTreatment.replace(/[^\d,.]/g, '');

    let parts = priceTreatment.split(',');
    const hasComma = parts.length > 1;

    if (parts[0].length > 5 && !hasComma) {
      parts[0] = parts[0].slice(0, 5).replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ',' + parts[0].slice(5);
    }
    if (parts.length > 1 && parts[1].length > 2) {
      parts[1] = parts[1].slice(0, 2);
    }
    
    input.value = parts.join(',');
    const priceComma = input.value;
    let valueWithoutPoint = priceComma.replace(/\./g, '');
    valueWithoutPoint = valueWithoutPoint.replace(',', '.')
    this.inputPrice = valueWithoutPoint;
  }

  buttonEdit() {

    (<HTMLInputElement>document.getElementById("valuePrice")).disabled = false;
    this.selectDisabled = false;
     
    (<HTMLInputElement>document.getElementById("titleDetails")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("titleEdit")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("subTitle")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnReturn")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnEdit")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnCancel")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnSave")).style.display = 'block';

  }

  buttonCancel() {

    (<HTMLInputElement>document.getElementById("valuePrice")).disabled = true;  
    (<HTMLInputElement>document.getElementById("currency")).style.color = '#202020';
    (<HTMLInputElement>document.getElementById("currency")).style.backgroundColor = '#E5E5E5';
    (<HTMLInputElement>document.getElementById("namePrice")).style.color = '#202020';
    (<HTMLInputElement>document.getElementById("namePrice")).style.backgroundColor = '#E5E5E5';
    this.selectDisabled = true;
    (<HTMLInputElement>document.getElementById("titleDetails")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("titleEdit")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("subTitle")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnReturn")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnEdit")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnCancel")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnSave")).style.display = 'none';

    this.restoringValues(); 
  }

  
  buttonSave() { 

    if (this.selectedNamePrice === "") {

      this.selectedNamePrice = null;
    }
    if (this.selectedCurrency === "") {

      this.selectedCurrency = null;
    } 
    if (this.inputPrice === "") {

      this.inputPrice = null;
    }
    else{
      this.restoringValues();  
    }

  }

  restoringValues() {

    this.selectedNamePrice = '';
    this.selectedCurrency = '';
    this.inputPrice = '';    
  }

}
