import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { InvoiceReportNoValueNotesResult } from "src/app/models/Invoice/invoice-report-no-value-notes-result";
import { InvoiceReportSummaryResult } from "src/app/models/Invoice/invoice-report-summary-result";
import { InvoiceReportValueNotesResult } from "src/app/models/Invoice/invoice-report-value-notes-result";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

    private apiUrl = environment.apiUrl;
    
    constructor(private httpClient: HttpClient) {
    }

    async invoiceReportSummaryPromise(year: string, month: string): Promise<Array<InvoiceReportSummaryResult>> {
      return this.httpClient.get<Array<InvoiceReportSummaryResult>>(`${this.apiUrl}/api/invoice/summary/${year}/${month}`).toPromise();
    } 
    
    async invoiceReportValueNotesPromise(year: string, month: string): Promise<Array<InvoiceReportValueNotesResult>> {      
      return this.httpClient.get<Array<InvoiceReportValueNotesResult>>(`${this.apiUrl}/api/invoice/value/notes/${year}/${month}`).toPromise();
    }    

    async invoiceReportNoValueNotesPromise(year: string, month: string): Promise<Array<InvoiceReportNoValueNotesResult>> {      
      return this.httpClient.get<Array<InvoiceReportNoValueNotesResult>>(`${this.apiUrl}/api/invoice/novalue/notes/${year}/${month}`).toPromise();
    }



}