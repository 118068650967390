import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import jwt_decode from "jwt-decode";
import { MessageService } from "primeng/api";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { ApsPermission } from "src/app/models/aps-permission/aps-permission";
import { ConstantsFordProtect } from "src/app/models/constants/constants-ford-protect";
import { UserToken } from "src/app/models/user/user-token";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ApsPermissionService {

  private apiUrl = environment.apiUrl;
  private apsPermission: ApsPermission;
  private userToken: UserToken;
  private userCsid: String;
  private permissions: string[];
  private snapshotUrl: String;

  constructor(private httpClient: HttpClient, 
    private messageService: MessageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
  ) {
    this.apsPermission = new ApsPermission();
    this.userToken = new UserToken();
    this.snapshotUrl = this.activatedRoute.snapshot['_routerState'].url;
  }

  setApsPermission(apsPermission: ApsPermission) {
    this.apsPermission = apsPermission;
  }

  getApsPermission(): ApsPermission {
    return this.apsPermission;
  }

  async getPermission(): Promise<Observable<ApsPermission>> {
    return this.httpClient.get<ApsPermission>(`${this.apiUrl}/api/permission/${this.getUserCsid()}`)
      .pipe(
        map((response: ApsPermission) => {
          return response;
        })
      );
  }

  async getPermissionsToPromise(): Promise<ApsPermission> {
    return this.httpClient.get<ApsPermission>(`${this.apiUrl}/api/permission/${this.getUserCsid()}`).toPromise();
  }

  async getPermissionByPermissionName(name: string): Promise<boolean> {    
    let isValid = false;
    await this.getPermissionsToPromise().then(lista => {
      lista.Permitted.forEach(permitted => {
        permitted.execute.forEach(permissionName => {
          if (permissionName == name) {
            isValid = true;
          }
        })
      })
    });
    return isValid;
  }

  async getListPermissions(): Promise<String[]> {
    let permissions: String[] = [];
    this.snapshotUrl = this.activatedRoute.snapshot['_routerState'].url;
    if (!this.snapshotUrl.includes(ConstantsFordProtect.OPEN_URL_FRONT)) {
      await this.getPermissionsToPromise().then(lista => {
          lista.Permitted.forEach(permitted => {
            permitted.execute.forEach(permissionName => {
              permissions.push(permissionName);
            })
          })
        });
    }
    return permissions;

  }

  async getSearchApsPermission() {
    await this.getPermission().then(success => {
      success.subscribe((response: ApsPermission) => {
        if (response.Permitted.length > 0) {
          this.setApsPermission(response);
        } else {
          this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Usuário sem permissão.' });
        }
      }, (err) => {

        if (err.status === 400 || err.status === 404 || err.status === 500) {
          this.messageService.add({ 
            severity: 'error', 
						summary: this.translateService.instant('TRANSLATE.ERROR'),
						detail: this.translateService.instant('TRANSLATE.USERS.NOT.EXIST')
          });

        } else if (err.status === 401) {

          this.messageService.add({
           severity: 'error',
           summary: this.translateService.instant('TRANSLATE.ERROR'),
           detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
          });
       
       } else {
       
         this.messageService.add({
           severity: 'error',
           summary: this.translateService.instant('TRANSLATE.ERROR'),
           detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
         });
       }

      });
    })
  }

  permissionValidation(screenName: string): boolean {
    let isValid = false;
    this.apsPermission?.Permitted.forEach(permitted => {
      permitted.execute.forEach(permissionName => {
        if (screenName === permissionName) {
          isValid = true;
        }
      });
    }, (err) => {
      if (err.status >= 400) {
        this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Usuário sem permissão contatar administrador.' });
        this.router.navigate(['home']);
      } 
    });

    return isValid;
  }

  isPermissionValidationDisabled(screenName: string): boolean {
    let isValid = true;
    this.apsPermission?.Permitted.forEach(permitted => {
      permitted.execute.forEach(permissionName => {
        if (screenName === permissionName) {
          isValid = false;
        }
      });
    });

    return isValid;
  }

  getUserCsid(): string {
    if (sessionStorage.getItem('encodedAccessToken') != "undefined" && sessionStorage.getItem('encodedAccessToken') !== null) {
      const token = sessionStorage.getItem('encodedAccessToken');
      const decodedToken = jwt_decode(token);

      this.userToken.commonName = decodedToken['CommonName'];
      this.userToken.authTime = decodedToken['auth_time'];
      this.userToken.emailAddress = decodedToken['emailaddress'];
      this.userToken.givenName = decodedToken['givenname'];
      this.userToken.sub = decodedToken['sub'];
      this.userToken.surName = decodedToken['surname'];
      this.userToken.country = decodedToken['country'];

      return this.userToken.commonName;
    } else {
      return '';
    }
  }

  getUserCountry(): string {
    if (sessionStorage.getItem('encodedAccessToken') != "undefined" && sessionStorage.getItem('encodedAccessToken') !== null) {
      const token = sessionStorage.getItem('encodedAccessToken');
      const decodedToken = jwt_decode(token);
      this.userToken.country = decodedToken['country'];
      return this.userToken.country;
    } else {
      return '';
    }
  }

  getUserToken(): UserToken {
    return this.userToken;
  }

}