import { AddressPayerBoletoResponseDto } from "./address-payer-boleto-response-dto";


export class PayerBoletoResponseDto {

    name: string;
    nationalId: string;
    address: AddressPayerBoletoResponseDto = new AddressPayerBoletoResponseDto;

    constructor() {
    }
}