import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import locale from '@angular/common/locales/en';
import localeCL from '@angular/common/locales/es-CL';
import localeCO from '@angular/common/locales/es-CO';
import localePT from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthGuardService, HttpRequestInterceptor, NgADFSModule, UrlConsumerService, UserIdService } from '@sade/ng-adfs';
import { SignaturePadModule } from 'angular2-signaturepad';
import { TextMaskModule } from 'angular2-text-mask';
import { MomentModule } from 'ngx-moment';
import { AccordionModule } from 'primeng/accordion';
import { MessageService, SharedModule } from 'primeng/api';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenubarModule } from 'primeng/menubar';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './core/footer/footer.component';
import { HeaderComponent } from './core/header/header.component';

import { PanelModule } from 'primeng';
import { TabViewModule } from 'primeng/tabview';
import { environment } from 'src/environments/environment';
import { RemoveAuthInterceptor } from './models/aps-permission/remove-auth-interceptor';
import { ChassiValidationService } from './services/chassi-validation/chassi-validation.service';
import { ChoosePlanService } from './services/choose-plan/choose-plan.service';
import { ContractManualService } from './services/contract-manual/contract-manual.service';
import { ContractSummaryService } from './services/contract-summary/contract-summary.service';
import { OrdersPagarMeService } from './services/orders-pagar-me/orders-pagar-me.service';
import { PlanSelecionService } from './services/plan-selection/plan-selection.service';
import { ProductService } from './services/product/product.service';
import { VehicleInformationService } from './services/vehicle-information/vehicle-information.service';
import { VinService } from './services/vin/vin.service';
import { ChoosePlanComponent } from './views/choose-plan/choose-plan.component';
import { ClientDataComponent } from './views/client-data/client-data.component';
import { ContractEditDealerComponent } from './views/contract-dealer/contract-edit-dealer.component';
import { ContractSearchDealerComponent } from './views/contract-dealer/contract-search-dealer.component';
import { ContractManualComponent } from './views/contract-manual/contract-manual.component';
import { ContractSignatureComponent } from './views/contract-signature/contract-signature.component';
import { ContractSummaryAttachmentOneComponent } from './views/contract-summary-attachment-one/contract-summary-attachment-one.component';
import { ContractSummaryAttachmentTwoComponent } from './views/contract-summary-attachment-two/contract-summary-attachment-two.component';
import { ContractSummaryExternalComponent } from './views/contract-summary-external/contract-summary-external.component';
import { ContractSummaryComponent } from './views/contract-summary/contract-summary.component';
import { ContractEditComponent } from './views/contract/contract-edit.component';
import { ContractSearchComponent } from './views/contract/contract-search.component';
import { CustomerComponent } from './views/customer/customer.component';
import { HomeComponent } from './views/home/home.component';
import { ImportContractComponent } from './views/import-contract/import-contract.component';
import { InformationComponent } from './views/information/information.component';
import { LegalTextContractBraBas1Component } from './views/legal-text-contract/legal-text-contract-bra-bas1/legal-text-contract-bra-bas1.component';
import { LegalTextContractBraPrebComponent } from './views/legal-text-contract/legal-text-contract-bra-preb/legal-text-contract-bra-preb.component';
import { LegalTextContractChileComponent } from './views/legal-text-contract/legal-text-contract-chile/legal-text-contract-chile.component';
import { LegalTextContractColombiaComponent } from './views/legal-text-contract/legal-text-contract-colombia/legal-text-contract-colombia.component';
import { LoginComponent } from './views/login/login.component';
import { NavComponent } from './views/nav/nav.component';
import { DealerSaleComponent } from './views/new-contract-dialer-sale/dealer-sale.component';
import { DirectSaleComponent } from './views/new-contract-direct-sale/direct-sale.component';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { PaymentLegalTextContractBraBas1Component } from './views/payment-legal-text-contract/payment-legal-text-contract-bra-bas1/payment-legal-text-contract-bra-bas1.component';
import { PaymentLegalTextContractBraPrebComponent } from './views/payment-legal-text-contract/payment-legal-text-contract-bra-preb/payment-legal-text-contract-bra-preb.component';
import { PaymentMethodComponent } from './views/payment-method/payment-method.component';
import { PaymentSignatureComponent } from './views/payment-signature/payment-signature.component';
import { PlanSelectionComponent } from './views/plan-selection/plan-selection.component';
import { NewCoverageComponent } from './views/product-create-search/new-coverage/new-coverage.component';
import { NewEligibilityComponent } from './views/product-create-search/new-eligibility/new-eligibility.component';
import { NewPriceComponent } from './views/product-create-search/new-price/new-price.component';
import { NewProductComponent } from './views/product-create-search/new-product/new-product.component';
import { ViewPlansComponent } from './views/product-create-search/view-plans/view-plans.component';
import { SalesChannelExternalComponent } from './views/sales-channel-external/sales-channel-external.component';
import { SalesChannelComponent } from './views/sales-channel/sales-channel.component';
import { VehicleInformationComponent } from './views/vehicle-information/vehicle-information.component';
import { ProductDetailsEditComponent } from './views/plans-details-edit/product-details-edit/product-details-edit.component';
import { CoverageDetailsEditComponent } from './views/plans-details-edit/coverage-details-edit/coverage-details-edit.component';
import { PriceDetailsEditComponent } from './views/plans-details-edit/price-details-edit/price-details-edit.component';
import { EligibilityDetailsEditComponent } from './views/plans-details-edit/eligibility-details-edit/eligibility-details-edit.component';
import { ReplacePipeModule } from './models/modules/replace-pipe.module';
import { ReportsComponent } from './views/excel-reports/reports/reports.component';

registerLocaleData(localeCL);
registerLocaleData(localeCO);
registerLocaleData(localePT);
registerLocaleData(locale);

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

@NgModule({
	declarations: [
		AppComponent,
		NavComponent,
		NotFoundComponent,
		FooterComponent,
		HeaderComponent,
		HomeComponent,
		DirectSaleComponent,
		DealerSaleComponent,
		ImportContractComponent,
		PlanSelectionComponent,
		CustomerComponent,
		LoginComponent,
		SalesChannelComponent,
		SalesChannelExternalComponent,
		VehicleInformationComponent,
		ChoosePlanComponent,
		ClientDataComponent,
		ContractSummaryComponent,
		ContractSummaryExternalComponent,
		ContractSummaryAttachmentOneComponent,
		ContractSummaryAttachmentTwoComponent,
		ContractSearchComponent,
		ContractEditComponent,
		ContractManualComponent,
		PaymentMethodComponent,
		PaymentSignatureComponent,
		ContractSearchDealerComponent,
		ContractEditDealerComponent,
		InformationComponent,
		ContractSignatureComponent,
		LegalTextContractBraPrebComponent,
		LegalTextContractChileComponent,
		LegalTextContractBraBas1Component,
		PaymentLegalTextContractBraBas1Component,
		PaymentLegalTextContractBraPrebComponent,
		LegalTextContractColombiaComponent,
  	ViewPlansComponent,
  	NewProductComponent,
  	NewCoverageComponent,
  	NewEligibilityComponent,
  	NewPriceComponent,
   ProductDetailsEditComponent,
   CoverageDetailsEditComponent,
   PriceDetailsEditComponent,
   EligibilityDetailsEditComponent,
   ReportsComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		CalendarModule,
		AppRoutingModule,
		ButtonModule,
		MenubarModule,
		PanelMenuModule,
		InputSwitchModule,
		SidebarModule,
		ToastModule,
		InputTextareaModule,
		TableModule,
		FileUploadModule,
		HttpClientModule,
		ProgressBarModule,
		MultiSelectModule,
		DropdownModule,
		RadioButtonModule,
		CardModule,
		AccordionModule,
		InputTextModule,
		CheckboxModule,
		MessageModule,
		FieldsetModule,
		InputMaskModule,
		SignaturePadModule,
		InputNumberModule,
		MomentModule,
		TextMaskModule,
		DialogModule,
		ProgressSpinnerModule,
		BlockUIModule,
		TabViewModule,
		SharedModule,
		PanelModule,
		ReplacePipeModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient]
			}
		}),
		NgADFSModule.forRoot({
			clientId: environment.clientId, //"insert your client-id here"
			environment: environment.Env, // Set the ADFS environment what you are using, with 'Env' enum
			resourceUri: environment.resourceUri, //"insert your resource here"
		})
	],
	providers: [
		{ provide: LOCALE_ID, useValue: "pt" },
		ChassiValidationService,
		MessageService,
		AuthGuardService,
		UserIdService,
		TranslateService,
		PlanSelecionService,
		ProductService,
		VehicleInformationService,
		UrlConsumerService,
		ContractSummaryService,
		ChoosePlanService,
		VinService,
		ContractManualService,
		OrdersPagarMeService,		
		{
			provide: HTTP_INTERCEPTORS,
			useClass: RemoveAuthInterceptor,
			multi: true
		},
		
	],
	bootstrap: [AppComponent],
})

export class AppModule {
}
