import { Dealer } from "../dealer/dealer";
import { DealerSeller } from "../dealer/dealer-seller";

interface TypePayment {
    label: string,
	value: boolean
}

interface FormaPayment {
    label: string,
	value: string
}

interface PrazoPayment {
    label: string,
	value: number
}

interface TransactionPayment {
    label: string,
	value: boolean
}

export class ContractSummary {
    
    typePayment: TypePayment;
    formaPayment: FormaPayment;
    prazoPayment: PrazoPayment;
    dealer: Dealer;
    dealerSeller: DealerSeller;
    transactionPayment: TransactionPayment;    

	constructor() {
        this.dealer = new Dealer();
        this.dealerSeller = new DealerSeller();
	}
}
