import { CustomerPagarMe } from "./customer-pagar-me";
import { ItemPagarMe } from "./item-pagar-me";
import { PaymentPagarMe } from "./payment-pagar-me";

export class OrdersPagarMe {
    items: Array<ItemPagarMe> = [];
    customer: CustomerPagarMe;
    payments: Array<PaymentPagarMe> = [];

	constructor() {
	
	}
}