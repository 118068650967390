import { DebitPagarMe } from "./debit-pagar-me";

export class DebitCardPagarMe {
	recurrence: boolean;
	installments: number;
	statement_descriptor: String;
	card: DebitPagarMe;

	constructor() {
	
	}
}