import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { OrdersPagarMe } from "src/app/models/pagar-me/orders-pagar-me";
import { OrdersPagarMeResponsePix } from "src/app/models/pagar-me/response/orders-pagar-me-response.pix";

import { environment } from "src/environments/environment";

const httpOptions = {
	headers: new HttpHeaders({
        'Content-Type': 'application/json'
	})
};

@Injectable({
  providedIn: 'root'
})
export class OrdersPagarMeService {

    private apiUrl = environment.apiUrl;
    private ordersPagarMe: OrdersPagarMe;

    constructor(private httpClient: HttpClient) {
    }
   
    setOrders(ordersPagarMe: OrdersPagarMe) {
        this.ordersPagarMe = ordersPagarMe;
    }

    getOrders(): OrdersPagarMe {
        return this.ordersPagarMe;
    }

    save(ordersPagarMe: OrdersPagarMe): Observable<OrdersPagarMe> {
        return this.httpClient.post<OrdersPagarMe>(`${this.apiUrl}/api/pagarme/orders`, ordersPagarMe, httpOptions);
    }

    saveDebit(ordersPagarMe: OrdersPagarMe): Observable<OrdersPagarMe> {
      return this.httpClient.post<OrdersPagarMe>(`${this.apiUrl}/api/pagarme/orders/debit`, ordersPagarMe, httpOptions);
    }

    savePix(ordersPagarMe: OrdersPagarMe): Observable<OrdersPagarMeResponsePix> {
      return this.httpClient.post<OrdersPagarMeResponsePix>(`${this.apiUrl}/api/pagarme/orders/pix`, ordersPagarMe, httpOptions);
    }        
}