import { Env } from "@sade/ng-adfs";

export const environment = {

	production: true,
	apiUrl: 'https://api.pd01e.gcp.ford.com/sa/esb/external',
	baseUrl: 'https://api.pd01e.gcp.ford.com/sa/esb/external',
	clientId: 'urn:fp2:pcf:native:prod',
	Env: Env.PROD,
	resourceUri: 'urn:fp2:pcf:weblogin:prod'
};
