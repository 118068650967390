import { SignatureContractSummaryProduct } from "./signature-contract-summary-product";

export class ContractSignature {
    
  	contractId: number;
	salesDate: Date;
	startDate: Date;
	endDate: Date;
	term: number;
	distance: number;
	visit: number;
	statusContract: number;	
	statusPayment: number;
	due: Date;
	total: number;
	modelYear: number;
	cvvg: Date;
	odometerStart: number;
	vehicleLine: string;
	country: string;
	ibgeNumber: string;
	factoryDistanceLimit: number = 0;
	factoryTimeLimit: number = 0;
	createContractDate: Date;
	extendedWarranty: number;
	extension: SignatureContractSummaryProduct;
	part: SignatureContractSummaryProduct;
	maintenance: SignatureContractSummaryProduct;
	daysExpireContract: number;
	modelYearLine: string;
	customerPrice: number; // PREÇO FINAL
	paymentMethod: string;
	paymentModality: string;
	numberParcels: number;
	productName: string;
	codeProduct: string;
	dealerK: string;
	dealerPAndAc: string;
	sellerK: number;
	uuid: string;

	constructor() {
	}
}