export class ProductPolicyRequestDto {

	country: string;
	user: string;
	policyDescription: string;
	policyName: string;
	productId: number;
	coverageCategoryId: number;
	saleType: number;
	isActive: number;
	vinCategoryVersionId: number;
	months: number;
	clientType: number;
	itemPriceId: number;

	constructor() {
	}
}