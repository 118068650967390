import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ApsPermitedScreen } from 'src/app/models/aps-permission/aps-permited-screen';
import { ContractEdit } from 'src/app/models/contract/contract-edit';
import { FilterContractSearchResult } from 'src/app/models/contract/filter-contract-search-result';
import { SalesTypeFord } from 'src/app/models/contract/sales-type-ford';
import { ApsPermissionService } from 'src/app/services/aps-permission/aps-permission.service';
import { ContractService } from 'src/app/services/contract/contract.service';

@Component({
	selector: 'app-contract-edit',
	templateUrl: './contract-edit.component.html',
	styleUrls: ['./contract-edit.component.css']
})
export class ContractEditComponent implements OnInit {

	pt = {
		firstDayOfWeek: 0,
		dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
		dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
		dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
		monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
			'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
		monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
		today: 'Hoje',
		clear: 'Limpar'
	};

	es = {
		firstDayOfWeek: 0,
		dayNames: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sábado'],
		dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
		dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
		monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio',
			'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
		monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
		today: 'Hoy',
		clear: 'Límpar'
	};

	calendarLang = this.pt;

	statusContract = [
		{ label: 'TRANSLATE.ACTIVE', value: 5 },
		{ label: 'TRANSLATE.BLOCKED', value: 10 },
		{ label: 'TRANSLATE.CANCELED', value: 15 },
		{ label: 'TRANSLATE.FINISHED', value: 20 },
		{ label: 'TRANSLATE.CREATED', value: 25 },
		{ label: 'TRANSLATE.PENDING.PAYMENT', value: 30 },
		{ label: 'TRANSLATE.PENDING.CANCELLATION', value: 55 },
		{ label: 'TRANSLATE.PENDING.ACTIVATION', value: 60 },
		{ label: 'TRANSLATE.PENDING.AMENDMENT', value: 65 },
		{ label: 'TRANSLATE.WITH.ERROR', value: 95 }
	];

	filterContractSearchResult: FilterContractSearchResult;
	isPermissionButtonEditDisabled: boolean = true;
	isEditarDisabled: boolean = true;
	isButtonSaveDisabled: boolean = true;
	isEditBlockDisabled: boolean = true;
	countCaracteresTextArea: number = 0;
	contractEdit: ContractEdit;
	isSubmitted: boolean = false;
	blockedUi: boolean = false;
	salesTypeFords: Array<SalesTypeFord> = [];
	salesTypeFord: SalesTypeFord;
	validateEmail: boolean = true;
	descriptionOptional: string;

	constructor(private translateService: TranslateService, private messageService: MessageService,
		private router: Router, private apsPermissionService: ApsPermissionService,
		private contractService: ContractService, private fb: FormBuilder) {

		this.filterContractSearchResult = new FilterContractSearchResult();
	}

	ngOnInit() {

		this.apsPermissionService.permissionValidation(ApsPermitedScreen.screenContractView);
		this.isPermissionButtonEditDisabled = this.apsPermissionService.isPermissionValidationDisabled(ApsPermitedScreen.screenContractEdit);
		this.searchContract();

		this.translateService.onLangChange.subscribe((langChange: any) => {
			switch (langChange.lang) {
				case "es-CL" || "es-CO":
					this.calendarLang = this.es;
					this.searchSalesTypeFord();
					break;
				case "pt-BR":
					this.calendarLang = this.pt;
					this.searchSalesTypeFord();

					break;
				/*case "en-US":
					this.calendarLang = this.en;
					break;*/
			}
		});
	}

	private searchContract() {
		if (this.contractService.getFilterContractSearchResultSimplified() != null) {

			let contractId: number = this.contractService.getFilterContractSearchResultSimplified().contractKey;
			let productId: number = this.contractService.getFilterContractSearchResultSimplified().productKey;
			let coverageCategoryId: number = this.contractService.getFilterContractSearchResultSimplified().coverageCategoryKey;
			let country: string = this.contractService.getFilterContractSearch().country;

			this.searchContractId(contractId, productId, coverageCategoryId);
		}
	}

	private convertDateNeverTimezone(date: Date): Date {
		return new Date(date + 'T00:00:00.00');
	}

	textAreaCounterCaracteres() {
		if (this.filterContractSearchResult.contractRemarks == null || this.filterContractSearchResult.contractRemarks == '') {
			this.countCaracteresTextArea = 0;
		}
		else {
			this.countCaracteresTextArea = this.filterContractSearchResult.contractRemarks.length;
		}
	}

	buttonComeBack() {
		this.router.navigate(['contract-search']);
	}

	buttonEditarEnabled() {
		this.isEditarDisabled = false;
		this.isButtonSaveDisabled = false;
	}

	buttonEditDisabled() {
		this.isEditarDisabled = true;
		this.isButtonSaveDisabled = true;
	}

	buttonSaveEdit() {

		if (this.validateEditForm()) {
			this.isSubmitted = true;
			this.blockedUi = true;

			this.contractService.contractEditPost(this.createObjectContractEdit())
				.subscribe((response: boolean) => {
					if (response) {

						this.messageService.add({
							severity: 'success',
							summary: this.translateService.instant('TRANSLATE.SUCCESS'),
							detail: this.translateService.instant('TRANSLATE.CONTRACT.SUCCESSFULLY.SAVED')
						});
					}
					else {

						this.messageService.add({
							severity: 'error',
							summary: this.translateService.instant('TRANSLATE.ERROR'),
							detail: this.translateService.instant('TRANSLATE.COULD.NOT.SAVE.CONTRACT')
						});
					}

					this.searchContract();

					this.blockedUi = false;
					this.isSubmitted = false;
					this.buttonEditDisabled();
				},
					(err) => {

						if (err.status === 400 || err.status === 404 || err.status === 500) {

							this.messageService.add({
								severity: 'error',
								summary: this.translateService.instant('TRANSLATE.ERROR'),
								detail: this.translateService.instant('TRANSLATE.CONTRACT.CANNOT.BE.SAVE.CONTACT.ADM')
							});

						} else if (err.status === 401) {

							this.messageService.add({
								severity: 'error',
								summary: this.translateService.instant('TRANSLATE.ERROR'),
								detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
							});

						} else {

							this.messageService.add({
								severity: 'error',
								summary: this.translateService.instant('TRANSLATE.ERROR'),
								detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
							});
						}

						this.buttonEditDisabled();
						this.isSubmitted = true;
						this.blockedUi = false;
					});
		}
	}

	private createObjectContractEdit(): ContractEdit {

		this.contractEdit = new ContractEdit();
		this.contractEdit.csId = this.apsPermissionService.getUserCsid();
		this.contractEdit.contractCodeFp2 = this.filterContractSearchResult.contractCodeFp2;
		this.contractEdit.contractRemarks = this.filterContractSearchResult.contractRemarks;
		this.contractEdit.contractStatusCode = this.filterContractSearchResult.contractStatusCode;
		this.contractEdit.country = this.filterContractSearchResult.country;
		this.contractEdit.coverageCategory = this.filterContractSearchResult.coverageCategory;
		this.contractEdit.coverageCode = this.filterContractSearchResult.coverageCode;
		this.contractEdit.coverageStartDate = this.filterContractSearchResult.coverageStartDate;
		this.contractEdit.coverageEndDate = this.filterContractSearchResult.coverageEndDate;
		this.contractEdit.operation = this.filterContractSearchResult.operation;
		this.contractEdit.vin = this.filterContractSearchResult.vin;
		this.contractEdit.vinKey = this.filterContractSearchResult.vinKey;
		this.contractEdit.coverageKey = this.filterContractSearchResult.coverageKey;
		this.contractEdit.productCode = this.filterContractSearchResult.productCode;
		this.contractEdit.productKey = this.filterContractSearchResult.productKey;
		this.contractEdit.email = this.filterContractSearchResult.customerEmail;

		if (this.filterContractSearchResult.coverageStartVisit >= 1) {
			this.contractEdit.coverageStartVisit = this.filterContractSearchResult.coverageStartVisit;
		}

		if (this.filterContractSearchResult.coverageKm !== null && this.filterContractSearchResult.coverageKm >= 0) {
			this.contractEdit.coverageKm = this.filterContractSearchResult.coverageKm;
		}

		if (this.filterContractSearchResult.coverageVisitCounter !== null && this.filterContractSearchResult.coverageVisitCounter >= 0) {
			this.contractEdit.coverageVisitCounter = this.filterContractSearchResult.coverageVisitCounter;
		}

		return this.contractEdit;
	}

	private searchContractId(contractId: number, productId: number, coverageCategoryId: number) {

		this.contractService.searchContractId(contractId, productId, coverageCategoryId)
			.subscribe((response: FilterContractSearchResult) => {
				this.filterContractSearchResult = response;
				this.textAreaCounterCaracteres();
				this.validationDate();
				this.searchSalesTypeFord();
				this.salesTypeFord = this.salesTypeFords.find(salesTypeFord => salesTypeFord.id == this.filterContractSearchResult.contractTypeCode);
			},
				(err) => {

					if (err.status === 400 || err.status === 404 || err.status === 500) {

						this.messageService.add({
							severity: 'error',
							summary: this.translateService.instant('TRANSLATE.ERROR'),
							detail: this.translateService.instant('TRANSLATE.INVALID.CONTRACT.SEARCH.CONTACT.ADM')
						});

					} else if (err.status === 401) {

						this.messageService.add({
							severity: 'error',
							summary: this.translateService.instant('TRANSLATE.ERROR'),
							detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
						});

					} else {

						this.messageService.add({
							severity: 'error',
							summary: this.translateService.instant('TRANSLATE.ERROR'),
							detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
						});
					}

				});
	}

	private validationDate() {
		if (this.filterContractSearchResult.contractDateCreate != null) {
			this.filterContractSearchResult.contractDateCreate = this.convertDateNeverTimezone(this.filterContractSearchResult.contractDateCreate);
		}

		if (this.filterContractSearchResult.contractDateEnd != null) {
			this.filterContractSearchResult.contractDateEnd = this.convertDateNeverTimezone(this.filterContractSearchResult.contractDateEnd);
		}

		if (this.filterContractSearchResult.contractDateUpdate != null) {
			this.filterContractSearchResult.contractDateUpdate = this.convertDateNeverTimezone(this.filterContractSearchResult.contractDateUpdate);
		}

		if (this.filterContractSearchResult.wsd != null) {
			this.filterContractSearchResult.wsd = this.convertDateNeverTimezone(this.filterContractSearchResult.wsd);
		}

		if (this.filterContractSearchResult.warrantyEndDate != null) {
			this.filterContractSearchResult.warrantyEndDate = this.convertDateNeverTimezone(this.filterContractSearchResult.warrantyEndDate);
		}

		if (this.filterContractSearchResult.vinUpdateDate != null) {
			this.filterContractSearchResult.vinUpdateDate = this.convertDateNeverTimezone(this.filterContractSearchResult.vinUpdateDate);
		}

		if (this.filterContractSearchResult.vinSaleDate != null) {
			this.filterContractSearchResult.vinSaleDate = this.convertDateNeverTimezone(this.filterContractSearchResult.vinSaleDate);
		}

		if (this.filterContractSearchResult.vinStartDate != null) {
			this.filterContractSearchResult.vinStartDate = this.convertDateNeverTimezone(this.filterContractSearchResult.vinStartDate);
		}

		if (this.filterContractSearchResult.vinEndDate != null) {
			this.filterContractSearchResult.vinEndDate = this.convertDateNeverTimezone(this.filterContractSearchResult.vinEndDate);
		}

		if (this.filterContractSearchResult.coverageStartDate != null) {
			this.filterContractSearchResult.coverageStartDate = this.convertDateNeverTimezone(this.filterContractSearchResult.coverageStartDate);
		}

		if (this.filterContractSearchResult.coverageEndDate != null) {
			this.filterContractSearchResult.coverageEndDate = this.convertDateNeverTimezone(this.filterContractSearchResult.coverageEndDate);
		}

		if (this.filterContractSearchResult.coverageUpdateDate != null) {
			this.filterContractSearchResult.coverageUpdateDate = this.convertDateNeverTimezone(this.filterContractSearchResult.coverageUpdateDate);
		}

		if (this.filterContractSearchResult.vinActualEndDate != null) {
			this.filterContractSearchResult.vinActualEndDate = this.convertDateNeverTimezone(this.filterContractSearchResult.vinActualEndDate);
		}
	}

	rangeDateLabel(): string {
		let date: Date = new Date();
		let dateLabelYear: number = date.getFullYear() + 50;
		return '1970:' + dateLabelYear;
	}

	searchSalesTypeFord() {
		this.contractService.searchSalesTypeFord()
			.subscribe((response: Array<SalesTypeFord>) => {

				this.salesTypeFords = response;
				this.translateSalesTypeFords(this.salesTypeFords);
			},
				(err) => {
					if (err.status == 500) {
						this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Tipo de venda não encontrado.' });
					}
				});
	}

	private translateSalesTypeFords(response: SalesTypeFord[]): SalesTypeFord[] {

		for (var i = 0; i < response.length; i++) {

			switch (response[i].description) {

				case "Varejo":
					response[i].description = this.translateService.instant('TRANSLATE.RETAIL');
					break;

				case "Direta":
					response[i].description = this.translateService.instant('TRANSLATE.DIRECT');
					break;

				case "Governo":
					response[i].description = this.translateService.instant('TRANSLATE.FLEET');
					break;

				case "Free Contract":
					response[i].description = this.translateService.instant('TRANSLATE.FREE.CONTRACT');
					break;

				case "Frota Interna":
					response[i].description = this.translateService.instant('TRANSLATE.INTERNAL.FLEET');
					break;
			}
		}

		return response;
	}

	private isEmail(search: string): boolean {
		var searchfind: boolean;
		const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
		searchfind = regexp.test(search);
		return searchfind;
	}

	validateEditForm(): boolean {
		var isValid = true;

		if (this.isEmail(this.filterContractSearchResult.customerEmail) == false) {

			this.messageService.add({
				severity: 'error',
				summary: this.translateService.instant('TRANSLATE.ERROR'),
				detail: this.translateService.instant('TRANSLATE.INVALID.EMAIL.PLEASE.FILL.IN.CORRECTLY')
			});

			isValid = false;
		}
		else if (this.filterContractSearchResult.contractRemarks == "" || this.filterContractSearchResult.contractRemarks == null) {

			this.messageService.add({
				severity: 'error',
				summary: this.translateService.instant('TRANSLATE.ERROR'),
				detail: this.translateService.instant('TRANSLATE.CONTRACT.REMARKS.FIELD.REQUIRED.PLEASE.CORRECTLY'),
			});

			isValid = false;
		}

		return isValid;
	}

}
