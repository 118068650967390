import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConstantsFordProtect } from "src/app/models/constants/constants-ford-protect";
import { ApsPermissionService } from "../aps-permission/aps-permission.service";

const httpOptions = {
	headers: new HttpHeaders({
        'Content-Type': 'application/json'
	})
};

@Injectable({
  providedIn: 'root'
})
export class SystemCountryService {

    private userCountry: string;

    constructor(private httpClient: HttpClient,
        private apsPermissionService: ApsPermissionService) {
    }

	setSystemCountry()   {
		let country: string = this.apsPermissionService.getUserCountry();        
		if (sessionStorage.getItem(ConstantsFordProtect.SYSTEM_COUNTRY) != null) {
			country = sessionStorage.getItem(ConstantsFordProtect.SYSTEM_COUNTRY);			
		}
		this.userCountry = country;
	} 
    
    getSystemCountry(): string {
		return this.userCountry;
	}	
   
}
