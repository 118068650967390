import { CustomerFull } from "../customer/customer -full";
import { DealerFull } from "../dealer/dealerFull";
import { FileFull } from "../files/file-full";
import { PaymentFull } from "../payments/payment-full";
import { ProductFull } from "../product/product-full";
import { SellerFull } from "../seller/sellerFull";
import { VinFull } from "../vin/vin-full";

export class ContractFull {
	contractId: number;
	country: string;
  	operation: string;
	status: number;
	user: string;
	saleDate: Date;
	startDate: Date;
	saleType: number;
	productPolicyId: number;
	finalPrice: number;
	odometerStart: number;

	vin: VinFull =  new VinFull();
	products: Array<ProductFull> = [];
	dealers: DealerFull = new DealerFull();
	sellers: SellerFull = new SellerFull();
	payments: PaymentFull = new PaymentFull();
	files: FileFull = new FileFull();
  	customers: CustomerFull = new CustomerFull;
  	
	constructor() {
	}
}