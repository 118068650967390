import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng';
import { StatusChassi } from 'src/app/models/enum/status-chassi';
import { ClientDataService } from 'src/app/services/client-data/client-data.service';
import { VehicleInformationService } from 'src/app/services/vehicle-information/vehicle-information.service';
import { ChassiValidation } from "src/app/models/chassi-validation/chassi-validation";
import { ClientData } from 'src/app/models/client-data/client-data';
import { ContractSummary } from 'src/app/models/contract-summary/contract-summary';
import { ContractSummaryService } from 'src/app/services/contract-summary/contract-summary.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ChoosePlanService } from 'src/app/services/choose-plan/choose-plan.service';
import { ChoosePlanBuyng } from 'src/app/models/choose-plan/choose-plan-buyng';
import { ApsPermissionService } from 'src/app/services/aps-permission/aps-permission.service';
import { ApsPermitedScreen } from 'src/app/models/aps-permission/aps-permited-screen';
import { Dealer } from 'src/app/models/dealer/dealer';
import { DealerSeller } from 'src/app/models/dealer/dealer-seller';
import { DealerService } from 'src/app/services/dealer/dealer.service';
import { DealerApsFordDetailAttributes } from 'src/app/models/dealer/dealer-aps-ford-detail-attributes';
import { ContractPendingPayment } from 'src/app/models/contract/contract-pending-payment';
import { ProductFull } from 'src/app/models/product/product-full';

@Component({
	selector: 'app-contract-summary-external',
	templateUrl: './contract-summary-external.component.html',
	styleUrls: ['./contract-summary-external.component.scss']
})
export class ContractSummaryExternalComponent implements OnInit {

	userform: FormGroup;
	chassiValidation: ChassiValidation;
	clientData: ClientData;
	contractSummary: ContractSummary;
	choosePlanBuyng: ChoosePlanBuyng;
	cdsid: string = sessionStorage.getItem('userId');
	saleChannel: string;
	isSubmitted: boolean = false;
	blockedUi: boolean = false;

	constructor(private contractSummaryService: ContractSummaryService,
		private vehicleInformationService: VehicleInformationService,
		private clientDataService: ClientDataService,
		private translateService: TranslateService,
		private messageService: MessageService,
		private router: Router,
		private route: ActivatedRoute,
		private fb: FormBuilder,
		private choosePlanService: ChoosePlanService,
		private apsPermissionService: ApsPermissionService,
		private dealerService: DealerService) {

		this.contractSummary = new ContractSummary();
		this.choosePlanBuyng = new ChoosePlanBuyng();
		this.saleChannel = route.snapshot.paramMap.get('saleChannel');
	}

	ngOnInit() {
		this.chassiValidation = this.vehicleInformationService.getAllChassiValidation();
		this.clientData = this.clientDataService.getClientData();

		if (this.chassiValidation == null || this.chassiValidation.validated == StatusChassi.invalid ||
			this.clientDataService.getClientData() === null || this.clientData === null ||
			this.choosePlanService.getChoosePlanBuyng() == null) {
			this.router.navigate(['sales-channel', this.saleChannel]);
		}

		this.getSellerAutomatic();
		this.getDealerAutomatic();

		this.userform = this.fb.group({
			'dealerCodigo': new FormControl('', Validators.required),
			'cdsidSeller': new FormControl('', Validators.required),
			// 'cpfSeller': new FormControl('', Validators.required),
			'nameSeller': new FormControl('', Validators.required),
			'foneSeller': new FormControl('', Validators.required),
			'emailSeller': new FormControl('', [Validators.required, Validators.email]),
			'dealerEmail': new FormControl('', [Validators.required, Validators.email])
		});

		this.apsPermissionService.permissionValidation(ApsPermitedScreen.screenContractReview);

		if (this.contractSummaryService.getContractSummary() != null) {
			this.contractSummary = this.contractSummaryService.getContractSummary();
		}

		if (this.choosePlanService.getChoosePlanBuyng() != null) {
			this.choosePlanBuyng = this.choosePlanService.getChoosePlanBuyng();
		}
	}

	buttonComeBack() {
		this.router.navigate(['client-data', this.saleChannel]);
	}

	getDealer(event: KeyboardEvent) {
		this.contractSummary.dealer.geoSalesCode = 'BRA';

		this.dealerService.getDealer(this.contractSummary.dealer)
			.subscribe((response: Dealer) => {
				this.contractSummary.dealer = response;
				if (this.contractSummary.dealer == null) {
					this.messageService.add({ severity: 'error', summary: this.translateService.instant('TRANSLATE.ERROR'), detail: this.translateService.instant('TRANSLATE.DEALER.NOT.FOUND') });
				}
			}, (err) => {

				if (err.status === 404) {

					this.messageService.add({
						severity: 'error',
						summary: this.translateService.instant('TRANSLATE.ERROR'),
						detail: this.translateService.instant('TRANSLATE.DEALER.NOT.FOUND')
					});

				} else if (err.status === 401) {

					this.messageService.add({
						severity: 'error',
						summary: this.translateService.instant('TRANSLATE.ERROR'),
						detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
					});

				} else {

					this.messageService.add({
						severity: 'error',
						summary: this.translateService.instant('TRANSLATE.ERROR'),
						detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
					});
				}

			});
	}

	getDealerSeller(event: KeyboardEvent) {
		this.dealerService.getDealerSeller(this.contractSummary.dealerSeller)
			.subscribe((response: DealerSeller) => {
				this.contractSummary.dealerSeller = response;
				if (this.contractSummary.dealerSeller == null) {
					this.messageService.add({
						severity: 'error',
						summary: this.translateService.instant('TRANSLATE.ERROR'),
						detail: this.translateService.instant('TRANSLATE.SELLER.NOT.FOUND')
					});
				}

			}, (err) => {

				if (err.status === 404) {

					this.messageService.add({
						severity: 'error',
						summary: this.translateService.instant('TRANSLATE.ERROR'),
						detail: this.translateService.instant('TRANSLATE.SELLER.NOT.FOUND')
					});

				} else if (err.status === 401) {

					this.messageService.add({
					 severity: 'error',
					 summary: this.translateService.instant('TRANSLATE.ERROR'),
					 detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
					});
			 
			 } else {
			 
				 this.messageService.add({
					 severity: 'error',
					 summary: this.translateService.instant('TRANSLATE.ERROR'),
					 detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
				 });
			 }

			});
	}

	getSellerAutomatic() {
		this.contractSummary.dealerSeller.sellerCode = this.cdsid;
		this.dealerService.getDealerSeller(this.contractSummary.dealerSeller)
			.subscribe((response: DealerSeller) => {
				this.contractSummary.dealerSeller = response;
			}, (err) => {

				if (err.status === 404) {

					this.messageService.add({
						severity: 'error',
						summary: this.translateService.instant('TRANSLATE.ERROR'),
						detail: this.translateService.instant('TRANSLATE.SELLER.NOT.FOUND')
					});
					
				} else if (err.status === 401) {

					this.messageService.add({
					 severity: 'error',
					 summary: this.translateService.instant('TRANSLATE.ERROR'),
					 detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
					});
			 
			 } else {
			 
				 this.messageService.add({
					 severity: 'error',
					 summary: this.translateService.instant('TRANSLATE.ERROR'),
					 detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
				 });
			 }

			});
	}

	/*
	* captura o cdsid que está na sessão;
	* informa ele no serviço de DLS;
	* se houver um response, verifica se tem um sitecode e atribui;
	* se não houver response, atribui o site code 00100 (FORD MOTOR COMPANY BRASIL LTDA) automaticamente;
	*/
	getDealerAutomatic() {
		this.contractSummary.dealerSeller.name = this.cdsid;
		this.dealerService.getDealerName(this.contractSummary.dealerSeller).subscribe((response: DealerApsFordDetailAttributes) => {
			if (response == null) {
				this.contractSummary.dealer.pandAC = "00100";
			} else {
				this.contractSummary.dealer.pandAC = response.attributeValue;
			}
			this.contractSummary.dealer.geoSalesCode = 'BRA';
			this.dealerService.getDealer(this.contractSummary.dealer)
				.subscribe((response: Dealer) => {
					this.contractSummary.dealer = response;
				});
		});
	}

	save(): void {
		this.isSubmitted = true;
		this.blockedUi = true;

		this.contractSummaryService.saveContractPendingPayment(this.setContractPendingPayment()).subscribe((response: ContractPendingPayment) => {
			this.router.navigate(['home']);
			this.messageService.add({ severity: 'success', summary: this.translateService.instant('TRANSLATE.SUCCESS'), detail: this.translateService.instant('TRANSLATE.PAYMENT.PENDING') })
			this.chassiValidation = new ChassiValidation();
			this.choosePlanBuyng = new ChoosePlanBuyng();
			this.clientData = new ClientData();
			this.contractSummary = new ContractSummary();

			this.blockedUi = false;
			this.isSubmitted = false;

		}, (err) => {
			
			if (err.status === 400 || err.status === 404 || err.status === 500) {
				this.messageService.add({ 
					severity: 'error', 
					summary: this.translateService.instant('TRANSLATE.ERROR'), 
					detail: this.translateService.instant('TRANSLATE.CONTRACT.CANNOT.BE.SAVE.CONTACT.ADM') 
				});

			} else if (err.status === 401) {

				this.messageService.add({
				 severity: 'error',
				 summary: this.translateService.instant('TRANSLATE.ERROR'),
				 detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
				});
		 
		 } else {
		 
			 this.messageService.add({
				 severity: 'error',
				 summary: this.translateService.instant('TRANSLATE.ERROR'),
				 detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
			 });
		 }

			this.isSubmitted = false;
			this.blockedUi = false;
		});
	}

	private setContractPendingPayment(): ContractPendingPayment {
		let contract = new ContractPendingPayment();
		contract.country = "BRA";
		contract.operation = "CAR";
		contract.status = 5;
		contract.user = "EMISSAO";
		contract.saleDate = new Date();
		contract.startDate = this.contractStartDate();
		contract.saleType = 30; //Telemarketing
		contract.finalPrice = this.choosePlanBuyng.totalPrice;

		if (this.chassiValidation.odometro != null) {
			contract.odometerStart = this.chassiValidation.odometro;
		} else {
			contract.odometerStart = 1;
		}

		contract.vin.vin = this.chassiValidation.vin;
		contract.vin.cvvgDate = this.contractStartDate();
		contract.vin.factoryWarrantyEndDate = null;
		contract.vin.idVinCategoryVersion = this.chassiValidation.idVinCategoryVersion;

		contract.payments.totalValue = this.choosePlanBuyng.totalPrice;

		contract.dealers.code = this.contractSummary.dealer.dealerCode;
		contract.dealers.pAndAC = this.contractSummary.dealer.pandAC;
		contract.dealers.divisionCode = this.contractSummary.dealer.divisionCode;
		contract.dealers.oldRegionCode = this.contractSummary.dealer.oldRegionCode;
		contract.dealers.geoSalesCode = this.contractSummary.dealer.geoSalesCode;
		contract.dealers.subCode = this.contractSummary.dealer.dealerSubCode;
		contract.dealers.name = this.contractSummary.dealer.dealerName;
		contract.dealers.email = this.contractSummary.dealer.dealerEmail;
		contract.dealers.phone = this.contractSummary.dealer.dealerPhone;

		contract.sellers.code = this.contractSummary.dealerSeller.sellerCode;
		contract.sellers.name = this.contractSummary.dealerSeller.name;
		contract.sellers.phone = this.contractSummary.dealerSeller.phone;
		contract.sellers.email = this.contractSummary.dealerSeller.email;

		contract.customers.country = this.clientData.countryCode;
		contract.customers.documentType = this.nationalId(this.clientData.nationalId);
		contract.customers.documentNumber = this.clientData.nationalId;
		contract.customers.name = this.clientData.firstname;
		contract.customers.phone = this.clientData.phone1;
		contract.customers.email = this.clientData.email1;
		contract.customers.lastName = this.clientData.lastname;
		contract.customers.customerSocialName = this.clientData.customerSocialName;
		contract.customers.cep = this.clientData.zip;
		contract.customers.streetName = this.clientData.address;
		contract.customers.streetNumber = this.clientData.number;
		contract.customers.streetComplement = this.clientData.complement;
		contract.customers.district = this.clientData.district;
		contract.customers.state = this.clientData.stateCode;
		contract.customers.city = this.clientData.city;

		if (this.choosePlanBuyng.extension.coverageCategoryId > 0 && this.choosePlanBuyng.extension.productId > 0) {
			let productExtension = new ProductFull();
			productExtension.coverageId = this.choosePlanBuyng.extension.coverageCategoryId;
			productExtension.productId = this.choosePlanBuyng.extension.productId;
			productExtension.productPolicyId = this.choosePlanBuyng.extension.productPolicyKey;
			productExtension.priceBookId = this.choosePlanBuyng.extension.pricebookKey;
			contract.products.push(productExtension);
		}

		if (this.choosePlanBuyng.maintenance.coverageCategoryId > 0 && this.choosePlanBuyng.maintenance.productId > 0) {
			let productFullMaintenance = new ProductFull();
			productFullMaintenance.coverageId = this.choosePlanBuyng.maintenance.coverageCategoryId;
			productFullMaintenance.productId = this.choosePlanBuyng.maintenance.productId;
			productFullMaintenance.productPolicyId = this.choosePlanBuyng.maintenance.productPolicyKey;
			productFullMaintenance.priceBookId = this.choosePlanBuyng.maintenance.pricebookKey;
			contract.products.push(productFullMaintenance);
		}

		if (this.choosePlanBuyng.part.coverageCategoryId > 0 && this.choosePlanBuyng.part.productId > 0) {
			let productFullPart = new ProductFull();
			productFullPart.coverageId = this.choosePlanBuyng.part.coverageCategoryId;
			productFullPart.productId = this.choosePlanBuyng.part.productId;
			productFullPart.productPolicyId = this.choosePlanBuyng.part.productPolicyKey;
			productFullPart.priceBookId = this.choosePlanBuyng.part.pricebookKey;
			contract.products.push(productFullPart);
		}

		return contract;
	}

	contractStartDate(): Date {
		let date = new Date();
		if (this.chassiValidation.warrantyStartDate != null) {
			date = this.chassiValidation.warrantyStartDate;
		}
		return date;
	}

	nationalId(cpfCnpj: string): string {
		if (cpfCnpj.length == 11) {
			return 'PF';
		} else {
			return 'PJ';
		}
	}

}