import { PayerBoletoRequestDto } from "./payer-boleto-request-dto";

export class CreateBoletoRequestDto {

    dueDate: string;
    contractId: number;
    nossoNumero: number;
    payer: PayerBoletoRequestDto = new PayerBoletoRequestDto;    
    dealerCode: number;
    price: number;
    reason: string;
    type: number;

    constructor() {
    }
}