import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SignaturePad } from 'angular2-signaturepad';
import { MessageService } from 'primeng/api';
import { ApsPermitedScreen } from 'src/app/models/aps-permission/aps-permited-screen';
import { ChassiValidation } from "src/app/models/chassi-validation/chassi-validation";
import { ChoosePlanBuyng } from 'src/app/models/choose-plan/choose-plan-buyng';
import { ClientData } from 'src/app/models/client-data/client-data';
import { ContractSummary } from 'src/app/models/contract-summary/contract-summary';
import { ContractFull } from 'src/app/models/contract/contract-full';
import { DownloadFile } from 'src/app/models/contract/download-file';
import { DealerCountryDto } from 'src/app/models/dealer/dealer-country-dto';
import { LanguageDealer } from 'src/app/models/enum/language-dealer';
import { StatusChassi } from 'src/app/models/enum/status-chassi';
import { ProductFull } from 'src/app/models/product/product-full';
import { ApsPermissionService } from 'src/app/services/aps-permission/aps-permission.service';
import { ChoosePlanService } from 'src/app/services/choose-plan/choose-plan.service';
import { ClientDataService } from 'src/app/services/client-data/client-data.service';
import { ContractSummaryService } from 'src/app/services/contract-summary/contract-summary.service';
import { ContractService } from 'src/app/services/contract/contract.service';
import { DealerService } from 'src/app/services/dealer/dealer.service';
import { MessageExceptionService } from 'src/app/services/message-exception/message-exception.service';
import { SystemCountryService } from 'src/app/services/system-country/system-country.service';
import { VehicleInformationService } from 'src/app/services/vehicle-information/vehicle-information.service';

@Component({
	selector: 'app-contract-summary-attachment-two',
	templateUrl: './contract-summary-attachment-two.component.html',
	styleUrls: ['./contract-summary-attachment-two.component.scss']
})
export class ContractSummaryAttachmentTwoComponent implements OnInit {

	isSubmitted = false;
	blockedUi = false;
	isContractCreated: boolean = false;
	contractIdDownload: number = 0;

	contractForm: FormGroup;
	submitted = false;
	invalidSignature = true;
	signatureImgBase64: string;
	chassiValidation: ChassiValidation;
	clientData: ClientData;
	contractSummary: ContractSummary;
	termsUser: boolean = false;
	dateAttachment: Date = new Date();
	choosePlanBuyng: ChoosePlanBuyng;
	@ViewChild(SignaturePad) signaturePad: SignaturePad;
	saleChannel: string;
	dealerCountry: DealerCountryDto;
	dealerName: string = sessionStorage.getItem('userId');
	lang: string;

	constructor(private contractSummaryService: ContractSummaryService,
		private vehicleInformationService: VehicleInformationService,
		private clientDataService: ClientDataService,
		private translateService: TranslateService,
		private messageService: MessageService,
		private router: Router,
		private route: ActivatedRoute,
		private fb: FormBuilder,
		private apsPermissionService: ApsPermissionService,
		private choosePlanService: ChoosePlanService,
		private messageExceptionService: MessageExceptionService,
		private contractService: ContractService,
		private dealerService: DealerService,
		private systemCountryService: SystemCountryService
	) {
		this.choosePlanBuyng = new ChoosePlanBuyng;
		this.saleChannel = route.snapshot.paramMap.get('saleChannel');
		this.systemCountryService.setSystemCountry();

	}

	private signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
		'minWidth': 1,
		'canvasWidth': 600,
		'canvasHeight': 100,
	};

	ngAfterViewInit() {
		this.signaturePad.set('minWidth', 1);
		this.signaturePad.clear();
	}

	drawComplete() {
		this.invalidSignature = this.validateSignature();
	}

	drawStart() {
	}

	drawClean() {
		this.signaturePad.clear();
		this.invalidSignature = this.validateSignature();
	}

	ngOnInit() {
		this.chassiValidation = this.vehicleInformationService.getAllChassiValidation();
		this.clientData = this.clientDataService.getClientData();
		this.contractSummary = this.contractSummaryService.getContractSummary();

		if (this.chassiValidation == null || this.chassiValidation.validated == StatusChassi.invalid ||
			this.clientDataService.getClientData() === null || this.clientData === null ||
			this.contractSummary === null) {
			this.router.navigate(['sales-channel', this.saleChannel], { skipLocationChange: true, replaceUrl: false });
		}

		this.apsPermissionService.permissionValidation(ApsPermitedScreen.screenSignature);

		if (this.choosePlanService.getChoosePlanBuyng() != null) {
			this.choosePlanBuyng = this.choosePlanService.getChoosePlanBuyng();
		}

		this.contractForm = this.fb.group({
			termsUser: [false, Validators.requiredTrue]
		});

		this.getDealerCountry();
	}

	get f() {
		return this.contractForm.controls;
	}

	getDay(): number {
		return this.dateAttachment.getDate();
	}

	getMonth(): string {
		var numberMonth = this.dateAttachment.getMonth();
		let nameMonth = '';

		switch (numberMonth) {
			case 0:
				nameMonth = "Janeiro";
				break;
			case 1:
				nameMonth = "Fevereiro";
				break;
			case 2:
				nameMonth = "Março";
				break;
			case 3:
				nameMonth = "Abril";
				break;
			case 4:
				nameMonth = "Maio";
				break;
			case 5:
				nameMonth = "Junho";
				break;
			case 6:
				nameMonth = "Julho";
				break;
			case 7:
				nameMonth = "Agosto";
				break;
			case 8:
				nameMonth = "Setembro";
				break;
			case 9:
				nameMonth = "Outubro";
				break;
			case 10:
				nameMonth = "Novembro";
				break;
			case 11:
				nameMonth = "Dezembro";
				break;
		}

		return nameMonth;
	}

	getYear(): number {
		return this.dateAttachment.getFullYear();
	}

	buttonNext() {
		this.submitted = true;
		if (this.contractForm.invalid) {
			return;
		}
		if (this.invalidSignature) {
			return;
		}
		this.setSignatureImgBase64();
		this.save();
	}

	buttonComeBack() {
		this.router.navigate(['contract-summary', this.saleChannel], { skipLocationChange: true, replaceUrl: false });
	}

	downloadPDF() {
		this.blockedUi = true;
		this.contractService.downloadFile(this.contractIdDownload)
			.subscribe((response: DownloadFile) => {
				const source = response.pdfByteArray;
				const link = document.createElement("a");
				link.href = source;
				link.download = `Termos do Contrato.pdf`
				link.click();

				this.blockedUi = false;
			}, (err) => {
				this.blockedUi = false;
				this.messageExceptionService.getMessageException(err.status, 'Arquivo não encontrado.');
			});
	}

	save(): void {
		this.isSubmitted = true;
		this.blockedUi = true;

		this.contractSummaryService.save(this.setContractFull(), this.setDealerLanguage())
			.subscribe((response: ContractFull) => {
				this.messageService.add({
					severity: 'success',
					summary: this.translateService.instant('TRANSLATE.SUCCESS'),
					detail: this.translateService.instant('TRANSLATE.CONTRACT.CREATED.SUCCESSFULL')
				});

				this.chassiValidation = new ChassiValidation();
				this.choosePlanBuyng = new ChoosePlanBuyng();
				this.clientData = new ClientData();
				this.contractSummary = new ContractSummary();

				this.contractIdDownload = response.contractId;
				this.isContractCreated = true;
				this.blockedUi = false;
				this.lang = "";
			}, (err) => {
				
				if (err.status === 400 || err.status === 404 || err.status === 500 ) {

					this.messageService.add({
						severity: 'error',
						summary: this.translateService.instant('TRANSLATE.ERROR'),
						detail: this.translateService.instant('TRANSLATE.CONTRACT.CANNOT.BE.SAVE.CONTACT.ADM')
					});
					
				} else if (err.status === 401) {

					this.messageService.add({
					 severity: 'error',
					 summary: this.translateService.instant('TRANSLATE.ERROR'),
					 detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
					});
			 
			 } else {
			 
				 this.messageService.add({
					 severity: 'error',
					 summary: this.translateService.instant('TRANSLATE.ERROR'),
					 detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
				 });
			 }
			 
				this.isContractCreated = false;
				this.isSubmitted = false;
				this.blockedUi = false;
			});
	}

	private setContractFull(): ContractFull {
		let contractFull = new ContractFull();
		contractFull.country = this.systemCountryService.getSystemCountry();
		contractFull.operation = "CAR";
		contractFull.status = 5;
		contractFull.user = "EMISSAO";
		contractFull.saleDate = new Date();
		contractFull.startDate = this.contractStartDate();
		contractFull.saleType = 5;
		contractFull.finalPrice = this.choosePlanBuyng.totalPrice;

		if (this.chassiValidation.odometro != null) {
			contractFull.odometerStart = this.chassiValidation.odometro;
		} else {
			contractFull.odometerStart = 1;
		}

		contractFull.vin.vin = this.chassiValidation.vin;
		contractFull.vin.cvvgDate = this.contractStartDate();
		contractFull.vin.factoryWarrantyEndDate = null;
		contractFull.vin.idVinCategoryVersion = this.chassiValidation.idVinCategoryVersion;

		contractFull.payments.method = "DEALER";
		contractFull.payments.modality = this.contractSummary.formaPayment.value;
		contractFull.payments.parcels = this.contractSummary.prazoPayment.value;
		contractFull.payments.totalValue = this.choosePlanBuyng.totalPrice

		contractFull.files.type = 1;
		contractFull.files.name = "Ford Protect Termo de Contratacao";
		contractFull.files.description = "TERMO DE CONTRATAÇÃO - GARANTIA ESTENDIDA REDUZIDA";
		contractFull.files.fileReference = "1";
		contractFull.files.file = this.signatureImgBase64;

		contractFull.dealers.code = this.contractSummary.dealer.dealerCode;
		contractFull.dealers.pAndAC = this.contractSummary.dealer.pandAC;
		contractFull.dealers.divisionCode = this.contractSummary.dealer.divisionCode;
		contractFull.dealers.oldRegionCode = this.contractSummary.dealer.oldRegionCode;
		contractFull.dealers.geoSalesCode = this.contractSummary.dealer.geoSalesCode;
		contractFull.dealers.subCode = this.contractSummary.dealer.dealerSubCode;
		contractFull.dealers.name = this.contractSummary.dealer.dealerName;
		contractFull.dealers.email = this.contractSummary.dealer.dealerEmail;
		contractFull.dealers.phone = this.contractSummary.dealer.dealerPhone;

		contractFull.sellers.code = this.contractSummary.dealerSeller.sellerCode;
		contractFull.sellers.name = this.contractSummary.dealerSeller.name;
		contractFull.sellers.phone = this.contractSummary.dealerSeller.phone;
		contractFull.sellers.email = this.contractSummary.dealerSeller.email;

		contractFull.customers.country = this.clientData.countryCode;
		contractFull.customers.documentType = this.nationalId(this.clientData.nationalId);
		contractFull.customers.documentNumber = this.clientData.nationalId;
		contractFull.customers.name = this.clientData.firstname;
		contractFull.customers.phone = this.clientData.phone1;
		contractFull.customers.email = this.clientData.email1;
		contractFull.customers.lastName = this.clientData.lastname;
		contractFull.customers.cep = this.clientData.zip;
		contractFull.customers.streetName = this.clientData.address;
		contractFull.customers.streetNumber = this.clientData.number;
		contractFull.customers.streetComplement = this.clientData.complement;
		contractFull.customers.district = this.clientData.district;
		contractFull.customers.state = this.clientData.stateCode;
		contractFull.customers.city = this.clientData.city;
		contractFull.customers.customerSocialName = this.clientData.customerSocialName;

		if (this.choosePlanBuyng.extension.coverageCategoryId > 0 && this.choosePlanBuyng.extension.productId > 0) {
			let productFullExtension = new ProductFull();
			productFullExtension.coverageId = this.choosePlanBuyng.extension.coverageCategoryId;
			productFullExtension.productId = this.choosePlanBuyng.extension.productId;
			productFullExtension.productPolicyId = this.choosePlanBuyng.extension.productPolicyKey;
			productFullExtension.priceBookId = this.choosePlanBuyng.extension.pricebookKey;
			contractFull.products.push(productFullExtension);
		}

		if (this.choosePlanBuyng.maintenance.coverageCategoryId > 0 && this.choosePlanBuyng.maintenance.productId > 0) {
			let productFullMaintenance = new ProductFull();
			productFullMaintenance.coverageId = this.choosePlanBuyng.maintenance.coverageCategoryId;
			productFullMaintenance.productId = this.choosePlanBuyng.maintenance.productId;
			productFullMaintenance.productPolicyId = this.choosePlanBuyng.extension.productPolicyKey;
			productFullMaintenance.priceBookId = this.choosePlanBuyng.extension.pricebookKey;
			productFullMaintenance.productPolicyId = this.choosePlanBuyng.maintenance.productPolicyKey;
			productFullMaintenance.priceBookId = this.choosePlanBuyng.maintenance.pricebookKey;
			contractFull.products.push(productFullMaintenance);
		}

		if (this.choosePlanBuyng.part.coverageCategoryId > 0 && this.choosePlanBuyng.part.productId > 0) {
			let productFullPart = new ProductFull();
			productFullPart.coverageId = this.choosePlanBuyng.part.coverageCategoryId;
			productFullPart.productId = this.choosePlanBuyng.part.productId;
			productFullPart.productPolicyId = this.choosePlanBuyng.part.productPolicyKey;
			productFullPart.priceBookId = this.choosePlanBuyng.part.pricebookKey;
			contractFull.products.push(productFullPart);
		}

		return contractFull;
	}

	nationalId(cpfCnpj: string): string {
		if (cpfCnpj.length == 11) {
			return 'PF';
		} else {
			return 'PJ';
		}
	}

	contractStartDate(): Date {
		let date = new Date();
		if (this.chassiValidation.warrantyStartDate != null) {
			date = this.chassiValidation.warrantyStartDate;
		}
		return date;
	}

	validateSignature(): boolean {
		let retorno = true;
		if (!this.signaturePad.isEmpty()) {
			retorno = false;
		}
		return retorno;
	}

	setSignatureImgBase64() {
		this.signatureImgBase64 = this.signaturePad.toDataURL();
	}


	buttonHome() {
		this.router.navigate(['home']);
	}

	/*
	 * Consulta no backend o país do dealer
	 */
	getDealerCountry() {
		this.dealerService.getDealerCountry(this.dealerName)
			.subscribe(response => {
				this.dealerCountry = response;
			}, (error) => {

				if (error.status === 404) {
					// Esse erro será analizado e tratado futuramente.
				} else if (error.status === 401) {

					this.messageService.add({
					 severity: 'error',
					 summary: this.translateService.instant('TRANSLATE.ERROR'),
					 detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
					});
			 
			 } else {
			 
				 this.messageService.add({
					 severity: 'error',
					 summary: this.translateService.instant('TRANSLATE.ERROR'),
					 detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
				 });
			 }

			});
	}

	/*
	 * Recupera o pais do usuario logado pelo token de acesso;
	 * Caso não encontre, recupera o país do SITECODE;
	 * Em ambos os casos, faz o parse do país do dealer com a tradução oficial (pt-BR, es-CL, es-CO etc)
	 */
	setDealerLanguage() {
		if (this.systemCountryService.getSystemCountry() != null) {
			this.lang = LanguageDealer[this.systemCountryService.getSystemCountry()];
		} else {
			this.lang = LanguageDealer[this.dealerCountry.countryAlternative];
		}
		return this.lang;
	}
}
