import { Permitted } from "./aps-permitted";

export class ApsPermission {
	
	Permitted: Array<Permitted> = [];
	
	constructor() {
		
	}
}
