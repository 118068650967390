import { CardPagarMe } from "./card-pagar-me";

export class CreditCardPagarMe {
	recurrence: boolean;
	installments: number;
	statement_descriptor: String;
	card: CardPagarMe;

	constructor() {
	
	}
}