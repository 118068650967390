import { EntryBankBoletoResponseDto } from "./entry-bank-boleto-response-dto";
import { PayerBoletoResponseDto } from "./payer-boleto-response-dto";

export class CreateBoletoResponseDto {

    id: string;
    contractId: number;
    createdBy: string;
    price: number;
    payer: PayerBoletoResponseDto = new PayerBoletoResponseDto;
    issueDate: string;
    dueDate: string;
    reason: string;
    code: string;
    status: string;    
    dealerCode: number;
    entryBank: EntryBankBoletoResponseDto = new EntryBankBoletoResponseDto;
    
    constructor() {
    }
}