import { ChargePagarMeResponse } from "./charges-pagar-me-response";

export class OrdersPagarMeResponsePix {

    id: string;
    status: string
    charges: Array<ChargePagarMeResponse> = [] ;

	constructor() {
	
	}
}