import { Component, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import { ApsPermissionService } from 'src/app/services/aps-permission/aps-permission.service';
import { SystemCountryService } from 'src/app/services/system-country/system-country.service';

@Component({
  selector: 'information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent implements OnInit {

  country: string;

  constructor(
    private apsPermissionService: ApsPermissionService,
    private systemCountryService: SystemCountryService
  ) {
    this.systemCountryService.setSystemCountry();
  }

  ngOnInit() {

    this.country = this.systemCountryService.getSystemCountry();
    this.apsPermissionService.getSearchApsPermission();
  }

  downloadPdf(pdfUrl: string, pdfName: string) {
		saveAs(pdfUrl, pdfName);
	}

}
