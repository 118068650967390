import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { LazyLoadEvent, MessageService } from 'primeng';
import { ApsPermitedScreen } from 'src/app/models/aps-permission/aps-permited-screen';
import { ConstantsFordProtect } from 'src/app/models/constants/constants-ford-protect';
import { FilterContractSearch } from 'src/app/models/contract/filter-contract-search';
import { FilterContractSearchResult } from 'src/app/models/contract/filter-contract-search-result';
import { FilterContractSearchResultSimplified } from 'src/app/models/contract/filter-contract-search-result-simplified';
import { FilterContractSearchResultTotalRecord } from 'src/app/models/contract/filter-contract-search-result-total-record';
import { SalesTypeFord } from 'src/app/models/contract/sales-type-ford';
import { ProductActive } from 'src/app/models/product/product-active';
import { ApsPermissionService } from 'src/app/services/aps-permission/aps-permission.service';
import { ContractService } from 'src/app/services/contract/contract.service';
import { ProductService } from 'src/app/services/product/product.service';

@Component({
	selector: 'app-contract-search',
	templateUrl: './contract-search.component.html',
	styleUrls: ['./contract-search.component.css']
})
export class ContractSearchComponent implements OnInit {

	pt = {
		firstDayOfWeek: 0,
		dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
		dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
		dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
		monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
			'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
		monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
		today: 'Hoje',
		clear: 'Limpar'
	};

	es = {
		firstDayOfWeek: 0,
		dayNames: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sábado'],
		dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
		dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
		monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio',
			'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
		monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
		today: 'Hoy',
		clear: 'Limpar'
	};

	calendarLang = this.pt;

	statusFilter = [
		{ label: 'TRANSLATE.SELECT', value: 0 },
		{ label: 'TRANSLATE.ACTIVE', value: 5 },
		{ label: 'Bloqueado', value: 10 },
		{ label: 'Cancelado', value: 15 },
		{ label: 'Finalizado', value: 20 },
		{ label: 'TRANSLATE.CREATED', value: 25 },
		{ label: 'TRANSLATE.PENDING.PAYMENT', value: 30 },
		{ label: 'TRANSLATE.PENDING.CANCELLATION', value: 55 },
		{ label: 'TRANSLATE.PENDING.ACTIVATION', value: 60 },
		{ label: 'TRANSLATE.PENDING.AMENDMENT', value: 65 },
		{ label: 'TRANSLATE.WITH.ERROR', value: 95 }
	];

	globalContractFilter = [
		{ label: 'TRANSLATE.SELECT', value: 0 },
		{ label: 'TRANSLATE.YES', value: 1 },
		{ label: 'TRANSLATE.NOT', value: 2 }
	];

	blockedUi: boolean = false;

	salesTypeFords: Array<SalesTypeFord> = [];
	salesTypeFord: SalesTypeFord;

	productActiveCountries: Array<ProductActive> = [];
	productActive: ProductActive;

	filterContractSearch: FilterContractSearch;
	filterContractSearchResultList: Array<FilterContractSearchResult> = [];
	filterContractSearchResultTotalRecord: FilterContractSearchResultTotalRecord;
	contractSearchTotalRecord: number;
	isSubmitted: boolean = false;
	isSubmittedExcel: boolean = false;
	isPermission: boolean = false;

	constructor(
		private translateService: TranslateService,
		private messageService: MessageService,
		private router: Router,
		private apsPermissionService: ApsPermissionService,
		private contractService: ContractService,
		private productService: ProductService
	) {

		this.filterContractSearch = new FilterContractSearch();
		this.filterContractSearchResultTotalRecord = new FilterContractSearchResultTotalRecord();
		this.buttonClean();
	}

	//Revisar aqui - Doble carga de SalesTypeFord dropdown
	ngOnInit() {
		this.isPermission = this.apsPermissionService.permissionValidation(ApsPermitedScreen.screenContractSearch);

		if (this.isPermission) {
			if (this.contractService.getFilterContractSearch() != null) {
				this.filterContractSearch = this.contractService.getFilterContractSearch();
				this.searchSalesTypeFord();
				this.searchProductActiveCountry();
				this.buttonFilterSearch();
			} else {
				this.searchProductActiveCountry();
			}
			this.productActive = this.addProductActiveDefault();
			this.salesTypeFord = this.addSalesTypeFordDefault();
			this.searchSalesTypeFord();
			this.searchProductActiveCountry();
		}

		this.translateService.onLangChange.subscribe((langChange: any) => {
			switch (langChange.lang) {
				case "es-CL" || "es-CL":
					this.calendarLang = this.es;
					break;
				case "pt-BR":
					this.calendarLang = this.pt;
					break;
			}
		})

	}

	countries() {

    return [
      { label: this.translateService.instant('TRANSLATE.ARGENTINA'), value: ConstantsFordProtect.ARG },
      { label: this.translateService.instant('TRANSLATE.BRAZIL'), value: ConstantsFordProtect.BRA },
      { label: this.translateService.instant('TRANSLATE.CHILE'), value: ConstantsFordProtect.CHL },
      { label: this.translateService.instant('TRANSLATE.COLOMBIA'), value: ConstantsFordProtect.COL }
    ];
  }

	filterValidation() {
		if (this.filterContractSearch.document === '' && this.filterContractSearch.vin === ''
			&& this.filterContractSearch.dealer === '' && this.filterContractSearch.contractOriginalNumber == null
			&& this.filterContractSearch.contractDateStart == null && this.filterContractSearch.contractDateEnd == null
			&& this.filterContractSearch.validityDateStart == null && this.filterContractSearch.validityDateEnd == null
			&& this.salesTypeFord.id == 0 && this.productActive.id == 0 && this.filterContractSearch.contractStatus == 0
			&& !this.filterContractSearch.countries && this.filterContractSearch.globalContract == 0) {
			this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Filtro não selecionado.' });
		} else if (!this.filterContractSearch.countries || this.filterContractSearch.countries.length == 0) {
			this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Selecione o(s) país(es).' });
		} else {
			this.buttonFilterSearch();
		}
	}

	buttonFilterSearch() {
		this.isSubmitted = true;
		this.blockedUi = true;
		this.filterContractSearch.salesTypeFordId = this.salesTypeFord.id;
		this.filterContractSearch.productId = this.productActive.id;

		this.contractService.contractSearch(this.filterContractSearch)
			.subscribe((response: FilterContractSearchResultTotalRecord) => {
				this.filterContractSearchResultTotalRecord = response;
				this.isSubmitted = false;
				this.blockedUi = false;
			}, (err) => {

				if (err.status === 401) {

					this.messageService.add({
						severity: 'error',
						summary: this.translateService.instant('TRANSLATE.ERROR'),
						detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
					});

				} else {

					this.messageService.add({
						severity: 'error',
						summary: this.translateService.instant('TRANSLATE.ERROR'),
						detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
					});
				}

				this.isSubmitted = false;
				this.blockedUi = false;
			});
	}

	loadData(event: LazyLoadEvent) {
		if (event.first > 0) {
			this.filterContractSearch.numberPage = (event.first / event.rows);
			this.filterValidationLazyLoad();
		} else {
			this.filterContractSearch.numberPage = event.first;
			this.filterValidationLazyLoad();
		}
	}

	filterValidationLazyLoad() {
		if (this.filterContractSearch.document === '' && this.filterContractSearch.vin === ''
			&& this.filterContractSearch.dealer === '' && this.filterContractSearch.contractOriginalNumber === ''
			&& this.filterContractSearch.contractDateStart == null && this.filterContractSearch.contractDateEnd == null
			&& this.filterContractSearch.validityDateStart == null && this.filterContractSearch.validityDateEnd == null
			&& this.salesTypeFord.id == 0 && this.productActive.id == 0 && this.filterContractSearch.contractStatus == 0
			// alterei
			&& !this.filterContractSearch.countries && this.filterContractSearch.globalContract == 0) {

		} else {
			this.buttonFilterSearch();
		}
	}

	buttonClean() {
		this.filterContractSearch = new FilterContractSearch();
		this.filterContractSearch.document = '';
		this.filterContractSearch.vin = '';
		this.filterContractSearch.dealer = '';
		this.filterContractSearch.countries = null;
		this.filterContractSearch.contractNumber = null;
		this.filterContractSearch.contractDateStart = null;
		this.filterContractSearch.contractDateEnd = null;
		this.filterContractSearch.globalContract = 0;
		this.filterContractSearch.contractStatus = 0;
		this.productActive = this.addProductActiveDefault();
		this.salesTypeFord = this.addSalesTypeFordDefault();
	}

	exportExcel() {
		if (this.filterContractSearch.document === '' && this.filterContractSearch.vin === ''
			&& this.filterContractSearch.dealer === '' && this.filterContractSearch.contractOriginalNumber === ''
			&& this.filterContractSearch.contractDateStart == null && this.filterContractSearch.contractDateEnd == null
			&& this.filterContractSearch.validityDateStart == null && this.filterContractSearch.validityDateEnd == null
			&& this.salesTypeFord.id <= 0 && this.productActive.id <= 0 && this.filterContractSearch.contractStatus <= 0
			&& !this.filterContractSearch.countries && this.filterContractSearch.globalContract == 0) {

			this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Filtro não selecionado.' });

		}
		else if (!this.filterContractSearch.countries || this.filterContractSearch.countries.length == 0) {

			this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Selecione o(s) país(es).' });

		}
		else {
			this.isSubmittedExcel = true;
			this.blockedUi = true;

			this.contractService.contractSearchExcel(this.filterContractSearch)
				.subscribe((response: Array<FilterContractSearchResult>) => {

					this.importXlsxFromArray(response);

					this.isSubmittedExcel = false;
					this.blockedUi = false;
				}, (err) => {
					if (err.status == 500) {
						this.messageService.add({
							severity: 'error', summary: this.translateService.instant('TRANSLATE.ERROR'),
							detail: this.translateService.instant('TRANSLATE.EXCEL.CANNOT.BE.EXPORTED')
						});
					}

					this.isSubmittedExcel = false;
					this.blockedUi = false;
				});
		}
	}

	private importXlsxFromArray(array: Array<FilterContractSearchResult>): void {
		import("xlsx").then(xlsx => {
			const worksheet = xlsx.utils.json_to_sheet(array);
			const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
			const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
			this.saveAsExcelFile(excelBuffer, "contracts");
		});
	}

	private saveAsExcelFile(buffer: any, fileName: string): void {
		let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
		let EXCEL_EXTENSION = '.xlsx';
		const data: Blob = new Blob([buffer], {
			type: EXCEL_TYPE
		});
		saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
	}

	buttonEdit(filterContractSearchResultSimplified: FilterContractSearchResultSimplified) {
		this.contractService.setFilterContractSearch(this.filterContractSearch);
		this.contractService.setFilterContractSearchResultSimplified(filterContractSearchResultSimplified);
		this.router.navigate(['contract-edit']);
	}

	statusName(statusId: number): string {
		return this.statusFilter.find(status => status.value == statusId).label;
	}

	private searchSalesTypeFord() {
		this.contractService.searchSalesTypeFord()
			.subscribe((response: Array<SalesTypeFord>) => {
				this.salesTypeFords.push(this.addSalesTypeFordDefault());
				this.salesTypeFords = this.translateSalesTypeFords(this.salesTypeFords.concat(response));


				if (this.filterContractSearch.salesTypeFordId > 0) {
					this.salesTypeFord = this.salesTypeFords.find(salesTypeFord => salesTypeFord.id == this.filterContractSearch.salesTypeFordId);
				} else {
					this.salesTypeFord = this.addSalesTypeFordDefault();
				}
			}
				, (err) => {
					if (err.status == 500) {
						this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Tipo de venda não encontrado.' });
					}
				});
	}

	private translateSalesTypeFords(salesTypeFordArray: SalesTypeFord[]): SalesTypeFord[] {

		for (var i = 0; i < salesTypeFordArray.length; i++) {
			switch (salesTypeFordArray[i].description) {
				case "Varejo":
					salesTypeFordArray[i].description = "TRANSLATE.RETAIL"
					break;
				case "Direta":
					salesTypeFordArray[i].description = "TRANSLATE.DIRECT"
					break;
				case "Governo":
					salesTypeFordArray[i].description = "TRANSLATE.FLEET"
					break;
				case "Free Contract":
					salesTypeFordArray[i].description = "TRANSLATE.FREE.CONTRACT"
					break;
				case "Frota Interna":
					salesTypeFordArray[i].description = "TRANSLATE.INTERNAL.FLEET"
					break;
			}
		}
		return salesTypeFordArray;
	}

	private addSalesTypeFordDefault(): SalesTypeFord {
		let salesTypeFord: SalesTypeFord = new SalesTypeFord();
		salesTypeFord.id = 0;
		salesTypeFord.description = 'TRANSLATE.SELECT';

		return salesTypeFord;
	}

	private searchProductActiveCountry() {

		if (this.filterContractSearch.countries != null) {
			let count = 0;

			if (this.filterContractSearch.countries.length > 0) {
				this.productService.getProductActiveCodeCountries(this.filterContractSearch.countries)
					.subscribe((response: Array<ProductActive>) => {

						this.productActiveCountries = response;
						if (count < 1) {
							this.productActiveCountries.push(this.addProductActiveDefault());
							//	repete os Ids nessa linha
							this.productActiveCountries = this.productActiveCountries.concat(response);
						}

						if (this.filterContractSearch.productId > 0) {
							this.productActive = this.productActiveCountries.find(product => product.id == this.filterContractSearch.productId);

						} else {
							if (count < 1) {
								this.productActive = this.addProductActiveDefault();
							}
						}
					}
						, (err) => {

							if (err.status == 400) {
								this.messageService.add({
									severity: 'error',
									summary: this.translateService.instant('TRANSLATE.ERROR'),
									detail: this.translateService.instant('TRANSLATE.PRODUCTS.NOT.FOUND')
								});
							} else if (err.status === 401) {

								this.messageService.add({
									severity: 'error',
									summary: this.translateService.instant('TRANSLATE.ERROR'),
									detail: this.translateService.instant('TRANSLATE.USER.WITHOUT.PERMISSION.REFRESH.PAGE')
								});

							} else {

								this.messageService.add({
									severity: 'error',
									summary: this.translateService.instant('TRANSLATE.ERROR'),
									detail: this.translateService.instant('TRANSLATE.ERROR.CONTACT.SYSTEM.ADMINISTRATOR')
								});
							}

						});
				count++;
			} else {
				this.productActiveCountries = [];
			}
		}
	}

	private addProductActiveDefault(): ProductActive {
		let productActive: ProductActive = new ProductActive();
		productActive.id = 0;
		productActive.description = 'Selecione';
		productActive.name = 'Selecione';
		productActive.code = 'Selecione';

		return productActive;
	}

	countryChange(event: any) {
		this.searchProductActiveCountry();
	}

	rangeDateLabel(): string {
		let date: Date = new Date();
		let dateLabelYear: number = date.getFullYear() + 50;
		return '1970:' + dateLabelYear;
	}

}