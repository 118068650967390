import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ContractSignature } from 'src/app/models/contract/contract-signature';
import { ContractService } from 'src/app/services/contract/contract.service';

@Component({
  selector: 'app-legal-text-contract-colombia',
  templateUrl: './legal-text-contract-colombia.component.html',
  styleUrls: ['./legal-text-contract-colombia.component.scss']
})
export class LegalTextContractColombiaComponent implements OnInit {

  contractSignature: ContractSignature;
  contract: number;
  document: string;
  month: string;
  country: string;
  uuid: string;

  constructor(
    private translateService: TranslateService,
    private contractService: ContractService,
    private activatedRoute: ActivatedRoute,
  ) {

    this.contractSignature = new ContractSignature;
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {

      this.uuid = params.uuid;
      this.contractSummarySearch(this.uuid);
    });

  }

  contractSummarySearch(uuid: string) {
    
    this.contractService
      .getContractSignature(uuid)
      .subscribe((response: ContractSignature) => {

        this.contractSignature = response;
        
        if (this.contractSignature.country === 'COL') {
          this.country = 'Colombia'
        }
        const salesDate = new Date(this.contractSignature.salesDate);
        const mes = salesDate.getMonth() + 1;

        switch (mes) {

          case 1:
              this.month = 'enero';
            break;

          case 2:
              this.month = 'fevereiro';
            break;

          case 3:
              this.month = 'marzo';
            break;

          case 4:
              this.month = 'abril';
            break;

          case 5:
              this.month = 'mayo';
            break;

          case 6:
              this.month = 'junio';
            break;

          case 7:
              this.month = 'julio';
            break;

          case 8:
              this.month = 'agosto';
            break;

          case 9:
              this.month = 'septiembre';
            break;

          case 10:
              this.month = 'octubre';
            break;

          case 11:
              this.month = 'noviembre';
            break;

          case 12:
              this.month = 'diciembre';
            break;
        }
      });
  }

}
