import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { ChoosePlan } from "src/app/models/choose-plan/choose-plan";
import { ChoosePlanBuyng } from "src/app/models/choose-plan/choose-plan-buyng";
import { ChoosePlanDuplicatedContract } from "src/app/models/choose-plan/choose-plan-duplicated-contract";
import { ChoosePlanFilter } from "src/app/models/choose-plan/choose-plan-filter";
import { ChoosePlanProduct } from "src/app/models/choose-plan/choose-plan-product";
import { environment } from "src/environments/environment";

const httpOptions = {
	headers: new HttpHeaders({
        'Content-Type': 'application/json'
	})
};

@Injectable({
  providedIn: 'root'
})
export class ChoosePlanService {

    private apiUrl = environment.apiUrl;
    private choosePlanBuyng: ChoosePlanBuyng;

    constructor(private httpClient: HttpClient, private messageService: MessageService) {
    }

    setChoosePlanBuyng(choosePlanBuyng: ChoosePlanBuyng) {
      this.choosePlanBuyng = choosePlanBuyng;
    }

    getChoosePlanBuyng(): ChoosePlanBuyng {
      return this.choosePlanBuyng;
    }

    getChoosePlanBuyngClean() {
      this.choosePlanBuyng = new ChoosePlanBuyng();
    }

    getChoosePlanProductList(choosePlanFilter: ChoosePlanFilter): Observable<ChoosePlan> {      
      return this.httpClient.post(`${this.apiUrl}/api/v1/products/policies/coverage-categories`, choosePlanFilter, httpOptions)
        .pipe(
          map((response: ChoosePlan) => {
            return response;
          })
        );
    }

    getChoosePlanDuplicatedContract(vin: string): Observable<ChoosePlanDuplicatedContract> {      
      return this.httpClient.get<ChoosePlanDuplicatedContract>(`${this.apiUrl}/api/products/verify/contract/duplicated/${vin}`)
      .pipe(
        map((response: ChoosePlanDuplicatedContract) => {
          return response;
        })
      );
    }

}