
export class ChassiValidation {
	
	country: string;
	vin: string;
	modelYear: number;
	tma: string;
	version: string;
	motorDesc: string;
	customerDeliveryDate: Date;
	invoicingDate: Date;
	warrantyStartDate: Date;
	errorMessage: string;
	vehicleLine: string;
	validated: number;
	system: string;
	odometro: number = 0;
	modelName: string;
	idVinCategoryVersion: number;

	constructor() {
		
	}
}
