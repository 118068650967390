export enum ApsPermitedScreen {
	
	screenHome = 'Modelo não identificado', //- Tela inicial
	screenSales = 'TMAC:ScreenSales', //- Tela de canal de vendas
	screenSalesTelemarketing = 'TMAC:ScreenSalesTelemarketing', // - Tela Exclusiva para venda telemarketing
	screenVinValidation = 'TMAC:ScreenVinValidation', //- Tela com informações do veículo
	screenPlansPolicy = 'TMAC:ScreenPlansPolicy', //- Tela de seleção de produtos
	screenCustomerValidation  = 'TMAC:ScreenCustomerValidation', //- Tela de validação do cliente
	screenContractReview = 'TMAC:ScreenContractReview', //- Tela de revisão do contrato
	screenSignature  = 'TMAC:ScreenSignature', //- Tela de anexo para assinatura
	screenContractImport  = 'TMAC:ScreenContractImport', //- Tela de importação de contratos
	screenVehicleModel = 'ScreenVehicleModel', //- Tela de seleção de modelos / N/E
	screenContractExport = 'ScreenContractExport', //- N/E
	screenContractSearch = 'TMAC:ScreenContractSearch', //- Tela de consulta de contrato
	screenContractView = 'TMAC:ScreenContractView', //- Tela de visualisar de contrato
	screenContractEdit = 'TMAC:ScreenContractEdit', //- Tela de editar de contrato
	screenManualContract = 'TMAC:ScreenManualContract', //- Tela de criação de contrato manual
	screenContractSearchDealer = 'TMAC:ScreenContractSearchDealer', //- Tela de consulta de contrato dealer
	screenContractViewDealer = 'TMAC:ScreenContractViewDealer', //- Tela de visualizar de contrato dealer
	accessManagement = 'TMAC:AccessManagement', //- perfil de acesso
	screenViewsPlans = 'TMAC:ScreenViewsPlans', // - Tela de consulta de planos

}

