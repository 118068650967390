import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'product-details-edit',
  templateUrl: './product-details-edit.component.html',
  styleUrls: ['./product-details-edit.component.scss']
})
export class ProductDetailsEditComponent implements OnInit {

  inputNameMessage: boolean;
  inputDescriptionMessage: boolean;
  inputProductName: string;
  inputProductDescription: string;

  actionCodeUppercase: string;
  accountCodeUppercase: string;
  coverageUppercase: string;
  insuranceCodeUppercase: string;

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {

    this.buttonCancel();
  }

  buttonReturn() {
    this.router.navigate(['view-plans'], { skipLocationChange: false, replaceUrl: false });
  }

  buttonEdit() {

    (<HTMLInputElement>document.getElementById("productName")).disabled = false;
    (<HTMLInputElement>document.getElementById("productDescription")).disabled = false;
    (<HTMLInputElement>document.getElementById("account")).disabled = false;
    (<HTMLInputElement>document.getElementById("actionCode")).disabled = false;
    (<HTMLInputElement>document.getElementById("coverageCode")).disabled = false;
    (<HTMLInputElement>document.getElementById("insuranceCode")).disabled = false;
    (<HTMLInputElement>document.getElementById("productDetails")).disabled = false;
    (<HTMLInputElement>document.getElementById("titleDetails")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("titleEdit")).style.display = 'block';    
    (<HTMLInputElement>document.getElementById("subTitle")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnReturn")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnEdit")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnCancel")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnSave")).style.display = 'block';
  }

  buttonCancel() {

    (<HTMLInputElement>document.getElementById("productName")).disabled = true;
    (<HTMLInputElement>document.getElementById("productDescription")).disabled = true;
    (<HTMLInputElement>document.getElementById("account")).disabled = true;
    (<HTMLInputElement>document.getElementById("actionCode")).disabled = true;
    (<HTMLInputElement>document.getElementById("coverageCode")).disabled = true;
    (<HTMLInputElement>document.getElementById("insuranceCode")).disabled = true;
    (<HTMLInputElement>document.getElementById("productDetails")).disabled = true;
    (<HTMLInputElement>document.getElementById("titleDetails")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("titleEdit")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("subTitle")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnReturn")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnEdit")).style.display = 'block';
    (<HTMLInputElement>document.getElementById("btnCancel")).style.display = 'none';
    (<HTMLInputElement>document.getElementById("btnSave")).style.display = 'none';

    this.inputNameMessage = false;
    this.inputDescriptionMessage = false;

  }

  accountOnKeyUp(event: any) {
    this.accountCodeUppercase = event.target.value.toUpperCase();
  }

  actionCodeOnKeyUp(event: any) {
    this.actionCodeUppercase = event.target.value.toUpperCase();
  }

  coverageCodeOnKeyUp(event: any) {
    this.coverageUppercase = event.target.value.toUpperCase();
  }

  insuranceCodeOnKeyUp(event: any) {
    this.insuranceCodeUppercase = event.target.value.toUpperCase();
  }

  buttonSave() {

    this.inputProductName = (<HTMLInputElement>document.getElementById("productName")).value;
    this.inputProductDescription = (<HTMLInputElement>document.getElementById("productDescription")).value;

    if (this.inputProductName === undefined ||
      this.inputProductName.length < 4 ||
      this.inputProductName.length > 50) {

      this.inputNameMessage = true;
    }
    if (this.inputProductDescription === undefined ||
      this.inputProductDescription.length < 4 ||
      this.inputProductDescription.length > 300) {

      this.inputDescriptionMessage = true;
    } else {

      this.inputNameMessage = false;
      this.inputDescriptionMessage = false;
    }
  }

}
