import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService, UrlConsumerService } from '@sade/ng-adfs';
import { ChoosePlanComponent } from './views/choose-plan/choose-plan.component';
import { ClientDataComponent } from './views/client-data/client-data.component';
import { ContractEditDealerComponent } from './views/contract-dealer/contract-edit-dealer.component';
import { ContractSearchDealerComponent } from './views/contract-dealer/contract-search-dealer.component';
import { ContractManualComponent } from './views/contract-manual/contract-manual.component';
import { ContractSignatureComponent } from './views/contract-signature/contract-signature.component';
import { ContractSummaryAttachmentOneComponent } from './views/contract-summary-attachment-one/contract-summary-attachment-one.component';
import { ContractSummaryAttachmentTwoComponent } from './views/contract-summary-attachment-two/contract-summary-attachment-two.component';
import { ContractSummaryExternalComponent } from './views/contract-summary-external/contract-summary-external.component';
import { ContractSummaryComponent } from './views/contract-summary/contract-summary.component';
import { ContractEditComponent } from './views/contract/contract-edit.component';
import { ContractSearchComponent } from './views/contract/contract-search.component';
import { CustomerComponent } from './views/customer/customer.component';
import { ReportsComponent } from './views/excel-reports/reports/reports.component';
import { HomeComponent } from './views/home/home.component';
import { ImportContractComponent } from './views/import-contract/import-contract.component';
import { InformationComponent } from './views/information/information.component';
import { LoginComponent } from './views/login/login.component';
import { DealerSaleComponent } from './views/new-contract-dialer-sale/dealer-sale.component';
import { DirectSaleComponent } from './views/new-contract-direct-sale/direct-sale.component';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { PaymentMethodComponent } from './views/payment-method/payment-method.component';
import { PaymentSignatureComponent } from './views/payment-signature/payment-signature.component';
import { PlanSelectionComponent } from './views/plan-selection/plan-selection.component';
import { CoverageDetailsEditComponent } from './views/plans-details-edit/coverage-details-edit/coverage-details-edit.component';
import { EligibilityDetailsEditComponent } from './views/plans-details-edit/eligibility-details-edit/eligibility-details-edit.component';
import { PriceDetailsEditComponent } from './views/plans-details-edit/price-details-edit/price-details-edit.component';
import { ProductDetailsEditComponent } from './views/plans-details-edit/product-details-edit/product-details-edit.component';
import { NewCoverageComponent } from './views/product-create-search/new-coverage/new-coverage.component';
import { NewEligibilityComponent } from './views/product-create-search/new-eligibility/new-eligibility.component';
import { NewPriceComponent } from './views/product-create-search/new-price/new-price.component';
import { NewProductComponent } from './views/product-create-search/new-product/new-product.component';
import { ViewPlansComponent } from './views/product-create-search/view-plans/view-plans.component';
import { SalesChannelExternalComponent } from './views/sales-channel-external/sales-channel-external.component';
import { SalesChannelComponent } from './views/sales-channel/sales-channel.component';
import { VehicleInformationComponent } from './views/vehicle-information/vehicle-information.component';

const routes: Routes = [
	{
		path: 'access_token',
		component: LoginComponent, // You can set any component you want, because the component will not be loaded
		canActivate: [UrlConsumerService]
	},
	{
		path: '',
		redirectTo: 'home',
		pathMatch: 'full'
	},
	{
		path: 'home',
		component: HomeComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'direct-sale',
		component: DirectSaleComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'dealer-sale',
		component: DealerSaleComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'import-contract',
		component: ImportContractComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'plan-selection',
		component: PlanSelectionComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'customer',
		component: CustomerComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'sales-channel',
		component: SalesChannelComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'view-plans',
		component: ViewPlansComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'reports',
		component: ReportsComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'new-coverage',
		component: NewCoverageComponent,
	},
	{
		path: 'new-eligibility',
		component: NewEligibilityComponent,
	},
	{
		path: 'new-price',
		component: NewPriceComponent,
	},
	{
		path: 'new-product',
		component: NewProductComponent,
	},
	{
		path: 'coverage-details-edit/:id',
		component: CoverageDetailsEditComponent,
	},
	{
		path: 'eligibility-details-edit/:id',
		component: EligibilityDetailsEditComponent,
	},
	{
		path: 'price-details-edit/:id',
		component: PriceDetailsEditComponent,
	},
	{
		path: 'product-details-edit/:id',
		component: ProductDetailsEditComponent,
		// canActivate: [AuthGuardService]
	},
	{
		path: 'sales-channel-external',
		component: SalesChannelExternalComponent,
		canActivate: [AuthGuardService]
	},	
	{
		path: 'vehicle-information/:saleChannel',
		component: VehicleInformationComponent,
		canActivate: [AuthGuardService]
	},
	{
		path: 'choose-plan/:saleChannel',
		component: ChoosePlanComponent,
		
		//canActivate: [AuthGuardService]
	},
	{
		path: 'client-data/:saleChannel',
		component: ClientDataComponent,
		//canActivate: [AuthGuardService]
	},
	{
		path: 'contract-summary/:saleChannel',		
		component: ContractSummaryComponent,
		//canActivate: [AuthGuardService]
	},
	{
		path: 'contract-summary-external/:saleChannel',
		component: ContractSummaryExternalComponent,
		//canActivate: [AuthGuardService]
	},	
	{
		path: 'contract-summary-attachment-one/:saleChannel',
		component: ContractSummaryAttachmentOneComponent,
		//canActivate: [AuthGuardService]
	},
	{
		path: 'contract-summary-attachment-two/:saleChannel',
		component: ContractSummaryAttachmentTwoComponent,
		//canActivate: [AuthGuardService]
	},
	{
		path: 'contract-search',
		component: ContractSearchComponent,
		//canActivate: [AuthGuardService]
	},
	{
		path: 'contract-search-dealer',
		component: ContractSearchDealerComponent,
		//canActivate: [AuthGuardService]
	},
	{
		path: 'contract-manual',
		component: ContractManualComponent,
		//canActivate: [AuthGuardService]
	},
	{
		path: 'payment-method',
		component: PaymentMethodComponent,
		//canActivate: [AuthGuardService]
	},
	{
		path: 'payment-signature',
		component: PaymentSignatureComponent,
		//canActivate: [AuthGuardService]
	},	
	{
		path: 'contract-signature',
		component: ContractSignatureComponent,
		//canActivate: [AuthGuardService]
	},
	{
		path: 'contract-edit',
		component: ContractEditComponent,
		//canActivate: [AuthGuardService]
	},
	{
		path: 'contract-edit-dealer',
		component: ContractEditDealerComponent,
		//canActivate: [AuthGuardService]
	},
	{
		path: 'information',
		component: InformationComponent,
		//canActivate: [AuthGuardService]
	},
	{
		path: '404',
		component: NotFoundComponent,
		canActivate: [AuthGuardService]
	},
	{ path: '**',
    redirectTo: '/404' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
	exports: [RouterModule]
})

export class AppRoutingModule {}
