import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { ChassiValidation } from "src/app/models/chassi-validation/chassi-validation";
import { PlanSelection } from "src/app/models/plan-selection/plan-selection";
import { environment } from "src/environments/environment";

const httpOptions = {
	headers: new HttpHeaders({
        'Content-Type': 'application/json'
	})
};

@Injectable({
  providedIn: 'root'
})
export class PlanSelecionService {

    private apiUrl = environment.apiUrl;
    private planSelectionList: Array<PlanSelection> = [];

    constructor(private httpClient: HttpClient, private messageService: MessageService) {
    }

    getPlanSelection(chassiValidations: Array<ChassiValidation>): Observable<Array<PlanSelection>> {      
      return this.httpClient.post(`${this.apiUrl}/api/vins/motorAndModelYearGrouping/`, chassiValidations, httpOptions)
        .pipe(
          map((response: Array<PlanSelection>) => {
            return response;
          })
        );
    }

    setAllPlanSelectionList(planSelections: Array<PlanSelection>) {
      this.planSelectionList = planSelections;
    }

    getAllPlanSelectionList(): Array<PlanSelection> {
      return this.planSelectionList;
    }
}
